import { useCallback } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import type { RankingQuestion } from '@/types/survey';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import DropDown from '@/components/DropDown';
import { OnSelectChange, Select } from '$admin/components/Select';
import { RequiredRankings } from './Ranking.Settings.Required';
import { ResponsePiping } from './ResponsePiping';
import {
  RandomizeCheckbox,
  OrderOptionsBasedOnSource,
  ShowDontKnowCheckbox,
  RationaleCheckbox,
} from './Settings';
import QuestionOptionsBuilder from './OptionsBuilder';
import { RankingOptionContextMenu } from './Ranking.Option.ContextMenu';
import { useHasLinkedOptions, useShowOptionOrdering } from './hooks';
import { ReuseQuestionOptions } from './ReuseQuestionValues';
import { FindAndReplaceOptions } from './FindAndReplace';
import styles from './style/Ranking.Question.css';

type Props = {
  item: RankingQuestion.Question;
};

export const RankingQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const handleChange = useCallback(<T extends keyof RankingQuestion.Settings = keyof RankingQuestion.Settings, V = RankingQuestion.Settings[T]>(field: keyof RankingQuestion.Settings) => (value: V) => {
    dispatch({
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        [field]: value,
      },
      type: 'update-question-settings',
    });
  }, [dispatch, item.settings, item.base.identifier]);

  const hasLinkedOptions = useHasLinkedOptions(item);
  const showOptionOrdering = useShowOptionOrdering(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping disabled={hasLinkedOptions} />
        <ReuseQuestionOptions />
        {canFar ? <FindAndReplaceOptions question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.ranking.MaxOptions}
        minOptions={surveyBuilder.ranking.MinOptions}>
        <QuestionOptionsBuilder
          renderAction={option => (
            <RankingOptionContextMenu
              question={item}
              option={option as RankingQuestion.Option} />
          )} />
      </QuestionOptionsBuilderContainer>

      <div className={styles.settings}>
        <RandomizeCheckbox
          disabled={item.settings.orderOptionsBasedOnSource}
          className={styles.checkbox} />

        {showOptionOrdering &&
          <OrderOptionsBasedOnSource
            disabled={item.settings.randomization}
            className={styles.checkbox} />
        }

        <QuestionSettingTitle title="Display Format" />
        <Select
          options={DisplayFormatDropdownItems}
          onChange={e => handleChange('displayFormat')(e.target.value.toString() as DisplayFormat)}
          value={(DisplayFormatDropdownItems.find(i => i.id === item.settings.displayFormat) ?? DefaultDisplayFormatItem).id} />

        <ShowDontKnowCheckbox className={styles.checkbox} />

        <QuestionSettingTitle
          title="Min Rank" />
        <div className={styles.input}>
          <RequiredRankings
            numberOfItems={item.options.length}
            onChange={handleChange('minRank')}
            value={item.settings.minRank} />
        </div>

        <QuestionSettingTitle
          title="Max Rank" />
        <div className={styles.input}>
          <RequiredRankings
            numberOfItems={item.options.length}
            onChange={handleChange('maxRank')}
            value={item.settings.maxRank} />
        </div>

        <RationaleCheckbox className={styles.checkbox} />
      </div>
    </>
  );
};

type DisplayFormat = RankingQuestion.Settings['displayFormat'];

type DisplayFormatDDItem = {
  id: DisplayFormat;
  name: string;
};
const DisplayFormatDropdownItems: DisplayFormatDDItem[] = [
  {
    id: 'default',
    name: 'Default',
  }, {
    id: 'lists',
    name: 'Lists',
  }, {
    id: 'story',
    name: 'Story',
  },
];
const DefaultDisplayFormatItem = DisplayFormatDropdownItems.find(i => i.id === 'default');

export default RankingQuestionBuilder;