import { useEffect, Fragment } from 'react';
import { useAppReadyState, useAuthChangeEffect, useHydrateEffect } from '@containers/AppReadyState';
import { useFetchApp, useInitSentry, useLogout, useValidSessionChecker } from '@containers/AppStateEffect/hooks';

type Props = {
  children: React.ReactNode;
};

export const AppStateEffectContainer = (props: Props) => {
  const app = useAppReadyState();
  const fetchApp = useFetchApp();
  const logout = useLogout();

  const initSentry = useInitSentry();

  useHydrateEffect(() => {
    initSentry();
  });

  useAuthChangeEffect(auth => {
    if (!auth) {
      // gtag('set', { 'user_id': null });
      logout({
        sideEffects: false,
      });
    }
  });

  useEffect(() => {
    if (app.authenticated) {
      fetchApp();
    }
  }, [
    app.authenticated,
    fetchApp,
  ]);

  useValidSessionChecker({
    enabled: app.initialized && app.authenticated,
  });

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

AppStateEffectContainer.displayName = 'AppStateEffectContainer';