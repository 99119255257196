import { useCallback } from 'react';
import cuid from 'cuid';
import { SurveyTemplateType } from '@enums/survey.template';
import type { SurveyBuilderVersion } from '@/types/survey';
import type { EditableSurveyStateContextValue } from './Context';
import { EditableSurveyContext } from './Context';
import { useSurveyBuilderState } from './hooks/useSurveyBuilderContext';
import type { EditableSurveyStateProps } from './interfaces';

const EmptySurvey: SurveyBuilderVersion = {
  alternateImageExercises: [],
  classifications: [],
  createdOn: null,
  id: null,
  items: [],
  logic: [],
  messages: [],
  questions: [],
  quotas: [],
  surveyId: null,
  surveyDetails: null,
  sections: [{
    hidden: false,
    id: null,
    identifier: cuid(),
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canModifyVisibility: true,
      canDelete: true,
      linkedOrder: null,
      randomize: false,
      randomizeSelf: null,
      template: {},
    },
    name: `Section 1`,
    ordinal: 1,
  }],
  tagging: [],
  template: {
    data: null,
    type: SurveyTemplateType.None,
  },
};

type Props =
  EditableSurveyStateProps
  & ChildrenProps;

export const EditableSurveyState = ({
  children,
  onDelete,
  onFetch,
  onSave,
}: Props) => {

  const [state, dispatch] = useSurveyBuilderState();

  const deleteSurvey = useCallback(() => {
    return onDelete()
      .then(_ => {
        dispatch({
          type: 'reset-survey',
          value: EmptySurvey,
        });
      });
  }, [onDelete, dispatch]);

  const fetchSurvey = useCallback(() => {
    return onFetch()
      .then(data => {
        dispatch({
          type: 'reset-survey',
          value: data.id ? data : EmptySurvey,
        });
      });
  }, [onFetch, dispatch]);

  const saveSurvey = useCallback(() => {
    return onSave({
      questions: state.survey.questions,
      quotas: state.survey.quotas,
      sections: state.survey.sections,
    })
      .then(_ => {
        dispatch({
          type: 'reset-survey',
          value: state.survey,
        });
      });
  }, [
    dispatch,
    state.survey,
    onSave,
  ]);

  const context: EditableSurveyStateContextValue = {
    deleteSurvey,
    fetchSurvey,
    saveSurvey,
    surveyId: state.survey.surveyId,
  };

  return (
    <EditableSurveyContext.Provider value={context}>
      {children}
    </EditableSurveyContext.Provider>
  );
};

export default EditableSurveyState;