import type { Routing } from '@containers/Routing';
import { path, pathname } from '@consts';
import { RouteAuth } from '@enums';
import * as utils from '@utils';
import { SurveyMonitoringContainer } from '@screens/SurveyMonitoring';
import Referral from '@/scenes/referrals';
import { BrandInsightsTerms, SentimentTerms } from '$website/screens/Terms';
import { AppGuard } from './AppGuard';
import * as access from './access';
import { router as accountRouter } from './account';
import { router as brandInsightsRouter } from './brand-insights';
import { router as calendarRouter }  from './calendar';
import * as calls from './calls';
import { router as companiesRouter }  from './companies';
import { router as complianceRouter } from './compliance';
import { router as conferenceRouter } from './conference';
import * as contacts from './contacts';
import { router as discoveryRouter } from './discovery';
import { router as externalProjectRouter } from './external-project';
import { router as externalSourcingProjectRouter } from './external-sourcing-project';
import * as home from './home';
import * as inbound from './inbound';
import { router as inviteRouter } from './invite';
import { router as messagesRouter } from './messages';
import { unsubscribeRouter, verifyEmailRouter, welcomeRouter } from './misc';
import { router as notificationsRouter } from './notifications';
import { router as onboardingRouter } from './onboarding';
import * as posts from './posts';
import { router as profileRouter } from './profile';
import { router as projectsRouter } from './projects';
import { router as schedulingRouter } from './scheduling';
import { router as searchRouter } from './search';
import { router as selfServeSurveyRouter } from './self-serve-survey';
import { router as settingsRouter } from './settings';
import { router as topicsRouter } from './topics';
import { router as universalsearchRouter } from './universal-search';
import { router as workspacesRouter } from './workspaces';

export const routes: Routing.RouteConfig[] = [
  {
    auth: RouteAuth.Ignore,
    component: SurveyMonitoringContainer,
    exact: true,
    path: path.Projects.SurveyMonitoring,
  },
  access.router,
  accountRouter,
  brandInsightsRouter,
  calls.router,
  projectsRouter,
  calendarRouter,
  companiesRouter,
  complianceRouter,
  conferenceRouter,
  contacts.router,
  discoveryRouter,
  externalProjectRouter,
  externalSourcingProjectRouter,
  home.router,
  inbound.router.all,
  inbound.router.auth,
  inviteRouter,
  messagesRouter,
  notificationsRouter,
  onboardingRouter,
  posts.router.auth,
  posts.router.all,
  profileRouter,
  schedulingRouter,
  searchRouter,
  selfServeSurveyRouter,
  settingsRouter,
  topicsRouter,
  universalsearchRouter,
  unsubscribeRouter,
  verifyEmailRouter,
  welcomeRouter,
  workspacesRouter,
  {
    auth: RouteAuth.Authenticated,
    component: Referral,
    exact: true,
    path: pathname.REFER,
    roles: utils.roles.consultant,
  },
  {
    auth: RouteAuth.Ignore,
    component: SentimentTerms,
    exact: true,
    path: pathname.Terms,
  },
  {
    auth: RouteAuth.Ignore,
    component: BrandInsightsTerms,
    exact: true,
    path: path.Website.Terms.BrandInsights,
  },
  // This has to be last because of *
  {
    auth: RouteAuth.Ignore,
    component: AppGuard,
    path: '*',
  },
];