import { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { useRichTextListBuilderContext } from '@/containers/SurveyBuilder.Question';
import { convertToSurveyRichText, parseSurveyRichText } from '@/containers/Survey/utils';
import { AddQuestionOptionButton } from '@presentation/SurveyBuilder';
import { MatrixRangeQuestion, Remirror } from '@/types';
import { Input, NumberInput } from '@/components/Input';
import { OptionContextMenu } from './Option.ContextMenu';
import styles from './style/MatrixRange.Question.css';

type Props = {
  item: MatrixRangeQuestion.Question;
};

export const MatrixRangeColumnsBuilder = ({ item }: Props) => {

  const context = useRichTextListBuilderContext<MatrixRangeQuestion.Option>();

  const [_, dispatch] = useSurveyBuilderState();

  const handleValueChange = useCallback((option: MatrixRangeQuestion.Option) => (values: NumberFormatValues) => {
    dispatch({
      type: 'update-question-option-value',
      questionIdentifier: item.base.identifier,
      option: { identifier: option.base.identifier },
      value: convertToSurveyRichText(values.value),
    });
  }, [dispatch, item.base.identifier]);

  const handleHelpTextChange = useCallback((option: MatrixRangeQuestion.Option) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'matrix-range-option-metadata-updated',
      payload: {
        questionIdentifier: item.base.identifier,
        option: { identifier: option.base.identifier },
        value: e.target.value,
      },
    });
  }, [dispatch, item.base.identifier]);

  return (
    <div className={styles.columns}>
      {context.items.map(option => {
        return (
          <div className={styles.option} key={option.base.identifier}>
            <div className={styles.optionValue}>
              <NumberInput
                allowLeadingZeros={false}
                decimalScale={0}
                onValueChange={handleValueChange(option)}
                value={parseSurveyRichText(option.value)} />
            </div>
            <div className={styles.optionHelpText}>
              <Input
                placeholder="Enter help text"
                onChange={handleHelpTextChange(option)}
                value={option.metadata.helpText} />
            </div>
            <div className={styles.menu}>
              <OptionContextMenu
                option={option} />
            </div>
          </div>
        );
      })}
      {context.permissions.canAddItem &&
        <AddQuestionOptionButton
          onClick={context.addItem} />}
    </div>
  );
};
