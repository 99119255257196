import { useCallback } from 'react';
import { Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useHasUnsavedChanges, useSurveyBuilderOptions, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveyType } from '@/enums';
import { utils } from '@/enums';
import { EnumDropDown } from '@/components/DropDown';
import { Input } from '@/components/Input';
import { ContextMenu } from '@/components/ContextMenu';
import * as $api from '@api';
import { useDownloader } from '@/utils';
import Toast from '@/components/Toast';
import { MoreHorizontalAnchorSmall } from '@/presentation/Anchor';
import { useAlert } from '@/components/Modal/Alert';
import styles from './style/Details.css';

export const Details = () => {
  const options = useSurveyBuilderOptions();

  return (
    <>
      <div className={styles.row}>
        <Name />
        {options.surveyTypes?.length > 0 && <Type />}
        <SurveyContextMenu />
      </div>
      <Divider sx={{ margin: '25px 0px' }} />
    </>
  );
};

const Name = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const options = useSurveyBuilderOptions();

  const onChange = useCallback((val: string) => {
    dispatch({
      type: 'name-changed',
      payload: {
        name: val,
      },
    });
  }, [dispatch]);

  return (
    <div className={styles.column}>
      <div className={styles.header}>Survey Name</div>
      <Input
        disabled={!options.showDetails}
        value={state.survey.surveyDetails?.name ?? ''}
        onChange={e => onChange(e.target.value)} />
    </div>
  );
};

const Type = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const options = useSurveyBuilderOptions();

  const onChange = useCallback((val: SurveyType) => {
    dispatch({
      type: 'type-changed',
      payload: {
        type: val,
      },
    });
  }, [dispatch]);

  return (
    <div className={styles.column}>
      <div className={styles.header}>Survey Type</div>
      <EnumDropDown<SurveyType>
        disabled={!options.showDetails}
        enum={utils.SurveyType}
        items={options.surveyTypes}
        value={state.survey.surveyDetails?.typeId}
        onSelect={onChange} />
    </div>
  );
};

const SurveyContextMenu = () => {
  const [state] = useSurveyBuilderState();

  const hasUnsavedChanges = useHasUnsavedChanges();
  const [toggleDownloadAlert, DownloadAlert] = useAlert();

  const downloader = useDownloader();

  const downloadTextsMutation = useMutation({
    mutationFn: () => {
      return $api.surveys.downloadSurveyTexts({ surveyId: state.savedSurvey.surveyId });
    },
    onSuccess: resp => downloader(resp),
    onError: () => Toast.error({ title: 'There was an error downloading the report' }),
  });

  const onExportClick = useCallback(() => {
    if (hasUnsavedChanges()) {
      toggleDownloadAlert();
    } else {
      downloadTextsMutation.mutate();
    }
  }, [downloadTextsMutation, hasUnsavedChanges, toggleDownloadAlert]);
  return (
    <>
      <ContextMenu
        className={styles.contextMenu}
        AnchorElement={MoreHorizontalAnchorSmall}
        items={[{
          label: 'Export XLSX',
          disabled: !state.savedSurvey?.surveyId,
          onClick: onExportClick,
        }]}
        options={{ placement: 'bottom' }} />
      <DownloadAlert
        message={`You have unsaved changes that won't be included in the report. Are you sure you want to download the report?`}
        onConfirm={downloadTextsMutation.mutate} />
    </>
  );
};