import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import { QuestionSettingTitle } from '@/presentation/SurveyBuilder';
import { SurveySettings } from '@/types/survey';
import { toTitleCase } from '@utils';
import { DropDown } from '@/components/DropDown';
import styles from './style/Settings.MatrixDisplay.css';

type Props = {
  className?: string;
  value: SurveySettings.MatrixDisplayValue;
};

export const MatrixDisplaySetting = ({ className, value }: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItem();

  const handleSelect = useCallback((value: SurveySettings.MatrixDisplayValue) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        display: value,
      },
    });
  }, [
    dispatch,
    item.settings,
    item.base.identifier,
  ]);

  return (
    <div className={className}>
      <QuestionSettingTitle title="Display Override" />
      <div className={styles.dropdown}>
        <MatrixDisplayDropdown
          onSelect={handleSelect}
          value={value} />
      </div>
    </div>
  );
};

type DropdownProps = {
  onSelect: (value: SurveySettings.MatrixDisplayValue) => void;
  value: SurveySettings.MatrixDisplayValue;
};

type DropdownItem = {
  id: string;
  value: string;
};

const MatrixDisplayDropdown = ({ onSelect, value }: DropdownProps) => {

  const items: DropdownItem[] = useMemo(() => {
    return [
      { id: 'empty', value: '' },
      { id: 'grid', value: 'Grid' },
      { id: 'list', value: 'List' },
    ];
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    const selected: SurveySettings.MatrixDisplayValue = item.id === 'empty' ? null : item.id as SurveySettings.MatrixDisplayValue;
    onSelect(selected);
  }, [onSelect]);

  return (
    <DropDown<DropdownItem>
      items={items}
      onSelect={handleSelect}
      text={value ? toTitleCase(value) : ''}
      getItemValue={item => item.value} />
  );
};