import { useContext, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import Toast from '@/components/Toast';
import { SurveyBuilderStateContext, SurveyBuilderStateContextValue } from '../Context';

type State = SurveyBuilderStateContextValue[0];

export const useSubmitSurveyDraftMutation = () => {
  const [state, dispatch] = useContext(SurveyBuilderStateContext);
  const [needsSubmit, changeNeedsSubmit] = useState(false);

  const mutation = useMutation(['save-survey-draft'], (data: State) => {
    return $api.surveys.draft.saveSurveyDraft({
      ...data.survey,
      surveyVersionId: data.draft.surveyVersionId,
    });
  }, {
    onMutate: () => {
      dispatch({
        type: 'draft-status-updated',
        status: 'saving',
      });
    },
    onSuccess: data => {
      dispatch({
        type: 'draft-survey-data-updated',
        value: data,
      });
    },
    onError: e => {
      Toast.error({
        title: 'Error',
        body: `There was an error saving the survey draft.`,
      });
    },
    onSettled: () => {
      changeNeedsSubmit(false);
      dispatch({
        type: 'draft-status-updated',
        status: 'idle',
      });
    },
  });

  useEffect(() => {
    if (state.draft.status === 'queued' && !mutation.isLoading && needsSubmit) {
      mutation.mutate(state);
      dispatch({
        type: 'draft-status-updated',
        status: 'idle',
      });
    }
  }, [state.draft.status, needsSubmit, state, mutation, dispatch]);

  const queueDraftSave = useCallback(() => {
    dispatch({
      type: 'draft-status-updated',
      status: 'queued',
    });
    changeNeedsSubmit(true);
  }, [dispatch]);

  return queueDraftSave;
};