import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import * as api from '@api';
import { path, querykey } from '@consts';
import { usePreviousDistinct, useQueryWrapper } from '@/utils';
import { WorkspaceBreadcrumbsContext } from '@containers/WorkspaceObject';
import {
  ProjectDetailsContext,
  ProjectDisplayrDashboardIdContext,
  ProjectQuantAnalysesContext,
  ProjectStateContext,
  ProjectStateLoadingContext,
  SetDisplayDashboardIdContext,
} from './Context';
import { useMatchProjectIdFromUrl } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const ProjectStateContainer = (props: Props) => {
  const params = useMatchProjectIdFromUrl();
  const [projectId, setProjectId] = useState(params.projectId);

  const previousProjectId = usePreviousDistinct(params.projectId);

  const fetchProjectParent = useCallback(() => {
    return api.projects.getProjectParent({
      projectParentId: params.projectParentId,
    }).then(res => {
      setProjectId(res.projectParent.latestChild);
    });
  }, [params.projectParentId]);

  useEffect(() => {
    if (params.projectId !== previousProjectId) {
      setProjectId(params.projectId);
    }
  }, [params.projectId, previousProjectId]);

  useEffect(() => {
    if (!projectId) {
      fetchProjectParent();
    }
  }, [fetchProjectParent, projectId]);

  return (
    <ProjectQueryContainer projectId={projectId}>
      {props.children}
    </ProjectQueryContainer>
  );
};

ProjectStateContainer.displayName = 'Project.State.Container';

type ProjectQueryContainerProps = {
  children: React.ReactNode;
} & IProjectId;

const ProjectQueryContainer = (props: ProjectQueryContainerProps) => {
  const [displayrDashboardId, setDisplayrDashboardId] = useState<string>(null);

  const query = useQueryWrapper({
    queryKey: querykey.Projects.Project.Get({ projectId: props.projectId }),
    queryFn: () => {
      return api.projects.fetchProject({
        projectId: props.projectId,
      });
    }, enabled: !!props.projectId,
    onSuccess: data => {
      setDisplayrDashboardId(data.project.displayrDashboardId);
    },
    refetchOnWindowFocus: false,
  });

  const ready = useMemo(() => !query.isInitialLoading && !!query.data?.project, [query.isInitialLoading, query.data?.project]);

  const detailsCtx = useMemo(() => ({ query }), [query]);

  const value = useMemo(() => ({
    capabilities: query.data?.state?.capabilities,
    features: query.data?.features,
    latestProject: ready ? Object.assign(query.data?.state?.latest, { slug: String(query.data?.state?.latest?.id) }) : null,
    object: query.data?.state?.object,
    pipeline: query.data?.pipeline,
    project: ready ? Object.assign(query.data?.state?.project, { slug: String(query.data?.state?.project?.id) }) : null,
    projectParent: query.data?.state?.parent,
    versions: query.data?.state?.versions,
    workspace: query.data?.state?.workspace,
  }), [query.data?.state?.capabilities, query.data?.state?.latest, query.data?.state?.object, query.data?.state?.project, query.data?.state?.parent, query.data?.state?.versions, query.data?.state?.workspace, query.data?.pipeline, ready]);

  const quantAnalyses = useMemo(() => {
    return query.data?.state?.project?.quantAnalyses || [];
  }, [query.data?.state?.project?.quantAnalyses]);

  if (query.isFetched && !query.data?.state?.parent) {
    const e = query.error as AxiosResponse;
    const data = e?.data as IWorkspaceObjectId;
    const forbidden = query.isError && e.status === 403;
    const hasObjectId = data !== null && typeof data === 'object' && !!data?.objectId;

    return forbidden && hasObjectId
      ? <Redirect to={generatePath(path.Access.Request, data)} />
      : <Redirect to={path.Projects.Root} />;
  }

  return (
    <ProjectQuantAnalysesContext.Provider value={quantAnalyses}>
      <ProjectDisplayrDashboardIdContext.Provider value={displayrDashboardId}>
        <SetDisplayDashboardIdContext.Provider value={setDisplayrDashboardId}>
          <WorkspaceBreadcrumbsContext.Provider value={query.data?.breadcrumbs}>
            <ProjectStateLoadingContext.Provider value={!ready}>
              <ProjectDetailsContext.Provider value={detailsCtx}>
                <ProjectStateContext.Provider value={value}>
                  {props.children}
                </ProjectStateContext.Provider>
              </ProjectDetailsContext.Provider>
            </ProjectStateLoadingContext.Provider>
          </WorkspaceBreadcrumbsContext.Provider>
        </SetDisplayDashboardIdContext.Provider>
      </ProjectDisplayrDashboardIdContext.Provider>
    </ProjectQuantAnalysesContext.Provider>
  );
};