import cuid from 'cuid';
import { HTMLProps, useMemo } from 'react';
import { PlusSquare, Upload } from 'react-feather';
import styles from './style.css';

type Props = {
  onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'id' | 'type'>;
  InputComponent?: React.ComponentType;
};

export const ImageInput = (props: Props) => {
  const inputId = useMemo(() => cuid(), []);

  return (
    <>
      <label htmlFor={inputId} className={styles.label}>
        <props.InputComponent />
      </label>
      <input
        className={styles.input}
        id={inputId}
        type='file'
        {...props.inputProps}
        onChange={props.onChange} />
    </>
  );
};

const DefaultInputComponent = () => {
  return (
    <PlusSquare size={200} />
  );
};

type DragInputComponentProps = {
  text?: string;
  size?: number;
};
//This doesn't actually have drag and drop functionality right now but it's styled like one
export const DragInputComponent = ({ text, size }: DragInputComponentProps) => {
  return (
    <div className={styles.dragInput} style={{ height: size ?? 200, width: size ?? 200 }}>
      <Upload size={36} />
      {text && <div>{text}</div>}
    </div>
  );
};

ImageInput.defaultProps = {
  InputComponent: DefaultInputComponent,
  inputProps: {
    accept: 'image/*',
  },
};