import { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as api from '@api';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import { GroupTagsContextValue, GroupTagUpdateItem } from './interfaces';

type Props =
  ITranscriptId &
  ChildrenProps;

export default function GroupTranscriptTagsContainer({ transcriptId, ...props }: Props) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupTranscriptTagsContainer.initialState);

  const { isSuccess, refetch } = useQuery(['get/group:tags:transcript', { transcriptId }], () => {
    return api.tags.group.getTranscriptTags({ transcriptId });
  }, {
    onSuccess: res => {
      dispatch(res.tags);
    },
  });

  const { mutateAsync } = useMutation((tags: GroupTagUpdateItem[]) => {
    return api.tags.group.updateTranscriptTags({
      transcriptId,
      tags,
    });
  }, {
    onSuccess: res => {
      dispatch(res.tags);
    },
  });

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={!isSuccess}>
        <GroupEntityTagsFetchContext.Provider value={refetch}>
          <GroupEntityTagsUpdateContext.Provider value={mutateAsync}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupTranscriptTagsContainer.initialState = initial;

export { GroupTranscriptTagsContainer };