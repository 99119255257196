import { useCallback } from 'react';
import RadioGroupMUI from '@mui/material/RadioGroup';
import RichTextRadioGroupOptions from './RichTextRadioGroupOptions';
import type { RichTextRadioGroupProps } from './interfaces';

type Props =
  RichTextRadioGroupProps;

export default function RichTextRadioGroup({ className, OptionComponent, selectedOption, options, onOptionChange, onInputChange }: Props) {
  const handleRadioChange =
    useCallback((e: React.ChangeEvent<HTMLInputElement>) => onOptionChange({ optionId: e.target.value }), [onOptionChange]);

  const handleInputChange =
    useCallback((optionId: string, text: string) => onInputChange?.({ optionId, text }), [onInputChange]);

  return (
    <RadioGroupMUI
      className={className}
      value={selectedOption}
      onChange={handleRadioChange}>
      {options.map(option =>
        <OptionComponent
          key={option.value}
          onInputChange={e => handleInputChange(option.value.toString(), e.target.value)}
          isSelected={selectedOption === option.value}
          {...option} />)}
    </RadioGroupMUI>
  );
}

RichTextRadioGroup.defaultProps = {
  OptionComponent: RichTextRadioGroupOptions,
};

export { RichTextRadioGroup };