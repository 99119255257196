import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import * as actions from '@actions';
import * as $logout from '@services/auth/logout';

type LogoutParams = {
  sideEffects: boolean;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const query = useQueryClient();

  const handleLogout = useCallback(() => {
    dispatch(actions.logout({ wasActionLogout: true }));
    Promise.all([
      query.cancelQueries(),
      // query.cancelMutations(),
    ])
      .then(() => query.clear());
  }, [dispatch, query]);

  const logout = ({ sideEffects = true }: LogoutParams) => {
    if (sideEffects) {
      return $logout.logout()
      .then(handleLogout);
    } else {
      handleLogout();
    }
  };

  return useCallback(logout, [handleLogout]);
};