import { useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { getSelectionSettingOptions, assertValidSelectionSettings } from '@containers/SurveyBuilder/utils/question.matrix-multiselect';
import type { MatrixMultiselectQuestion } from '@/types';
import { arr } from '@utils';
import { QuestionColumnsBuilder } from './OptionsBuilder';
import { SelectionSettings } from './Multiselect.Settings.Selections';
import { ResponsePiping } from './ResponsePiping';
import { QuestionRowsBuilder } from './RowsBuilder';
import styles from './style/MatrixGrid.Question.css';
import {
  OrderRowsBasedOnSource,
  RandomizeRowsCheckbox,
  MatrixNotApplicable,
  MatrixGroupBySetting,
  MatrixDisplaySetting,
  RationaleCheckbox,
} from './Settings';
import { useHasLinkedRows } from './hooks';
import { ReuseQuestionRows } from './ReuseQuestionValues';
import { FindAndReplaceOptions, FindAndReplaceRows } from './FindAndReplace';
import { MatrixMultiselectRowContextMenu } from './MatrixMultiselect.Row.ContextMenu';
import { OptionContextMenu } from './Option.ContextMenu';

type Props = {
  item: MatrixMultiselectQuestion.Question;
};

export const MatrixMultiselectBuilder = ({ item }: Props) => {

  const selectItems = useMemo(() => {
    const [min, max] = getSelectionSettingOptions(item);

    return arr.range(max - min + 1, min).reduce<{ [k: number]: string }>((acc, x) => {
      acc[x] = x.toString();
      return acc;
    }, {
      [0]: '',
    });
  }, [
    item,
  ]);

  const settingsValidation = useMemo(() => {
    return assertValidSelectionSettings(item);
  }, [item]);

  const hasLinkedRows = useHasLinkedRows(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping
          disabled={hasLinkedRows}
          type="rows" />
        <ReuseQuestionRows />
        {canFar ? <FindAndReplaceRows question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionRowsBuilderContainer
        maxRows={surveyBuilder.matrixMultiselect.MaxRows}
        minRows={surveyBuilder.matrixMultiselect.MinRows}>
        <QuestionRowsBuilder
          className={styles.rows}
          renderAction={row => (
            <MatrixMultiselectRowContextMenu
              question={item}
              row={row as MatrixMultiselectQuestion.Row} />
          )} />
      </QuestionRowsBuilderContainer>

      <RandomizeRowsCheckbox
        disabled={item.settings.orderRowsBasedOnSource}
        className={styles.checkbox} />

      {hasLinkedRows &&
        <OrderRowsBasedOnSource
          disabled={item.settings.randomizeRows}
          className={styles.checkbox} />
      }

      <QuestionSettingTitle title="Columns">
        <ResponsePiping />
        {canFar ? <FindAndReplaceOptions question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.matrixMultiselect.MaxColumns}
        minOptions={surveyBuilder.matrixMultiselect.MinColumns}>
        <QuestionColumnsBuilder
          className={styles.options}
          renderAction={option => (
            <OptionContextMenu option={option} excludeFindAndReplace={false} />
          )} />
      </QuestionOptionsBuilderContainer>

      <MatrixNotApplicable
        className={styles.checkbox} />

      <RandomizeRowsCheckbox
        label="Randomize Columns"
        className={styles.checkbox} />

      <MatrixGroupBySetting
        className={styles.checkbox}
        value={item.settings.groupBy} />

      <MatrixDisplaySetting
        className={styles.checkbox}
        value={item.settings.display} />

      <SelectionSettings
        item={item}
        selectItems={selectItems}
        validation={settingsValidation} />

      <RationaleCheckbox className={styles.checkbox} />
    </>
  );
};

export default MatrixMultiselectBuilder;