import { useMemo } from 'react';
import { SurveyItemType } from '@enums/Survey';
import { useSurveyBuilderOptions, useSurveyBuilderState } from './useSurveyBuilderContext';
import useIsEditingSurvey from './useIsEditingSurvey';

export const useCanAddQuestion = () => {
  const [state] = useSurveyBuilderState();
  const { maxQuestions } = useSurveyBuilderOptions();
  const isEditing = useIsEditingSurvey();

  const canAddQuestion = useMemo(() => {
    return !isEditing &&
      state.survey.questions.length < maxQuestions;
  }, [
    isEditing,
    maxQuestions,
    state.survey.questions,
  ]);

  return canAddQuestion;
};

export const useCanAddMessage = () => {
  const isEditing = useIsEditingSurvey();
  const options = useSurveyBuilderOptions();

  const canAddMessage = useMemo(() => {
    return !isEditing &&
      options.itemTypes.includes(SurveyItemType.Message);

  }, [isEditing, options.itemTypes]);

  return canAddMessage;
};

export const useCanAddAIE = () => {
  const isEditing = useIsEditingSurvey();
  const options = useSurveyBuilderOptions();

  const canAddAIE = useMemo(() => {
    return !isEditing &&
      options.itemTypes.includes(SurveyItemType.AlternateImageExercise);

  }, [isEditing, options.itemTypes]);

  return canAddAIE;
};