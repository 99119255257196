import { useState, useCallback } from 'react';
import { TimeRange } from '@containers/Transcript/interfaces';
import { HighlightWithText } from '@/components/Transcript/interfaces.extension.highlight';
import { TranscriptRedactingContext } from './Context';

export const TranscriptRedactionContainer = ({ children }: ChildrenProps) => {
  const [redacting, _setRedacting] = useState<boolean>(false);
  const [redactedRanges, setRedactedRanges] = useState<TimeRange[]>([]);
  const [selections, setSelection] = useState<HighlightWithText[]>([]);

  const setRedacting = useCallback((val: boolean) => {
    _setRedacting(val);
    if (!val) {
      setRedactedRanges([]);
      setSelection([]);
    }
  }, []);

  const addTimeRange = useCallback((range: TimeRange) => {
    //Make sure the range doesn't overlap with any existing ranges
    setRedactedRanges(old => combineOverlappingRanges([...old, range]));
  }, []);

  const addSelection = useCallback((selection: HighlightWithText) => {
    setSelection(old => [...old, selection]);
  }, []);

  return (
    <TranscriptRedactingContext.Provider value={{
      selections,
      addSelection,
      redacting,
      setRedacting,
      redactedRanges,
      addTimeRange,
    }}>
      {children}
    </TranscriptRedactingContext.Provider>
  );
};

TranscriptRedactionContainer.displayName = 'Transcript.Redaction.Container';

function combineOverlappingRanges(ranges: TimeRange[]) {
  const sorted = ranges.sort((a, b) => a.start - b.start);
  const combined: TimeRange[] = [];
  let current: TimeRange | null = null;
  for (const range of sorted) {
    if (!current) {
      current = range;
    } else {
      if (current.end >= range.start) {
        current = {
          start: current.start,
          end: Math.max(current.end, range.end),
        };
      } else {
        combined.push(current);
        current = range;
      }
    }
  }
  if (current) {
    combined.push(current);
  }
  return combined;
}