import { ShortTextResponseQuestion } from '@/types';
import { RationaleCheckbox } from './Settings.Rationale';

export type Props = {
  item: ShortTextResponseQuestion.Question;
};

export const ShortTextQuestionBuilder = ({ item }: Props) => {

  return (
    <>
      <RationaleCheckbox />
    </>
  );
};

export default ShortTextQuestionBuilder;