import { Fragment, useCallback, useMemo, memo, useEffect, useRef } from 'react';
import type { LocalAudioTrack, RemoteAudioTrack, RemoteParticipant, LocalParticipant } from 'twilio-video';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneDisabled from '@mui/icons-material/PhoneDisabled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MicOnIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { format } from 'date-fns';
import { captureException } from '@sentry/react';
import { CallRole, ConferenceSocketEvent, utils as enumUtils } from '@enums';
import type { IConference } from '@containers/Conference';
import { useCoordinator, useTwilioVideo, useConferenceInstance, useSocket } from '@containers/Conference';
import { ModeratedParticipantName, useVisibleParticipants } from '@screens/Conference.Common';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { Tooltip, ConditionalTooltip } from '@presentation/Tooltip';
import type { Participant as LiveParticipant, Participant } from '@/types/conferences.live';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import { usePublications, useParticipants, useTrack, useIsTrackEnabled } from '@/components/Conference.Video/hooks';
import { UserAvatar } from '@/components/UserAvatar';
import Toast from '@/components/Toast';
import { useYourParticipant, useHostCount } from './hooks';
import styles from './style/Bar.Right.Participants.css';

export function BarRightParticipantsInConference() {
  const { muteParticipant } = useCoordinator();
  const twilio = useTwilioVideo();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();
  const hostCount = useHostCount();

  const visibleParticipants = useVisibleParticipants();

  const twilioParticipants = useParticipants(twilio.room);

  const loggedRef = useRef(false);
  useEffect(() => {
    const badParticipants = visibleParticipants.filter(p => !p.name);

    if (badParticipants.length && !loggedRef.current) {
      captureException(new Error(`Found participants without a name`), {
        extra: {
          badParticipants,
        },
      });

      loggedRef.current = true;
    }
  }, [visibleParticipants]);

  const participants = visibleParticipants.filter(p => p.status === 'meeting-room').sort((a, b) => a.id === you?.id ? -1 : a.name?.localeCompare(b.name));

  const handleMute = useCallback((sid: string, pid: string, enabled: boolean) => () => {
    muteParticipant({
      conferenceIdentifier: instance.conferenceIdentifier,
      sid,
      pid,
      enabled,
    });

    const participant = participants.find(p => p.id === pid);
    if (participant) {
      Toast.success({
        title: `You've muted ${participant.name}`,
      });
    }
  }, [participants, instance.conferenceIdentifier, muteParticipant]);

  if (!you.isHost) {
    return (
      <Fragment>
        {participants.map(liveParticipant => {
          return (
            <InConferenceParticipant
              key={liveParticipant.id}
              isYou={you.id === liveParticipant.id}
              participant={liveParticipant}
              onMute={handleMute}
              twilioParticipant={null}
              canChangeVisibility={instance.features.visibilityToggle} />
          );
        })}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={styles.subHeader}>
        In Conference
      </div>
      {participants.map(liveParticipant => {
        const twilioParticipant =
          liveParticipant.twilioIdentity === twilio.room.localParticipant.identity
            ? twilio.room.localParticipant
            : twilioParticipants.find(p => p.identity === liveParticipant.twilioIdentity);

        return (
          <InConferenceParticipant
            key={liveParticipant.id}
            isYou={you.id === liveParticipant.id}
            participant={liveParticipant}
            twilioParticipant={twilioParticipant}
            onMute={handleMute}
            hostCount={hostCount}
            hostAbilities
            canChangeVisibility />
        );
      })}
    </Fragment>
  );
}

type InConferenceParticipantProps = {
  isYou: boolean;
  participant: LiveParticipant;
  twilioParticipant: RemoteParticipant | LocalParticipant;
  onMute: (sid: string, pid: string, enabled: boolean) => () => void;
  hostAbilities?: boolean;
  hostCount?: number;
  canChangeVisibility?: boolean;
};

const InConferenceParticipant = memo(({ isYou, participant, twilioParticipant, onMute, hostCount = 1, hostAbilities = false, canChangeVisibility = false }: InConferenceParticipantProps) => {
  const type = participant.status === 'waiting-room'
    ? ''
    : participant.isHost
      ? ` (${hostCount > 1 ? 'Co-Host' : 'Host'})`
      : ` (${enumUtils.CallRole.getName(participant.role) ?? 'Unknown'})`;

  const name = `${participant.name}${type}`;
  const originalName = `${participant.originalName ?? participant.name}${type}`;

  if (!hostAbilities) {
    return (
      <div className={styles.row}>
        <UserAvatar
          className={styles.avatar}
          pictureUrl={participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null}
          size={30} />
        <div className={styles.details}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
        <div className={styles.actions}>
          {canChangeVisibility && isYou && (
            <Fragment>
              <ParticipantVisibility
                participant={participant}
                isYou={isYou} />
              <InConferenceParticipantPopper
                participant={participant}
                isYou={isYou}
                canMakeHost={false}
                canRemove={false}
                canChangeRole={false}
                canRequestLog={false}
                canChangeVisibility={true} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null}
        size={30} />
      <div className={styles.details}>
        <div className={styles.name}>
          <ModeratedParticipantName participant={participant} displayName={originalName} />
        </div>
        <div className={styles.actions}>
          <ParticipantVisibility
            participant={participant}
            isYou={isYou} />
          {!isYou && <ParticipantDeviceInfo participant={participant} />}
          <ParticipantTwilioActions
            participant={participant}
            twilioParticipant={twilioParticipant}
            onMute={onMute} />
          <InConferenceParticipantPopper
            participant={participant}
            isYou={isYou}
            canMakeHost={!participant.isHost}
            canRemove={!isYou}
            canChangeRole={!participant.isHost && validRoleChangeAuths.includes(participant.auth)}
            canRequestLog={!isYou}
            canChangeVisibility={canChangeVisibility} />
        </div>
      </div>
    </div>
  );
});

type ParticipantVisibilityProps = {
  participant: LiveParticipant;
  isYou: boolean;
};

function ParticipantVisibility({ participant, isYou }: ParticipantVisibilityProps) {
  return (
    participant.isVisible
      ? (
        <Tooltip
          title={isYou ? `You're visible to attendees` : 'Is visible to attendees'}
          placement='top'
          tooltipstyles={{ top: 8, padding: '5px', textAlign: 'center' }}>
          <div className={styles.visibilityOn}>
            <VisibilityIcon />
          </div>
        </Tooltip>
      )
      : (
        <Tooltip
          title={isYou ? `You're not visible to attendees unless your camera or microphone is active` : 'Is not visible to attendees'}
          placement='top'
          tooltipstyles={{ top: 8, padding: '5px', textAlign: 'center' }}>
          <div className={styles.visibilityOff}>
            <VisibilityOffIcon />
          </div>
        </Tooltip>
      )
  );
}

type ParticipantTwilioActionsProps = {
  participant: LiveParticipant;
  twilioParticipant: RemoteParticipant | LocalParticipant;
  onMute: (sid: string, pid: string, enabled: boolean) => () => void;
};

function ParticipantTwilioActions({ participant, twilioParticipant, onMute }: ParticipantTwilioActionsProps) {
  const publications = usePublications(twilioParticipant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  const audioEnabled = useIsTrackEnabled(audioTrack);

  if (!twilioParticipant) return <div className={styles.micPlaceholder} />;
  if (participant.type === 'phone') {
    if (participant.isMutedRemotely) {
      return (
        <div onClick={onMute(twilioParticipant?.sid, participant.id, true)} className={styles.phoneOn}>
          <PhoneDisabled />
        </div>
      );
    } else {
      return (
        <div onClick={onMute(twilioParticipant?.sid, participant.id, false)} className={styles.phoneOn}>
          <PhoneIcon />
        </div>
      );
    }
  }

  return (
    audioEnabled
      ? (
        <div
          className={styles.micOn}
          onClick={onMute(twilioParticipant?.sid, participant.id, false)}>
          <MicOnIcon />
        </div>
      )
      : (
        <div className={styles.micOff}>
          <MicOffIcon />
        </div>
      )
  );
}

type InConferenceParticipantPopperProps = {
  participant: LiveParticipant;
  isYou: boolean;
  canMakeHost: boolean;
  canChangeVisibility: boolean;
  canRemove: boolean;
  canChangeRole: boolean;
  canRequestLog: boolean;
};

function InConferenceParticipantPopper({ participant, isYou, canMakeHost, canChangeVisibility, canRemove, canChangeRole, canRequestLog }: InConferenceParticipantPopperProps) {
  const { giveHost, removeParticipant, changeParticipantVisibility, changeParticipantRole } = useCoordinator();
  const socket = useSocket();

  const canChangeToRespondent = useMemo(() => canChangeRole && participant.role === CallRole.Attendee, [canChangeRole, participant]);

  const handleGiveHost = useCallback((close: () => void) => () => {
    giveHost({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      selfKeepHost: true,
    });
    Toast.success({
      title: `You've given host to ${participant.name}`,
    });
    close();
  }, [giveHost, participant]);

  const handleVisibility = useCallback((close: () => void) => () => {
    changeParticipantVisibility({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      visibility: !participant.isVisible,
    });
    Toast.success({
      title: isYou
        ? `You've made yourself ${participant.isVisible ? 'hidden' : 'visible'}`
        : `You've changed ${participant.name} to ${participant.isVisible ? 'hidden' : 'visible'}`,
    });
    close();
  }, [changeParticipantVisibility, isYou, participant]);

  const handleRemove = useCallback((close: () => void) => () => {
    removeParticipant({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
    });
    Toast.success({
      title: `You've removed ${participant.name} from the conference`,
    });
    close();
  }, [removeParticipant, participant]);

  const handleChangeToRespondent = useCallback((close: () => void) => () => {
    changeParticipantRole({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      role: CallRole.Respondent,
    });

    Toast.success({
      title: `You've changed ${participant.name} to a respondent`,
      body: `They've been removed from the conference and will need to rejoin with their new role.`,
    });
    close();
  }, [changeParticipantRole, participant]);

  const requestLog = useCallback((close: () => void) => () => {
    socket.raw.emit(ConferenceSocketEvent.RequestLog, {
      pid: participant.id,
      conferenceIdentifier: participant.conferenceIdentifier,
    });

    Toast.success({
      title: `You've requested debug information from the participant.`,
      body: `You will need an engineer to pull the data.`,
    });
    close();
  }, [socket, participant]);

  if (!canMakeHost && !canChangeVisibility && !canRemove && !canChangeToRespondent && !canRequestLog) return <div className={styles.popperPlaceholder} />;

  return (
    <PopupState
      variant="popper"
      popupId="participant-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <MoreHorizontalAnchor open={popupState.isOpen} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom-end"
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {canMakeHost && (
                  <PopperMenuItem onClick={handleGiveHost(popupState.close)}>
                    Make Host
                  </PopperMenuItem>
                )}
                {canChangeVisibility && (
                  <PopperMenuItem onClick={handleVisibility(popupState.close)}>
                    {participant.isVisible ? 'Make Hidden' : 'Make Visible'}
                  </PopperMenuItem>
                )}
                {canRemove && (
                  <PopperMenuItem onClick={handleRemove(popupState.close)}>
                    Remove
                  </PopperMenuItem>
                )}
                {canChangeToRespondent && (
                  (
                    <PopperMenuItem onClick={handleChangeToRespondent(popupState.close)}>
                      Change to Respondent
                    </PopperMenuItem>
                  )
                )}
                {canRequestLog && (
                  (
                    <PopperMenuItem onClick={requestLog(popupState.close)}>
                      {`Request Debug Logs`}
                    </PopperMenuItem>
                  )
                )}
              </PopperMenu>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

type ParticipantDeviceInfoProps = {
  participant: LiveParticipant;
};

function ParticipantDeviceInfo({ participant }: ParticipantDeviceInfoProps) {
  if (!participant.deviceInfo) return null;

  const hasError = participant.deviceInfo.audio.error || participant.deviceInfo.video.error;

  const Icon = hasError ? PhonelinkOffIcon : PhonelinkIcon;

  const ToolTipContent = (
    <div>
      <div>Audio</div>
      <DeviceInfo info={participant.deviceInfo.audio} />
      <div>Video</div>
      <DeviceInfo info={participant.deviceInfo.video} />
      <div>Last updated on {format(new Date(participant.deviceInfo.lastEventTs), 'p')}</div>
    </div>
  );

  return (
    <Tooltip
      title={ToolTipContent}
      placement='top'>
      <Icon className={styles.deviceIcon} />
    </Tooltip>
  );
}

type DeviceInfo = LiveParticipant['deviceInfo']['audio'];

function DeviceInfo({ info }: { info: DeviceInfo }) {
  if (info.error) {
    return `Error: ${info.error}`;
  } else if (info.isPrompting) {
    return `Waiting on user permission prompt`;
  } else {
    return `Ready: ${info.deviceList.length} devices available`;
  }
}

const validRoleChangeAuths: Participant['auth'][] = ['authorized-member', 'off-platform'];