import * as types from '@store/action-types';
import type {
  UserCheckFinishedAction,
  UserCheckFinishedState,
  UserLogoutState,
  UserLogoutAction,
  UserAuthenticationChangeAction,
  UserInfoChangeAction,
  UserInfoChangeState,
  UserCheckStartedAction,
  UserSettingsUpdated,
} from '@store/interfaces';
import * as $session from '@services/auth/session';

export const userAuthenticationChange = (): UserAuthenticationChangeAction => {
  return {
    type: types.USER_AUTHENTICATION_CHANGE,
    state: {
      authenticated: $session.isAuthenticated(),
    },
  };
};

export const userCheckFinished = (data: UserCheckFinishedState): UserCheckFinishedAction => {
  if (data.error && !data.error.offline) {
    if (process.env.__DEV__) {
      console.error(data);
    }
  }

  return {
    type: types.USER_CHECK_FINISHED,
    state: {
      ...data,
      authenticated: !data.error ? $session.isAuthenticated() : false,
    },
  };
};

export const userCheckStarted = (): UserCheckStartedAction => {
  return {
    type: types.USER_CHECK_STARTED,
    state: {
      authenticated: $session.isAuthenticated(),
    },
  };
};

export const userInfoChange = (data: UserInfoChangeState = {}): UserInfoChangeAction => {
  return {
    type: types.USER_INFO_CHANGE,
    ...data,
  };
};

export const userSettingsUpdated = (data: UserSettingsUpdated.State): UserSettingsUpdated.Action => {
  return {
    type: types.USER_SETTINGS_UPDATED,
    ...data,
  };
};

export const logout = (data: UserLogoutState = {}): UserLogoutAction => {
  if ($session.isAuthenticated()) {
    $session.destroy();

    return {
      type: types.USER_LOGOUT,
      ...data,
    };
  }

  return userAuthenticationChange();
};