import { TaggingItemBuilder } from './interfaces';

export function surveyTaggingReducer(state: TaggingItemBuilder.State, action: TaggingItemBuilder.Action): TaggingItemBuilder.State {

  const newState = {
    ...state,
    action: actionReducer(state.action, action),
    // conditions: surveyConditionsReducer(state.conditions, action as ConditionsBuilder.Action),
  };

  logAction(state, newState, action);

  return newState;
}

function actionReducer(state: TaggingItemBuilder.State['action'], action: TaggingItemBuilder.Action): TaggingItemBuilder.State['action'] {

  switch (action.type) {

    case 'action-updated':
      return action.payload;

    default:
      return state;
  }
}

function logAction(oldState: TaggingItemBuilder.State, newState: TaggingItemBuilder.State, action: TaggingItemBuilder.Action) {
  if (process.env.VANCERY_ENV !== 'production') {
    console.groupCollapsed('Survey Tagging Builder Action', action);
    console.log('Old State', oldState);
    console.log('New State', newState);
    console.groupEnd();
  }
}