import { urls } from '@consts';
import Button from '@/components/Button';
import { BillingDoneBaseProps } from './interfaces';
import styles from './style/Done.css';

type Props = BillingDoneBaseProps;

export const AdHocCall = ({ navigation, nextLabel }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          {`You're all set for the call!`}
        </h1>
        <div className={styles.copy}>
          {`Your suggested call times will be reviewed, and if selected, you will receive a calendar invitation from the system with dial information for the consultation soon!`}
        </div>
        <div className={styles.copy2}>
          {`You will be paid through your connected payout method if you are selected for the project.`}
        </div>
        <div className={styles.navigation}>
          <Button
            variant="brick"
            title={nextLabel}
            onClick={navigation.next} />
          {/* <div className={styles.external}>
            <Button
              href={`${urls.FAQ}`}
              target="_blank"
              variant="link">
              {`Learn more about commonly asked questions`}
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

AdHocCall.defaultProps = {
  nextLabel: 'Complete',
};