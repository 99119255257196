import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useUpdateLogoMutation } from '@containers/Branding/hooks';
import { useSelectGroup } from '@containers/Store';
import { MediaOrientation } from '@enums';
import { ButtonOutlined } from '@presentation';
import { ImageCrop } from '@/components/ImageCrop';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Cropper } from './Settings.Branding.Logo.Cropper';
import * as Landscape from './Settings.Branding.Logo.Landscape';
import * as Square from './Settings.Branding.Logo.Square';
import styles from './style/Settings.Branding.Logo.css';

type Props = unknown;

export const Logo = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const [toggle, UploadModal] = useModal(Cropper);

  if (query.isLoading) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Logos</h2>
        <div className={styles.main}>
          <div className={styles.upload}>
            <ButtonOutlined
              borderWidth="thin"
              className={styles.btn}
              color="silver"
              fontWeight="light"
              onClick={toggle}>
              Manage Logos
            </ButtonOutlined>
          </div>
          <div className={styles.frame}>
            <Square.Image imageSrc={query.data?.logos?.square?.url} />
          </div>
          <div className={styles.frame}>
            <Landscape.Image imageSrc={query.data?.logos?.landscape?.url} />
          </div>
        </div>
      </div>
      <UploadModal />
    </div>
  );
};

Logo.displayName = 'Settings.Branding.Logo';

type LogoUploadModalProps = {
  imageSrc?: string;
} & Pick<ModalProps, 'onClose' | 'open'>;

const LogoUploadModal = (props: LogoUploadModalProps) => {
  const { branding } = useSelectGroup();
  const dispatch = useDispatch();

  const mutation = useUpdateLogoMutation({
    onSuccess: res => {
      dispatch(actions.brandingUpdated({
        branding: {
          logos: {
            ...branding.logos,
            ...res.logo,
          },
        },
      }));
      props.onClose();
    },
  });

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}>
      <ImageCrop
        imageUrl={props.imageSrc}
        isSubmitting={mutation.isLoading}
        onSave={data => mutation.mutate({
          blob: data.blob,
          orientation: MediaOrientation.Square,
        })}
        ruleOfThirds={true} />
    </Modal>
  );
};