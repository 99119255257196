import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@/enums';
import { useSurveyFormQuestionAnswer } from '@/containers/SurveyForm';
import LongTextResponseInput from './LongTextResponseInput';
import { LongTextResponseProps } from './interfaces';
import { Rationale } from './Rationale';

export const LongTextResponse = ({ className, item }: LongTextResponseProps) => {
  const [answer, setAnswer] = useSurveyFormQuestionAnswer<SurveyQuestionType.LongTextResponse>();

  const updateAnswer = useCallback((value: string) => {
    setAnswer({
      value,
    });
  }, [setAnswer]);

  const isLongEnough = useMemo(() => {
    return answer?.value?.length >= item.settings.characterMinimum;
  }, [
    item.settings.characterMinimum,
    answer,
  ]);

  return (
    <>
      <LongTextResponseInput
        className={className}
        isLongEnough={isLongEnough}
        updateAnswer={updateAnswer}
        value={answer?.value} />
      <Rationale settings={item.settings.rationale} />
    </>
  );
};

export default LongTextResponse;