import { Fragment, useCallback, memo } from 'react';
import { utils as enumUtils } from '@enums';
import type { IConference } from '@containers/Conference';
import { useCoordinator, useConferenceInstance } from '@containers/Conference';
import type { Participant as LiveParticipant } from '@/types/conferences.live';
import { ModeratedParticipantName } from '@screens/Conference.Common';
import { Button } from '@/components/Button';
import { UserAvatar } from '@/components/UserAvatar';
import Toast from '@/components/Toast';
import { useYourParticipant } from './hooks';
import styles from './style/Bar.Right.Participants.css';

export function BarRightParticipantsWaitingRoom() {
  const { waitingRoomAdmit, waitingRoomReject } = useCoordinator();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();

  const handleAdmit = useCallback((participant: LiveParticipant) => () => {
    waitingRoomAdmit({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
    });
    Toast.success({
      title: `You've admitted ${participant.name} into the conference`,
    });
  }, [waitingRoomAdmit]);

  const handleReject = useCallback((participant: LiveParticipant) => () => {
    waitingRoomReject({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
    });
    Toast.success({
      title: `You've denied ${participant.name} into the conference`,
    });
  }, [waitingRoomReject]);

  if (!you.isHost) return null;

  const waiting = instance.participants.filter(p => p.status === 'waiting-room' || p.status === 'dialing' || p.status === 'admitting');

  if (!waiting.length) return null;

  return (
    <Fragment>
      <div className={styles.subHeader}>
        Waiting Room
      </div>
      {waiting.map(participant => (
        <WaitingRoomParticipant
          key={`${participant.id}`}
          participant={participant}
          onAdmit={handleAdmit}
          onReject={handleReject} />
      ))}
    </Fragment>
  );
}

type WaitingRoomParticipantProps = {
  participant: LiveParticipant;
  onAdmit: (participant: LiveParticipant) => () => void;
  onReject: (participant: LiveParticipant) => () => void;
};

const WaitingRoomParticipant = memo(({ participant, onAdmit, onReject }: WaitingRoomParticipantProps) => {
  const type = participant.isHost
    ? 'Host'
    : `${enumUtils.CallRole.getName(participant.role) ?? 'Unknown'}`;

  const name = `${participant.name} (${type})`;
  const originalName = `${participant.originalName ?? participant.name} (${type})`;

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={participant.auth === 'authorized-member' || participant.auth === 'outside-member' ? participant.pictureUrl : null}
        size={30} />
      <div className={styles.details}>
        <div className={styles.name}>
          <ModeratedParticipantName participant={participant} displayName={originalName} />
        </div>
        {participant.status != 'dialing' &&
          <div className={styles.actions}>
            <Button
              className={styles.actionButton}
              variant="brick"
              onClick={onAdmit(participant)}>
              Admit
            </Button>
            <Button
              className={styles.actionButton}
              variant="brick"
              color="destructive"
              onClick={onReject(participant)}>
              Remove
            </Button>
          </div>
        }
        {participant.status === 'dialing' && <div className={styles.actionButton}>Calling...</div>}
      </div>
    </div>
  );
});