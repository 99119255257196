import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { BackLink } from '@presentation/Back';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectStateContainer } from '@containers/GroupProject/Project.State.Container';
import { useProjectState } from '@containers/GroupProject/hooks';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import { ProjectSurveyBuilderState, SurveyQuotasGoalContext, useIsEditingSurvey, useHasUnsavedChanges, useSurveyBuilderState, useCanSaveSurvey } from '@containers/SurveyBuilder';
import { useFetchProjectGoal, useDiscardSurveyDraft } from '@utils/api';
import { DiscardDraftButton } from '@screens/ProjectCreation/Footer';
import { ProjectSurveysContainer } from '@/access/admin/containers/Project.Survey';
import { RouteLeavingGuard, RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { Alert } from '@/components/Modal/Alert';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs, DraftNotification } from '@/components/SurveyBuilder';
import { useFetchProjectSurvey } from './useFetchSurvey';
import { useSaveProjectSurvey } from './useSaveSurvey';
import styles from './style/ProjectSurveyEditor.css';

type LocationState = {
  from: string;
};

export const ProjectSurveyEditor = () => {
  return (
    <ProjectStateContainer>
      <WithState />
    </ProjectStateContainer>
  );
};

const WithState = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const state = useProjectState();

  if (!state.project) return <ActivityIndicator show />;

  return (
    <ProjectSurveysContainer projectId={projectId}>
      <ProjectSurveyBuilderState projectType={state.project.projectType}>
        <ProjectStateContainer>
          <SurveyEditor />
        </ProjectStateContainer>
      </ProjectSurveyBuilderState>
    </ProjectSurveysContainer>
  );
};

ProjectSurveyEditor.displayName = 'Group.Project.Survey.Editor';

const SurveyEditor = () => {
  const params = useParams<Stringify<ISurveyId>>();
  const surveyId = useMemo(() => +params.surveyId, [params.surveyId]);
  const location = useLocation<LocationState>();
  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const hasUnsavedChanges = useHasUnsavedChanges();
  const [goalResponse, fetchGoal] = useFetchProjectGoal();

  const { projectId } = useMatchProjectIdFromUrl();

  const {
    isSuccess,
  } = useFetchProjectSurvey({ surveyId });
  const {
    isLoading: isSaving,
    mutateAsync: mutateSurvey,
  } = useSaveProjectSurvey(projectId);

  useEffect(() => {
    if (projectId) {
      fetchGoal(projectId);
    }
  }, [fetchGoal, projectId]);

  const saveSurvey = useCallback(() => {
    return mutateSurvey({
      survey: state.survey,
      draftVersionId: state.draft.surveyVersionId,
    });
  }, [
    mutateSurvey,
    state.survey,
    state.draft.surveyVersionId,
  ]);

  const backTo = useMemo(() => {
    return location.state?.from ||
      getLocationFor.project.surveyResponses({ slug: projectId.toString() });
  }, [projectId, location.state]);

  const canSave = useCanSaveSurvey();

  const { mutate: discardDraft } = useDiscardSurveyDraft({});

  const tabs = useProjectSurveyBuilderTabs();

  if (!isSuccess || !projectId) {
    return <ActivityIndicator show />;
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <BackLink
            className={styles.back}
            to={backTo} />
          <div className={styles.draftNotification}>
            <DraftNotification />
          </div>
          <SurveyQuotasGoalContext.Provider value={goalResponse.value?.goal?.value}>
            <ProjectSurveyBuilder tabs={tabs} projectId={projectId} />
          </SurveyQuotasGoalContext.Provider>
          <div className={styles.btns}>
            <DiscardDraftButton className={styles.save} />
            <ButtonActivityIndicator
              className={styles.save}
              onClick={saveSurvey}
              loading={isSaving}
              implicitDisable={isSaving}
              disabled={!canSave}>
              Save
            </ButtonActivityIndicator>
          </div>
        </div>
      </div>
      <RouteLeavingGuard block={hasUnsavedChanges()}>
        {(guard: RouteLeavingGuardState) => (
          <Alert
            confirmText="Save"
            cancelText='Discard'
            message="You have unsaved changes, do you want to save your draft?"
            onClose={() => { discardDraft({ surveyVersionId: state.draft.surveyVersionId }); guard.confirmNavigation(); }}
            onConfirm={guard.confirmNavigation}
            open={guard.open} />
        )}
      </RouteLeavingGuard>
    </>
  );

};

export default ProjectSurveyEditor;