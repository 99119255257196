import { SurveyBuilder, SurveyDetailsBuilder } from '../interfaces';

export function detailsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyDetailsBuilder.State {
  switch (action.type) {
    case 'name-changed': {
      return {
        ...safeOverrides(),
        name: action.payload.name,
      };
    }

    case 'type-changed': {
      return {
        ...safeOverrides(),
        typeId: action.payload.type,
      };
    }
  }

  return state.survey.surveyDetails;

  function safeOverrides() {
    return state.survey.surveyDetails ?? {};
  }
}