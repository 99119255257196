import { useCallback, useContext, useMemo, useState } from 'react';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext, MedicalExpertiseContext } from '@containers/MedicalOnboarding/Context';
import { Length, validators } from '@containers/MedicalOnboarding/utils';
import * as Input from '@/components/MedicalProfile/Input';
import styles from './style/Input.DeaNumber.css';

type Props = unknown;

export const DeaNumber = (props: Props) => {
  const ctx = {
    background: useContext(MedicalBackgroundContext),
    expertise: useContext(MedicalExpertiseContext),
    identification: useContext(MedicalBackgroundIdentificationContext),
  };

  const [value, setValue] = useState<string>(ctx.identification.form.deaNumber);

  const save = useCallback((value: string) => {
    ctx.background.mutation.mutate({
      identification: {
        ...ctx.identification.form,
        deaNumber: value,
      },
    }, {
      onSuccess: ctx.identification.rehydrate,
    });

    ctx.identification.setValue({
      deaNumber: value,
    });

  }, [
    ctx.background.mutation,
    ctx.identification,
  ]);

  const editing = useMemo(() => {
    return ctx.identification.form.deaNumber !== value;
  }, [
    ctx.identification.form.deaNumber,
    value,
  ]);

  const cancel = useCallback(() => {
    setValue(ctx.identification.form.deaNumber);
  }, [
    ctx.identification.form.deaNumber,
    setValue,
  ]);

  const disabled = useMemo(() => {

    return validators.deaNumber(value);

  }, [value]);

  return (
    <div className={styles.root}>
      <div className={styles.field}>
        <Input.Text
          className={styles.input}
          maxLength={Length.dea}
          minLength={Length.dea}
          placeholder="Enter DEA Number"
          onChange={e => setValue(e.target.value)}
          value={value} />
      </div>
      {editing &&
        <div className={styles.btns}>
          <button
            className={styles.cancel}
            disabled={ctx.background.mutation.isLoading}
            onClick={cancel}>
            <Clear className={styles.icon} />
          </button>
          <button
            className={styles.save}
            disabled={disabled || ctx.background.mutation.isLoading}
            onClick={() => save(value)}>
            <Check className={styles.icon} />
          </button>
        </div>}
    </div>
  );
};

DeaNumber.displayName = 'Member.Experience.Input.DeaNumber';