import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyPipedValueType,
  SurveyOptionType,
} from '@enums/Survey';
import {
  CompetitiveIntelligenceQuestion,
  CompetitiveIntelligenceTemplateSection,
  SurveyTemplateOptionKey,
  SurveyTemplateType,
} from '@enums/survey.template';
import type { SurveyTemplate } from '@/types/survey.templates';
import type {
  MultipleChoiceQuestion,
  SurveyQuestion,
  SurveyBuilderVersion,
} from '@/types/survey';
import * as $question from '../utils/question';
import * as $shared from './template.shared-questions';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.CompetitiveIntelligenceTemplate = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.CompetitiveIntelligenceTemplate = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.CompetitiveIntelligenceTemplate): SurveyBuilderVersion {

  const screeningSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: CompetitiveIntelligenceTemplateSection.Screening },
    },
    name: 'Screening Questions',
    ordinal: 1,
  });

  const competitorIndexingSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: CompetitiveIntelligenceTemplateSection.CompetitorIndexing },
    },
    name: 'Competitor Indexing Questions',
    ordinal: 2,
  });

  const competitorRankingSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: CompetitiveIntelligenceTemplateSection.CompetitorRanking },
    },
    name: 'Competitor Ranking Questions',
    ordinal: 3,
  });

  const currentCustomerSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: CompetitiveIntelligenceTemplateSection.CurrentCustomer },
    },
    hidden: true,
    name: 'Current Customer Questions',
    ordinal: 4,
  });

  const formerCustomerSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: CompetitiveIntelligenceTemplateSection.FormerCustomer },
    },
    hidden: true,
    name: 'Form Customer Questions',
    ordinal: 5,
  });

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgCurrentCustomerQuestion() {
    const questionIdentifier = cuid();

    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'Yes',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const dqIfNoLogic = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: {
        identifier: noOption.base.identifier,
      },
    });

    const logic = [dqIfNoLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: CompetitiveIntelligenceQuestion.OrgCurrentCustomer,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Is your organization a current customer of '),
        $template.generateCategoryNode(data.category),
        $template.generateTextNode('?'),
      ]),
    });

    return { logic, question };
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: screeningSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: screeningSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleQuestion({ section: screeningSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: screeningSection });
  }

  function SubjectAwarenessQuestion() {
    const questionIdentifier = cuid();
    const options = [
      // 'My company is a current customer',
      // 'I am a former customer',
      `I have evaluated this company, but have never been a customer`,
      `I am aware of this company at a high level`,
      `I don't have much awareness of this company`,
    ];
    const currentCustomerOption = $template.generateOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
        template: {
          key: SurveyTemplateOptionKey.CurrentCustomer,
          linkedEntity: null,
        },
      },
      ordinal: 1,
      value: 'My company is a current customer',
    });
    const formerCustomerOption = $template.generateOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
        template: {
          key: SurveyTemplateOptionKey.FormerCustomer,
          linkedEntity: null,
        },
      },
      ordinal: 2,
      value: 'I am a former customer',
    });

    const matrixRows = [
      $template.generateMatrixGridTargetRow({
        ordinal: 1,
        target: data.target.value,
      }),
      ...data.competitors.map((item, i) => $template.generateMatrixGridCompetitorRow({
        item,
        ordinal: i + 2,
      })),
    ];

    const formerCustomerSectionResponseLogic = matrixRows.map(row => {
      return $template.generateMatrixOptionSelectedLogic({
        action: {
          type: SurveyActionType.IncludeSection,
          section: {
            identifier: formerCustomerSection.identifier,
          },
        },
        question: { identifier: questionIdentifier },
        option: { identifier: formerCustomerOption.base.identifier },
        row: { identifier: row.base.identifier },
      });
    });

    const currentCustomerSectionResponseLogic = matrixRows.map(row => {
      return $template.generateMatrixOptionSelectedLogic({
        action: {
          type: SurveyActionType.IncludeSection,
          section: {
            identifier: currentCustomerSection.identifier,
          },
        },
        question: { identifier: questionIdentifier },
        option: { identifier: currentCustomerOption.base.identifier },
        row: { identifier: row.base.identifier },
      });
    });

    const logic = [
      ...formerCustomerSectionResponseLogic,
      ...currentCustomerSectionResponseLogic,
    ];

    const question = $template.generateMatrixGridQuestion({
      identifier: questionIdentifier,
      options: [
        currentCustomerOption,
        formerCustomerOption,
        ...options.map((value, i) => $template.generateOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            template: {},
          },
          ordinal: i + 3,
          value,
        })),
      ],
      matrixRows,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectAwareness },
      },
      section: {
        identifier: competitorIndexingSection.identifier,
      },
      settings: {
        ...$question.matrixGrid.DefaultSettings,
        randomizeRows: true,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please indicate your awareness level of each of the following in the `),
        $template.generateCategoryNode(data.category),
        $template.generateTextNode(` space?`),
      ]),
    });

    return {
      currentCustomerOption,
      formerCustomerOption,
      question,
      logic,
    };
  }

  const subjectAwarenessQuestion = SubjectAwarenessQuestion();

  function BrandStrengthQuestion() {
    return $template.generateSlidersQuestion({
      matrixRows: [
        $template.generateSlidersTargetRow({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorSlidersRow({
          item,
          ordinal: i + 2,
        })),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectBrandStrength },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        randomization: true,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 10,
          increment: 1,
          label: $template.generateRichTextValue([
            $template.generateTextNode('Brand Strength'),
          ]),
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rate each of the following on a 10-point scale for Brand Strength. (1 = No Brand Strength, 10 = Excellent Brand Strength)`),
      ]),
    });
  }

  const brandStrengthQuestion = BrandStrengthQuestion();

  function BrandStrengthReasoningQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectBrandStrengthReason },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please explain why you ranked `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.FirstRankedOption,
          },
          questionIdentifier: brandStrengthQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the market leader(s) in Brand Strength in 2-3 sentences.`),
      ]),
    });
  }

  function PriceQuestion() {
    return $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectPrice },
      },
      matrixRows: [
        $template.generateSlidersTargetRow({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorSlidersRow({
          item,
          ordinal: i + 2,
        })),
      ],
      section: {
        identifier: competitorRankingSection.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        randomization: true,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 10,
          increment: 1,
          label: $template.generateRichTextValue([
            $template.generateTextNode('Price'),
          ]),
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rate each of the following on a 10-point scale for Price. (1 = Very Cheap, 10 = Very Expensive)`),
      ]),
    });
  }

  const priceQuestion = PriceQuestion();

  function PriceReasoningQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectPriceReason },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`You ranked `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.FirstRankedOption,
          },
          questionIdentifier: priceQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the most expensive provider(s), and `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.LastRankedOption,
          },
          questionIdentifier: priceQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the least expensive provider(s) in the `),
        categoryNode(),
        $template.generateTextNode(` space. Please elaborate on the pricing schemes in the `),
        categoryNode(),
        $template.generateTextNode(` space and your perception of ROI in 2-3 sentences.`),
      ]),
    });
  }

  function ServiceQualityQuestion() {
    return $template.generateSlidersQuestion({
      matrixRows: [
        $template.generateSlidersTargetRow({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorSlidersRow({
          item,
          ordinal: i + 2,
        })),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectServiceQuality },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        randomization: true,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 10,
          increment: 1,
          label: $template.generateRichTextValue([
            $template.generateTextNode('Service Quality'),
          ]),
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rate each of the following on a 10-point scale for Service Quality. (1 = Poor Service, 10 = Excellent Service)`),
      ]),
    });
  }

  const serviceQualityQuestion = ServiceQualityQuestion();

  function ServiceQualityReasoningQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectServiceQualityReason },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please explain why you ranked `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.FirstRankedOption,
          },
          questionIdentifier: serviceQualityQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the market leader(s) in Service Quality in 2-3 sentences.`),
      ]),
    });
  }

  function ProductValueQuestion() {
    return $template.generateRankingQuestion({
      options: [
        $template.generateTargetRankingOption({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorRankingOption({
          item,
          ordinal: i + 2,
        })),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectProductValue },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      settings: {
        ...$question.ranking.DefaultSettings,
        randomization: true,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`What is your perception of each of the following on Product Value?`),
      ]),
    });
  }

  const productValueQuestion = ProductValueQuestion();

  function ProductValueReasoningQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.SubjectProductValueReason },
      },
      section: {
        identifier: competitorRankingSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please explain why you ranked `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.FirstRankedOption,
          },
          questionIdentifier: productValueQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the market leader in Product Value in 2-3 sentences.`),
      ]),
    });
  }

  function CurrentCustomerRationaleQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.CurrentCustomerRationale },
      },
      section: {
        identifier: currentCustomerSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`You indicated that you are a current customer of `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.MatrixRowsSelected,
            option: {
              identifier: subjectAwarenessQuestion.currentCustomerOption.base.identifier,
            },
          },
          questionIdentifier: subjectAwarenessQuestion.question.base.identifier,
        }),
        $template.generateTextNode(`. Please elaborate in a couple of sentences as to the rationale for your organization using this provider.`),
      ]),
    });
  }

  function CurrentCustomerReplaceWillingnessQuestion() {
    const options = [
      'Very willing, we are not satisfied with our current provider',
      'Somewhat willing, there could be better options',
      'Not too willing, but always open to looking',
      'Not willing at all, we are very satisfied with our current provider',
    ];
    return $template.generateMultipleChoiceQuestion({
      options: options.map((value, i) => $template.generateMultipleChoiceOption({
        metadata: {
          canModifyValue: false,
          canDelete: false,
        },
        ordinal: i + 1,
        value,
      })),
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.CurrentCustomerReplacementWillingness },
      },
      section: {
        identifier: currentCustomerSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`How willing are you to consider replacing your current `),
        categoryNode(),
        $template.generateTextNode(` provider with others in the market?`),
      ]),
    });
  }

  function CurrentCustomerReplacementRationaleQuestion() {
    const options = [
      'Cost or other budget considerations',
      'Our current provider is good, but we think there could be better',
      'Our current provider does not solve our problems',
      'Customer service and support is not great',
    ];
    return $template.generateRankingQuestion({
      options: [
        ...options.map((value, i) => $template.generateRankingOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            template: {},
          },
          ordinal: i + 1,
          value,
        })),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.CurrentCustomerReplacementRationale },
      },
      section: {
        identifier: currentCustomerSection.identifier,
      },
      settings: {
        ...$question.ranking.DefaultSettings,
        randomization: true,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rank the below reasons for considering other options.`),
      ]),
    });
  }

  function CurrentCustomerReplacementQuestion() {
    // option conditions include not selected in subjectAwarenessQuestion.currentCustomerOption

    // const conditions: SurveyQuestionCondition<SurveyQuestionType.MatrixGrid> = [{
    //   question: {
    //     identifier: subjectAwarenessQuestion.question.base.identifier,
    //   },
    //   condition: {
    //     type: SurveyQuestionConditionType.NotChosen,
    //     value: {
    //       row:
    //     },
    //   } as MatrixGridQuestion.Logic.Condition,
    // }];
    return $template.generateMultiselectQuestion({
      options: [
        $template.generateTargetMultiselectOption({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
          item,
          ordinal: i + 2,
        })),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: data.competitors.length + 2,
          type: SurveyOptionType.Default,
          value: 'Other (please specify)',
        }),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isExclusive: true,
          },
          ordinal: data.competitors.length + 3,
          type: SurveyOptionType.NoneOfTheAbove,
          value: 'None of the above',
        }),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.CurrentCustomerReplacement },
      },
      section: {
        identifier: currentCustomerSection.identifier,
      },
      settings: {
        ...$question.multiselect.DefaultSettings,
        randomization: true,
        includeNoneOfTheAbove: true,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`If you were to adopt a different provider of `),
        categoryNode(),
        $template.generateTextNode(`, which of the following would you consider.`),
      ]),
    });
  }

  function FormerCustomerRationaleQuestion() {
    const options = [
      `I left a role at a former employer, but the company didn't abandon the service`,
      'There were issues with our service or experience',
      'We migrated to another provider that was more compelling',
      'We no longer needed the service',
    ];
    return $template.generateMultipleChoiceQuestion({
      options: [
        ...options.map((value, i) => $template.generateMultipleChoiceOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            template: {},
          },
          ordinal: i + 1,
          value,
        })),
        $template.generateMultipleChoiceOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: options.length + 1,
          type: SurveyOptionType.Default,
          value: 'Other (please specify)',
        }),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.FormerCustomerRationale },
      },
      section: {
        identifier: formerCustomerSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`You indicated that you were a former customer of `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.MatrixRowsSelected,
            option: {
              identifier: subjectAwarenessQuestion.formerCustomerOption.base.identifier,
            },
          },
          questionIdentifier: subjectAwarenessQuestion.question.base.identifier,
        }),
        $template.generateTextNode(`. At a high-level, could you please elaborate as to the rationale for an organization like yours no longer using this provider?`),
      ]),
    });
  }

  function FormerCustomerReplacementQuestion() {
    // option conditions include not selected in subjectAwarenessQuestion.formerCustomerOption
    return $template.generateMultiselectQuestion({
      options: [
        $template.generateTargetMultiselectOption({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
          item,
          ordinal: i + 2,
        })),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: data.competitors.length + 2,
          type: SurveyOptionType.Default,
          value: 'Other (please specify)',
        }),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isExclusive: true,
          },
          ordinal: data.competitors.length + 3,
          type: SurveyOptionType.NoneOfTheAbove,
          value: 'None of the above',
        }),
      ],
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: { key: CompetitiveIntelligenceQuestion.FormerCustomerReplacement },
      },
      section: {
        identifier: formerCustomerSection.identifier,
      },
      settings: {
        ...$question.multiselect.DefaultSettings,
        randomization: true,
        includeNoneOfTheAbove: true,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Did you subsequently migrate to a different provider of `),
        categoryNode(),
        $template.generateTextNode(`? Please select which of any of the following you adopted.`),
      ]),
    });
  }

  const {
    logic: orgCurrentCustomerLogic,
    question: orgCurrentCustomerQuestion,
  } = OrgCurrentCustomerQuestion();

  const questions: SurveyQuestion[] = [
    orgCurrentCustomerQuestion,
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    subjectAwarenessQuestion.question,
    brandStrengthQuestion,
    BrandStrengthReasoningQuestion(),
    priceQuestion,
    PriceReasoningQuestion(),
    serviceQualityQuestion,
    ServiceQualityReasoningQuestion(),
    productValueQuestion,
    ProductValueReasoningQuestion(),
    CurrentCustomerRationaleQuestion(),
    CurrentCustomerReplaceWillingnessQuestion(),
    CurrentCustomerReplacementRationaleQuestion(),
    CurrentCustomerReplacementQuestion(),
    FormerCustomerRationaleQuestion(),
    FormerCustomerReplacementQuestion(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    logic: [
      ...subjectAwarenessQuestion.logic,
      ...orgCurrentCustomerLogic,
    ].filter(Boolean),
    messages: [],
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [
      screeningSection,
      competitorIndexingSection,
      competitorRankingSection,
      currentCustomerSection,
      formerCustomerSection,
    ],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.CompetitiveIntelligence,
    },
  };
}