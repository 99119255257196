import { forwardRef } from 'react';
import { SurveySectionNumber } from '@presentation/Survey';
import type { SurveySection } from '@/types/survey';
import { cx } from '@utils';
import { useIsEditingSurvey } from '@/containers/SurveyBuilder';
import { SurveyMetadata } from '@/components/SurveyBuilder.Question.Preview/Metadata';
import { SectionContextMenu } from './Section.ContextMenu';
import styles from './style/Header.css';

type Props = {
  className?: string;
  item: SurveySection;
};

export const SurveySectionHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {

  const metadata = [
    props.item.hidden && 'Hidden by default',
    props.item.isScreener && 'Screener',
  ].filter(Boolean);

  const editing = useIsEditingSurvey();

  return (
    <div ref={ref} className={cx(styles.root, props.className)}>
      <div className={styles.left}>
        <SurveySectionNumber
          className={styles.number}
          ordinal={props.item.ordinal} />
        {props.item.name}
        {!!metadata.length &&
          <SurveyMetadata
            items={metadata} />}
      </div>
      {!editing && <SectionContextMenu />}
    </div>
  );
});

export default SurveySectionHeader;