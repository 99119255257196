import cuid from 'cuid';
import type {
  SurveySection,
  SurveyQuestion,
  SurveyItem,
  SurveyMessage,
} from '@/types/survey';
import { SurveyItemType } from '@/enums';

export function generateSection({
  hidden = false,
  isScreener = false,
  ordinal = 0,
  ...data
}: GenerateSection): SurveySection {
  return {
    hidden,
    id: null,
    identifier: cuid(),
    isScreener,
    metadata: {
      randomize: false,
      randomizeSelf: null,
      linkedOrder: null,
      ...data.metadata,
    },
    ordinal,
    name: data.name,
  };
}

type GenerateSection =
  Partial<Pick<SurveySection, 'hidden' | 'isScreener' | 'ordinal'>> &
  Pick<SurveySection, 'name'> & {
    metadata: PartialBy<SurveySection['metadata'], 'randomize' | 'randomizeSelf' | 'linkedOrder'>;
  };

export function generateQuestionItem(data: SurveyQuestion): Omit<SurveyItem, 'ordinal'> {
  return {
    id: null,
    identifier: cuid(),
    type: SurveyItemType.Question,
    section: {
      identifier: data.section.identifier,
    },
    source: {
      identifier: data.base.identifier,
    },
  };
}

export function generateMessageItem(data: GenerateMessageItem): Omit<SurveyItem, 'ordinal'> {
  return {
    id: null,
    identifier: cuid(),
    type: SurveyItemType.Message,
    section: {
      identifier: data.section.identifier,
    },
    source: {
      identifier: data.identifier,
    },
  };
}

type GenerateMessageItem = {
  section: { identifier: string };
} & SurveyMessage;

export function generateMessage(data: GenerateMessage): SurveyMessage {
  return {
    id: null,
    identifier: cuid(),
    metadata: data.metadata,
    value: data.value,
  };
}

type GenerateMessage = Omit<SurveyMessage, 'id' | 'identifier'>;