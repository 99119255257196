import * as $session from './session';
import * as $sessionHttp from './session-http';
import type { OAuth2 as IOAuth2 } from './interfaces';

export function authorize({ url }: IOAuth2.Authorize.Params) {
  window.location.href = url;
}

export function callback({ identifier, code, state }: IOAuth2.AuthorizeCallback.Params): Promise<IOAuth2.AuthorizeCallback.Result> {
  return $sessionHttp.oauth2({
    identifier,
    code,
    state,
  })
  .then(session => {
    $session.set({
      authenticated: session.authenticated,
      csrf: session.csrf,
      expiry: session.expiry,
      userId: session.userId,
    });
    $session.setLastLoginStrategy({
      strategy: 'enterprise',
      identifier,
      endSessionUrl: session.endSessionUrl,
    });
    return Promise.resolve({
      type: 'redirect',
      returnUrl: session.returnUrl,
    });
  });
}