import { SurveyItemType } from '@enums/Survey';
import { SurveyItem } from '@/types/survey';
import { OrdinalMap, SurveyItemsBuilder } from '../interfaces';
import { SurveyBuilder, SurveySectionsBuilder, SurveyMessagesBuilder, SurveyQuestionsBuilder, SurveyAIEBuilder } from '../interfaces';
import { syncItemOrdinals } from './side-effects';

type State = SurveyItem[];

export function messageAdded(state: SurveyBuilder.State['survey'], action: SurveyMessagesBuilder.MessageAdded.Action): State {
  const item: SurveyItem = {
    id: null,
    identifier: action.item.identifier,
    ordinal: action.item.ordinal,
    section: {
      identifier: action.sectionIdentifier,
    },
    source: {
      identifier: action.messageIdentifier,
    },
    type: SurveyItemType.Message,
  };

  return itemAdded(state, item);
}

export function aieAdded(state: SurveyBuilder.State['survey'], action: SurveyAIEBuilder.ExerciseAdded.Action): State {
  const item: SurveyItem = {
    id: null,
    identifier: action.item.identifier,
    ordinal: action.item.ordinal,
    section: {
      identifier: action.sectionIdentifier,
    },
    source: {
      identifier: action.exerciseIdentifier,
    },
    type: SurveyItemType.AlternateImageExercise,
  };

  return itemAdded(state, item);
}

export function questionAdded(state: SurveyBuilder.State['survey'], action: SurveyQuestionsBuilder.QuestionAdded.Action): State {

  const item: SurveyItem = {
    id: null,
    identifier: action.item.identifier,
    ordinal: action.item.ordinal,
    section: {
      identifier: action.sectionIdentifier,
    },
    source: {
      identifier: action.questionIdentifier,
    },
    type: SurveyItemType.Question,
  };

  return itemAdded(state, item);
}

export function questionCopied(state: SurveyBuilder.State['survey'], action: SurveyQuestionsBuilder.QuestionCopied.Action): State {
  const sourceQuestion = state.questions.find(q => q.base.identifier === action.payload.sourceQuestionIdentifier);

  const item: SurveyItem = {
    id: null,
    identifier: action.payload.targetItemIdentifier,
    ordinal: state.items.length + 1,
    section: {
      identifier: sourceQuestion.section.identifier,
    },
    source: {
      identifier: action.payload.targetQuestionIdentifier,
    },
    type: SurveyItemType.Question,
  };

  return itemAdded(state, item);
}

function itemAdded(state: SurveyBuilder.State['survey'], item: SurveyItem): State {

  const ordinals = generateNewOrdinals();
  const syncItem = syncItemOrdinals(ordinals);

  return [
    ...state.items
      .filter(f => f.ordinal < item.ordinal)
      .map(syncItem),
    item,
    ...state.items
      .filter(f => f.ordinal >= item.ordinal)
      .map(syncItem),
  ];

  function generateNewOrdinals() {
    return state.items.reduce<OrdinalMap>((acc, x) => {
      acc[x.identifier] = x.ordinal < item.ordinal
        ? x.ordinal
        : x.ordinal + 1;
      return acc;
    }, {});
  }

}

export function itemRemoved(state: SurveyBuilder.State['survey'], identifier: string): State {

  const ordinal = state.items.find(f => f.identifier === identifier).ordinal;
  const ordinals = generateNewOrdinals();
  const syncItem = syncItemOrdinals(ordinals);

  return state.items
    .filter(f => f.identifier !== identifier)
    .map(syncItem);

  function generateNewOrdinals() {
    return state.items.reduce<OrdinalMap>((acc, x) => {
      acc[x.identifier] = x.ordinal < ordinal
        ? x.ordinal
        : x.ordinal > ordinal
          ? x.ordinal - 1
          : null;
      return acc;
    }, {});
  }
}

export function sectionRemoved(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.RemoveSection.Action): State {

  const removedSection = state.sections.find(f => f.identifier === action.identifier);
  const removedItemsCount = state.items.filter(f => f.section.identifier === action.identifier).length;

  return state.items.reduce<State>((acc, item) => {
    if (item.section.identifier === action.identifier) return acc;

    const section = state.sections.find(f => f.identifier === item.section.identifier);

    const ordinal = section.ordinal < removedSection.ordinal
      ? item.ordinal
      : item.ordinal - removedItemsCount;

    return acc.concat({
      ...item,
      ordinal,
    });
  }, []);
}

export function questionIdentifierUpdated(state: SurveyBuilder.State['survey'], action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): State {

  return state.items.reduce((acc, x) => {
    if (x.source.identifier === action.oldIdentifier) {
      return acc.concat({
        ...x,
        source: {
          identifier: action.newIdentifier,
        },
      });
    }

    return acc.concat(x);

  }, []);
}

export function itemMoved(state: SurveyBuilder.State['survey'], action: SurveyItemsBuilder.ItemMoved.Action): State {

  const item = state.items.find(f => f.identifier === action.payload.identifier);
  const refItem = state.items.find(f => f.identifier === action.payload.to.ref);

  const updatedItem = {
    ...item,
    section: { identifier: refItem.section.identifier },
  };

  if (action.payload.to.position === 'after') {
    const preceding = state.items.filter(f => f.identifier !== action.payload.identifier && f.ordinal <= refItem.ordinal);
    const proceeding = state.items.filter(f => f.identifier !== action.payload.identifier && f.ordinal > refItem.ordinal);

    return [
      ...preceding,
      updatedItem,
      ...proceeding,
    ].filter(Boolean)
      .map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
  } else if (action.payload.to.position === 'before') {
    const preceding = state.items.filter(f => f.identifier !== action.payload.identifier && f.ordinal < refItem.ordinal);
    const proceeding = state.items.filter(f => f.identifier !== action.payload.identifier && f.ordinal >= refItem.ordinal);

    return [
      ...preceding,
      updatedItem,
      ...proceeding,
    ].filter(Boolean)
      .map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
  }
}

export function sectionBreakAdded(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.SectionBreakAdded.Action): State {

  return state.items.reduce<State>((acc, item) => {
    const isInNewSection = action.payload.items.some(s => s.identifier === item.identifier);

    return isInNewSection
      ? acc.concat({ ...item, section: { identifier: action.payload.newIdentifier } })
      : acc.concat(item);
  }, []);
}