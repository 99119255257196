import { useCallback, useContext, useMemo } from 'react';
import { InfoTooltip } from '@presentation/InfoTooltip';
import {
  ExternalSurveyConfigurationContext,
  ExternalSurveyConfigurationBootstrapContext,
  ExternalSurveyConfigurationMutationsContext,
  ExternalSurveyConfigurationContainer,
} from '@containers/Project.ExternalSurvey';
import { useToggle } from '@utils';
import { AdminExternalSurveyConfigurationContainer } from '$admin/containers/Project.ExternalSurvey';
import { RedirectLinks } from '$admin/components/ExternalSurvey';
import { Switch } from '@/components/Switch';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useAlert } from '@/components/Modal/Alert';
import { ExternalLinkUpload } from '@/components/ExternalLinkUpload';
import { useProjectSurveyStepperContext } from './context';
import { BackButton, Footer, NextButton, SkipStep } from './Footer';
import styles from './style/ExternalSurvey.css';

const ExternalSurveyWithState = () => {

  const { project: { id: projectId } } = useProjectSurveyStepperContext();

  const isAdminPage = window.location.pathname.startsWith('/admin');

  if (isAdminPage) {
    return (
      <AdminExternalSurveyConfigurationContainer projectId={projectId}>
        <ExternalSurvey />
      </AdminExternalSurveyConfigurationContainer>
    );
  }

  return (
    <ExternalSurveyConfigurationContainer projectId={projectId}>
      <ExternalSurvey />
    </ExternalSurveyConfigurationContainer>
  );
};

export { ExternalSurveyWithState as ExternalSurvey };

const ExternalSurvey = () => {
  const { back, project: { id: projectId }, onComplete } = useProjectSurveyStepperContext();

  const [toggleAlert, Alert] = useAlert();

  const { isLoading, data: externalSurveyConfiguration, refetch } = useContext(ExternalSurveyConfigurationBootstrapContext);

  const [fixed, toggleFixed] = useToggle(externalSurveyConfiguration?.fixedLinks ?? false);

  const {
    deleteProjectConfigMutation: { mutateAsync: deleteExternalConfig },
    updateProjectConfigMutation,
  } = useContext(ExternalSurveyConfigurationMutationsContext);

  const onBack = useCallback(() => {
    deleteExternalConfig(null, {
      onSuccess: back,
    });
  }, [back, deleteExternalConfig]);

  const handleComplete = useCallback(() => {
    onComplete(true);
  }, [onComplete]);

  const handleToggleFixedLinks = useCallback(() => {
    toggleFixed();
    updateProjectConfigMutation.mutateAsync({
      fixedLinks: !fixed,
    })
    .then(() => refetch());
  }, [
    fixed,
    refetch,
    toggleFixed,
    updateProjectConfigMutation,
  ]);

  if (isLoading) {
    return (
      <ActivityIndicator />
    );
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.title}>
          External Survey Links
        </div>
        <div className={styles.subtitle}>
          Upload links to an external survey platform that respondents will be directed to upon accepting the project.
        </div>
        <Section title="Fixed Survey Link Assignments">
          <Switch
            active={fixed}
            disabled={updateProjectConfigMutation.isLoading || isLoading}
            onClick={handleToggleFixedLinks} />
        </Section>
        <Section title={'External Survey Links'}>
          <ExternalLinkUpload
            fixedLinks={externalSurveyConfiguration?.fixedLinks}
            onSuccess={() => refetch()} />
        </Section>
        <Section title='Redirect Links'>
          <RedirectLinks projectId={projectId} externalSurveyConfiguration={externalSurveyConfiguration} />
        </Section>
      </div>
      <Footer>
        <BackButton onClick={externalSurveyConfiguration?.numLinksAvailable ? toggleAlert : onBack} />
        <div>
          <NextButton
            title="Get Started"
            onClick={handleComplete} />
          <SkipStep text='Skip for now' onClick={handleComplete} />
        </div>
      </Footer>
      <Alert onConfirm={onBack} message={'Going back will remove all links associated with this project.'} />
    </>
  );
};

type SectionProps = ChildrenProps & {
  title: string;
  helpText?: string;
};

const Section = ({ title, helpText, children }: SectionProps) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {title}
        {helpText ? <InfoTooltip className={styles.tooltip} text={helpText} /> : null}
      </div>
      {children}
    </div>
  );
};

export const ExternalSurveyScreens = [
  ExternalSurveyWithState,
];