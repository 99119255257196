import { useQuery } from '@tanstack/react-query';
import { fetchLanguages } from '@api/search';
import { SelectSearchUnderlined } from '$admin/components/Select/Select.Search.Underlined';

type Props = {
  defaultValues: number[];
  onChange: (vals: Descriptor[]) => void;
};

export const SelectLanguage = ({ defaultValues, onChange }: Props) => {
  const query = useQuery(['fetch-languages'], async () => {
    const res = await fetchLanguages();
    const primaryLanguages = [
      `en-US`,
      `es-ES`,
      `es-US`,
      `pt-PT`,
      `pt-BR`,
    ];

    return [
      ...res.items.filter(f => primaryLanguages.includes(f.fullCode)),
      ...res.items.filter(f => !primaryLanguages.includes(f.fullCode)),
    ];
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (query.isLoading) {
    return (
      <SelectSearchUnderlined
        key={'disabled-language-select'}
        isLoading={true}
        options={[]}
        isMulti={false}
        isDisabled={true} />
    );
  }

  return (
    <SelectSearchUnderlined
      key={'enabled-language-select'}
      isLoading={query.isLoading}
      options={query.data}
      defaultValue={query.data.filter(o => defaultValues.includes(o.id))}
      onChange={(d: Descriptor) => onChange([d])}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id.toString()}
      isMulti={false} />
  );
};