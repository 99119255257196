import { useCallback, useMemo, useReducer } from 'react';
import {
  SurveyBuilderVersion,
} from '@/types';
import { SurveyItemType } from '@/enums';
import { SurveyBuilder } from './interfaces';
import { SurveyBuilderStateContext, QuestionOrdinalsMap } from './Context';
import { template } from './template';
import { surveyStateReducer } from './state';

type Props = {
  initialValue?: SurveyBuilderVersion;
} & ChildrenProps;

export const InitialValue = template.custom.initialSurveyData;

function resolveInitialData(initialValue: SurveyBuilderVersion): SurveyBuilder.State {
  return {
    committed: {
      alternateImageExercises: initialValue?.alternateImageExercises || [],
      classifications: initialValue?.classifications || [],
      items: initialValue?.items || [],
      logic: initialValue?.logic || [],
      messages: initialValue?.messages || [],
      questions: initialValue?.questions || [],
      quotas: initialValue?.quotas || [],
      tagging: initialValue?.tagging || [],
    },
    editing: {
      itemIdentifier: null,
      logic: {
        identifier: null,
        isNew: false,
      },
      quota: {
        identifier: null,
        isNew: false,
      },
      tagging: {
        identifier: null,
        isNew: false,
      },
    },
    savedSurvey: initialValue,
    survey: initialValue,
    draft: {
      surveyVersionId: null,
      status: 'idle',
    },
  };
}

export const SurveyBuilderState = ({
  children,
  initialValue = InitialValue,
}: Props) => {
  const [state, dispatch] = useReducer(surveyStateReducer, resolveInitialData(initialValue));

  const clearState = useCallback(() => {
    dispatch({
      type: 'replace-state',
      state: resolveInitialData(initialValue),
    });
  }, [dispatch, initialValue]);

  const ordinalsMap = useMemo(() => {
    return state.survey.items.filter(f => f.type === SurveyItemType.Question)
      .reduce((acc, x, i) => {

        return {
          ...acc,
          [x.source.identifier]: i + 1,
        };

      }, {} as QuestionOrdinalsMap);
  }, [state.survey.items]);

  const questions = useMemo(() => {
    return state.survey.questions.map(m => ({
      ...m,
      ordinal: ordinalsMap[m.base.identifier],
    }));
  }, [ordinalsMap, state.survey.questions]);

  const stateWithOrdinals = {
    ...state,
    survey: {
      ...state.survey,
      questions,
    },
  };

  return (
    <SurveyBuilderStateContext.Provider value={[stateWithOrdinals, dispatch, clearState]}>
      {children}
    </SurveyBuilderStateContext.Provider>
  );
};

export default SurveyBuilderState;
