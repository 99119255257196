import { useCallback } from 'react';
import { useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { ConjointAnalysisQuestionBuilderContainer } from '@containers/SurveyBuilder.Question/ConjointAnalysisContainer';
import { MaxDiffQuestionBuilderContainer } from '@containers/SurveyBuilder.Question/MaxDiffContainer';
import {
  useQuestionBuilderItemContext,
  useCancelQuestionEditing,
  useSaveQuestionContext,
} from '@containers/SurveyBuilder.Question/Context';
import { SurveyQuestionType } from '@enums/Survey';
import { FormButtons } from '@presentation/FormButtons';
import { SurveyQuestionNumber } from '@presentation/Survey';
import { SurveyRichTextEditor } from '@/components/Survey.RichText';
import QuestionTypeDropdown from './QuestionTypeDropdown';
import ConjointAnalysisQuestionBuilder from './ConjointAnalysis.Question';
import MaxDiffQuestionBuilder from './MaxDiff.Question';
import MatrixGridQuestionBuilder from './MatrixGrid.Question';
import MatrixMultiselectBuilder from './MatrixMultiselect.Question';
import MatrixSliderQuestionBuilder from './MatrixSlider.Question';
import MultipleChoiceQuestionBuilder from './MultipleChoice.Question';
import MultiselectQuestionBuilder from './Multiselect.Question';
import LongTextQuestionBuilder from './LongText.Question';
import RankingQuestionBuilder from './Ranking.Question';
import NumberInputTableBuilder from './NumberInputTable.Question';
import MultiTextboxQuestionBuilder from './MultiTextbox.Question';
import { DropdownQuestionBuilder } from './Dropdown.Question';
import { ExclusiveOptionsQuestionBuilder } from './ExclusiveOptions.Question';
import { ShortTextQuestionBuilder } from './ShortText.Question';
import { MatrixRangeQuestionBuilder } from './MatrixRange.Question';
import { ImageMarkupQuestionBuilder } from './ImageMarkupQuestionBuilder';
import { Toolbar } from './Toolbar';
import { CaptureSheetFields } from './CaptureSheet.Fields';
import styles from './style/QuestionBuilder.css';

export const QuestionBuilder = () => {

  const { questionTypes } = useSurveyBuilderOptions();
  const item = useQuestionBuilderItemContext();

  const renderQuestionType = useCallback(() => {
    return (
      <div className={styles.question}>
        <SurveyQuestionNumber
          className={styles.number}
          ordinal={item.ordinal} />
        <div className={styles.questionType}>
          <div className={styles.dropdown}>
            <QuestionTypeDropdown
              items={questionTypes} />
          </div>
        </div>
      </div>
    );
  }, [
    item.ordinal,
    questionTypes,
  ]);

  const renderQuestionContent = useCallback(() => {
    switch (item.typeId) {
      case SurveyQuestionType.MultipleChoice:
        return <MultipleChoiceQuestionBuilder item={item} />;

      case SurveyQuestionType.Multiselect:
        return <MultiselectQuestionBuilder item={item} />;

      case SurveyQuestionType.Ranking:
        return <RankingQuestionBuilder item={item} />;

      case SurveyQuestionType.Sliders:
        return <MatrixSliderQuestionBuilder item={item} />;

      case SurveyQuestionType.MatrixGrid:
        return <MatrixGridQuestionBuilder item={item} />;

      case SurveyQuestionType.LongTextResponse:
        return <LongTextQuestionBuilder item={item} />;

      case SurveyQuestionType.ShortTextResponse:
        return <ShortTextQuestionBuilder item={item} />;

      case SurveyQuestionType.MaxDifference:
        return (
          <MaxDiffQuestionBuilderContainer item={item}>
            <MaxDiffQuestionBuilder />
          </MaxDiffQuestionBuilderContainer>
        );

      case SurveyQuestionType.MatrixMultiselect:
        return <MatrixMultiselectBuilder item={item} />;

      case SurveyQuestionType.ConjointAnalysis:
        return (
          <ConjointAnalysisQuestionBuilderContainer item={item}>
            <ConjointAnalysisQuestionBuilder />
          </ConjointAnalysisQuestionBuilderContainer>
        );

      case SurveyQuestionType.NumberInputTable:
        return <NumberInputTableBuilder item={item} />;

      case SurveyQuestionType.MultiTextbox:
        return <MultiTextboxQuestionBuilder item={item} />;

      case SurveyQuestionType.ExclusiveOptions:
        return <ExclusiveOptionsQuestionBuilder item={item} />;

      case SurveyQuestionType.Dropdown:
        return <DropdownQuestionBuilder item={item} />;

      case SurveyQuestionType.MatrixRange:
        return <MatrixRangeQuestionBuilder item={item} />;

      case SurveyQuestionType.ImageMarkup:
        return <ImageMarkupQuestionBuilder item={item} />;

      default: {
        const error = new UnreachableCaseError(item);
        return null;
      }
    }
  }, [
    item,
  ]);

  const renderQuestion = useCallback(() => {
    return (
      <>
        <div className={styles.topBar}>
          <Toolbar className={styles.toolbar} />
          <CaptureSheetFields />
        </div>
        <div className={styles.editor}>
          <SurveyRichTextEditor className={styles.questionText} />
        </div>
        {renderQuestionContent()}
      </>
    );
  }, [
    renderQuestionContent,
  ]);

  return (
    <div className={styles.root}>
      {renderQuestionType()}
      <div className={styles.body}>
        {item.typeId && renderQuestion()}
        <QuestionBuilderFormButtons />
      </div>
    </div>
  );
};

const QuestionBuilderFormButtons = () => {

  const {
    canSave,
    validationErrors,
    onSave,
  } = useSaveQuestionContext();
  const onCancel = useCancelQuestionEditing();

  return (
    <div className={styles.buttons}>
      <FormButtons
        archivable={false}
        disabled={!canSave}
        disabledTooltip={validationErrors?.[0]}
        deletable={false}
        onSubmit={onSave}
        onCancel={onCancel} />
    </div>
  );
};

export default QuestionBuilder;