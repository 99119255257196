import { compareAsc, format } from 'date-fns';
import { useCallback } from 'react';
import type { IConference } from '@/containers/Conference';
import { useConferenceInstance } from '@/containers/Conference';
import { ConferenceLogType, type ConferenceLogRecord } from '@/types';
import { Tooltip } from '@/presentation/Tooltip';
import styles from './style/Bar.Right.Logs.css';

export const Logs = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  return (
    <div className={styles.root}>
      <div className={styles.header}>Logs</div>
      <div className={styles.logs}>
        {instance.logs.sort((a, b) => compareAsc(a.logTime, b.logTime)).map(r => (<LogItem key={r.id} record={r} />))}
      </div>
    </div>
  );
};

type LogItemProps<T extends ConferenceLogType> = {
  record: ConferenceLogRecord<T>;
};

const LogItem = <T extends ConferenceLogType>({ record }: LogItemProps<T>) => {

  return (
    <div className={styles.item}>
      <div className={styles.timestamp}>
        <Tooltip title={format(new Date(record.logTime), 'PPpp')} placement='top'>
          <div>{format(new Date(record.logTime), 'pp')}</div>
        </Tooltip>
      </div>
      <div>
        <LogItemContent record={record} />
      </div>
    </div>
  );
};

const LogItemContent = <T extends ConferenceLogType>({ record }: LogItemProps<T>) => {
  const getPidName = usePidNameMapper();

  switch (record.typeId) {
    case ConferenceLogType.ParticipantJoined:
      return <>{getPidName(record.loggedBy)} has joined the conference.</>;
    case ConferenceLogType.ParticipantLeft:
      assertRecordType<ConferenceLogType.ParticipantLeft>(record);
      return <>{getPidName(record.loggedBy)} has left the conference. Reason: {record.context.reason}</>;
    case ConferenceLogType.Error:
      assertRecordType<ConferenceLogType.Error>(record);
      return <>{getPidName(record.loggedBy)} error: {record.context.message}</>;
    case ConferenceLogType.Info:
      assertRecordType<ConferenceLogType.Info>(record);
      return <>{getPidName(record.loggedBy)} info: {record.context.message}</>;

    default: {
      const type: never = record.typeId; //Just for case exhaustion
      return <Tooltip title={JSON.stringify(record)}><div>Unknown Log Type</div></Tooltip>;
    }
  }
};

const usePidNameMapper = () => {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();

  return useCallback((pid: string) => {
    return instance.participants.find(p => p.id === pid)?.name ?? 'Unknown';
  }, [instance.participants]);
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function assertRecordType<T extends ConferenceLogType>(record: ConferenceLogRecord): asserts record is ConferenceLogRecord<T> {

}