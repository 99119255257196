import { useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { getSelectionSettingOptions, assertValidSelectionSettings } from '@containers/SurveyBuilder/utils/question.multiselect';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { MultiselectQuestion } from '@/types';
import { arr } from '@utils';
import { SelectionSettings } from './Multiselect.Settings.Selections';
import { ResponsePiping } from './ResponsePiping';
import styles from './style/Multiselect.Question.css';
import QuestionOptionsBuilder from './OptionsBuilder';
import { ReuseQuestionOptions } from './ReuseQuestionValues';
import { FindAndReplaceOptions } from './FindAndReplace';
import { MultiselectOptionContextMenu } from './Multiselect.Option.ContextMenu';
import {
  RationaleCheckbox,
  RandomizeCheckbox,
  OrderOptionsBasedOnSource,
  GridDisplay,
} from './Settings';
import { useShowOptionOrdering, useHasLinkedOptions } from './hooks';

type Props = {
  item: MultiselectQuestion.Question;
};

export const MultiselectQuestionBuilder = ({ item }: Props) => {

  const selectItems = useMemo(() => {

    if (!item.options.length) return { [0]: '' };

    const [min, max] = getSelectionSettingOptions(item);

    return arr.range(max - min + 1, min).reduce<{ [k: string]: string }>((acc, x) => ({
      ...acc,
      [x]: x.toString(),
    }), {
      [0]: '',
    });
  }, [
    item,
  ]);

  const settingsValidation = useMemo(() => {
    return assertValidSelectionSettings(item);
  }, [item]);

  const hasLinkedOptions = useHasLinkedOptions(item);
  const showOptionOrdering = useShowOptionOrdering(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping disabled={hasLinkedOptions} />
        <ReuseQuestionOptions />
        {canFar ? <FindAndReplaceOptions question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.multiselect.MaxOptions}
        minOptions={surveyBuilder.multipleChoice.MinOptions}>
        <QuestionOptionsBuilder
          renderAction={option => (
            <MultiselectOptionContextMenu
              question={item}
              option={option as MultiselectQuestion.Option} />
          )} />
      </QuestionOptionsBuilderContainer>

      <RandomizeCheckbox
        disabled={item.settings.orderOptionsBasedOnSource}
        className={styles.checkbox} />

      {showOptionOrdering &&
        <OrderOptionsBasedOnSource
          disabled={item.settings.randomization}
          className={styles.checkbox} />
      }
      <GridDisplay className={styles.checkbox} />

      <RationaleCheckbox className={styles.checkbox} />

      <SelectionSettings
        className={styles.selections}
        item={item}
        selectItems={selectItems}
        validation={settingsValidation} />
    </>
  );
};

export default MultiselectQuestionBuilder;