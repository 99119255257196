import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useTranscriptToolsEnabled } from '@containers/Transcript/hooks';
import { useToggle } from '@utils/hooks';
import { useGetCommentsForHighlight } from '@/components/Transcript/hooks';
import { Alert } from '@/components/Modal/Alert';
import { FloatingWrapper } from '@/components/Remirror';
import { HighlightTooltip } from './Tooltip.Highlight';
import { Menu } from './Tooltip.Highlight.Menu';
import { StyledPopper, classes } from './StyledPopper';
import { MenuColorDisplayContext } from './context';
import {
  useBlurHighlight,
  useHighlightTooltipPositioner,
  useHelpers,
  useRemoveHighlight as useRemoveHighlightMutation,
  useTooltipHighlightConfig,
} from './hooks';

export const HighlightTooltipContainer = () => {
  const [showColors, setShowColors] = useState(false);
  const position = useHighlightTooltipPositioner();
  const blurHighlight = useBlurHighlight();

  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const containerRef = useRef<HTMLDivElement>();

  const {
    handleInitialRemoveHighlightClick,
    handleRemoveHighlight,
    showRemoveHighlightAlert,
    toggleRemoveHighlightAlert,
  } = useRemoveHighlight();

  const config = useTooltipHighlightConfig();
  const hidden = !useTranscriptToolsEnabled();

  useEffect(() => {
    //Reset the color tooltip whenever we highlight something else
    setShowColors(false);
  }, [position.data]);

  const modifiers = useMemo(() => ([
    {
      name: 'arrow',
      options: {
        element: arrowRef,
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 15],
      },
    },
  ]), [arrowRef]);

  if (hidden) return null;

  return (
    <>
      <ClickAwayListener
        mouseEvent={showRemoveHighlightAlert ? false : 'onMouseDown'}
        touchEvent={showRemoveHighlightAlert ? false : 'onTouchStart'}
        onClickAway={blurHighlight}>
        <div>
          <MenuColorDisplayContext.Provider value={[showColors, setShowColors]}>
            <FloatingWrapper
              containerRef={containerRef}
              position={position}>
              <StyledPopper
                id="highlight-popper"
                className={classes.popper}
                container={containerRef.current}
                placement="top"
                open={position.visible && showColors}
                anchorEl={position.element}
                style={{
                  zIndex: 2,
                }}
                modifiers={modifiers}>
                <HighlightTooltip
                  arrow={{
                    className: classes.arrow,
                    ref: setArrowRef,
                  }}
                  config={config} />
              </StyledPopper>
            </FloatingWrapper>
            <Menu onRemove={handleInitialRemoveHighlightClick} />
          </MenuColorDisplayContext.Provider>
        </div>
      </ClickAwayListener>
      <Alert
        open={showRemoveHighlightAlert}
        message="This highlight has comments attached to it, are you sure you want to remove it?"
        onClose={toggleRemoveHighlightAlert}
        onConfirm={handleRemoveHighlight} />
    </>
  );
};

const useRemoveHighlight = () => {

  const [showRemoveHighlightAlert, toggleRemoveHighlightAlert] = useToggle();

  const { getFocusedHighlight } = useHelpers();
  const getComments = useGetCommentsForHighlight();

  const {
    mutateAsync: removeHighlight,
  } = useRemoveHighlightMutation({
    onSuccess: () => {
      if (showRemoveHighlightAlert) {
        toggleRemoveHighlightAlert();
      }
    },
  });

  const handleRemoveHighlight = useCallback(() => {
    const highlight = getFocusedHighlight();
    removeHighlight({ highlight });
  }, [
    getFocusedHighlight,
    removeHighlight,
  ]);

  const handleInitialRemoveHighlightClick = useCallback(() => {
    const highlight = getFocusedHighlight();
    const comments = getComments(highlight.id);

    if (comments.length) {
      toggleRemoveHighlightAlert();
    } else {
      handleRemoveHighlight();
    }
  }, [
    getComments,
    getFocusedHighlight,
    handleRemoveHighlight,
    toggleRemoveHighlightAlert,
  ]);

  return {
    handleInitialRemoveHighlightClick,
    handleRemoveHighlight,
    showRemoveHighlightAlert,
    toggleRemoveHighlightAlert,
  } as const;

};