import { Fragment, memo } from 'react';
import { FormButtons } from '@presentation';
import Edit from '@/components/icons/Edit';
import styles from './style.css';

type Props = {
  title:      string;
  editing:    boolean;
  canEdit?:   boolean;
  onEdit:     () => void;
  onReset:    () => void;
  onSave:     () => void;
  canSave?:   boolean;
  onCreate?:  () => void;
  canCreate?: boolean;
};

export const SectionHeader = memo(({ title, ...props }: Props) => {
  return (
    <Fragment>
      <div className={styles.sectionTitleWrap}>
        <div className={styles.sectionTitle}>{title}</div>
        <Buttons {...props} />
      </div>
    </Fragment>
  );
});

type ButtonProps = Pick<Props, 'editing' | 'onEdit' | 'onReset' | 'onSave' | 'canEdit' | 'canSave' | 'canCreate' | 'onCreate'>;

const Buttons = memo(({ editing, onEdit, onReset, onSave, onCreate, canCreate, canEdit = true, canSave = true }: ButtonProps) => {
  if (!canEdit) return null;

  if (!editing) {
    return (
      <Edit
        onClick={onEdit}
        size={14} />
    );
  }

  return (
    <FormButtons
      archivable={false}
      disabled={!canSave}
      deletable={false}
      creatable={canCreate}
      onCancel={onReset}
      onSubmit={onSave}
      onCreate={onCreate}
      size={14} />
  );
});