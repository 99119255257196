import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyQuestionConditionType,
  SurveyConditionType,
} from '@enums/Survey';
import {
  PricingStrategyQuestion,
  PricingStrategySection,
  SurveyTemplateEntityType,
  SurveyTemplateType,
} from '@enums/survey.template';
import { SurveyTemplate } from '@/types/survey.templates';
import {
  MultipleChoiceQuestion,
  SurveyBuilderVersion,
  SurveyQuestion,
} from '@/types/survey';
import * as $question from '../utils/question';
import * as $shared from './template.shared-questions';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.PricingStrategy = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.PricingStrategy = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5, 'Competitor'),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.PricingStrategy): SurveyBuilderVersion {

  const section = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: PricingStrategySection.Main },
    },
    hidden: false,
    name: 'Main Section',
    ordinal: 1,
  });

  const baseSection = {
    identifier: section.identifier,
  };

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: baseSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: baseSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleDQQuestion({ section: baseSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: baseSection });
  }

  const {
    logic: recentCustomerLogic,
    question: recentCustomerQuestion,
  } = (function RecentCustomerQuestion() {
    const questionIdentifier = cuid();

    const yesOptions = [
      'Yes, Current',
      'Yes, in the past 12 months',
    ];

    const noOption = $template.generateMultipleChoiceOption({
      ordinal: 3,
      value: 'No',
    });

    const dqIfNoLogic = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noOption.base.identifier },
    });

    const logic = [dqIfNoLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PricingStrategyQuestion.RecentCustomer,
        },
      },
      options: [
        ...yesOptions.map((value, i) => $template.generateMultipleChoiceOption({
          ordinal: i + 1,
          value,
        })),
        noOption,
      ],
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Are you a current or recent customer of a `),
        categoryNode(),
        $template.generateTextNode(` solution?`),
      ]),
    });

    return { logic, question };
  })();

  const {
    logic: evaluationInvolvementLogic,
    question: evaluationInvolvementQuestion,
  } = (function EvaluationInvolvementQuestion() {
    const questionIdentifier = cuid();

    const options = [
      'I am/was the primary decision maker',
      'I am/was part of a team of decision makers',
      'I am/was an influencer on the decision',
    ];

    const notInvolvedOption = $template.generateMultipleChoiceOption({
      ordinal: 4,
      value: 'I am/was not involved in the decision',
    });

    const dqIfNotInvolved = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: notInvolvedOption.base.identifier },
    });

    const logic = [dqIfNotInvolved];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PricingStrategyQuestion.EvaluationInvolvement,
        },
      },
      options: [
        ...options.map((value, i) => $template.generateMultipleChoiceOption({
          ordinal: i + 1,
          value,
        })),
        notInvolvedOption,
      ],
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`How would you best describe your involvement in the evaluation / selection process for your organization's `),
        categoryNode(),
        $template.generateTextNode(` solution?`),
      ]),
    });

    return { logic, question };
  })();

  function PerceivedValueQuestion() {

    return $template.generateSlidersQuestion({
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Value',
      })],
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.PerceivedValue },
      },
      section: baseSection,
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        randomization: false,
        slider: {
          displayPctOfTotal: false,
          ensureAnswerTotalEqualsMax: false,
          hideSlider: false,
          minValue: 1,
          maxValue: 10,
          increment: 1,
          label: null,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Please rate your perceived value of `),
        categoryNode(),
        $template.generateTextNode(` on a scale of 1-10. (1 = waste of budget, 10 = business critical)`),
      ]),
    });
  }

  function AwarenessQuestion() {

    const targetOption = $template.generateTargetMultiselectOption({
      ordinal: 1,
      target: data.target.value,
    });

    const competitorOptions = data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
      item,
      ordinal: i + 2,
    }));

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.Awareness },
      },
      options: [
        targetOption,
        ...competitorOptions,
      ],
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Which of the following `),
        categoryNode(),
        $template.generateTextNode(` providers are you aware of?`),
      ]),
    });
  }

  const awarenessQuestion = AwarenessQuestion();

  function PerceptionQuestion() {
    const awarenessTargetOption = awarenessQuestion.options.find(f => f.metadata.template.linkedEntity.type === SurveyTemplateEntityType.Target);
    const targetRow = $template.generateMatrixGridTargetRow({
      conditions: [{
        identifier: cuid(),
        conditionType: SurveyConditionType.Question,
        data: {
          question: {
            identifier: awarenessQuestion.base.identifier,
          },
          type: SurveyQuestionConditionType.OptionSelected,
          value: {
            option: {
              identifier: awarenessTargetOption.base.identifier,
            },
          },
        },
      }],
      ordinal: 1,
      target: data.target.value,
    });

    const competitorRows = data.competitors.map((item, i) => {
      const awarenessOption = awarenessQuestion.options
        .find(f => f.metadata.template.linkedEntity.type === SurveyTemplateEntityType.Competitor &&
          f.metadata.template.linkedEntity.id === item.id);
      return $template.generateMatrixGridCompetitorRow({
        conditions: [{
          conditionType: SurveyConditionType.Question,
          identifier: cuid(),
          data: {
            question: {
              identifier: awarenessQuestion.base.identifier,
            },
            type: SurveyQuestionConditionType.OptionNotSelected,
            value: {
              option: {
                identifier: awarenessOption.base.identifier,
              },
            },
          },
        }],
        item,
        ordinal: i + 2,
      });
    });

    const options = [
      'Low Cost',
      'Good Value',
      'Justified Premium',
      'Expensive',
      'Not Sure',
    ];

    return $template.generateMatrixGridQuestion({
      matrixRows: [
        targetRow,
        ...competitorRows,
      ],
      options: options.map((value, i) => $template.generateOption({
        metadata: {
          canModifyValue: true,
          canDelete: true,
          template: {},
        },
        ordinal: i + 1,
        value,
      })),
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.GeneralPerception },
      },
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`What is your general perception of the brands you are aware of?`),
      ]),
    });
  }

  function PoorQualityQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.PoorQuality },
      },
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`At what price would you begin to think the `),
        categoryNode(),
        $template.generateTextNode(` solution is so inexpensive that you would not buy it because it would be poor quality?`),
      ]),
    });
  }

  function GoodDealQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.GoodDeal },
      },
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`At what price would you think the product is a deal that is too good to pass up (in USD$)?`),
      ]),
    });
  }

  function ApproachingExpensiveQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.ApproachingExpensive },
      },
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`At what price would you think the product is approaching expensive, but still worth considering (in USD $)?`),
      ]),
    });
  }

  function BeyondConsiderationQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.BeyondConsideraton },
      },
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`At what price would you begin to think the product is expensive beyond consideration (in USD$)?`),
      ]),
    });
  }

  function OtherInfluenceQuestion() {

    const optionTexts = [
      'Customer Experience',
      'Sales / Service Rep.',
      'Advertising',
      'Guarantee / Warranty Offers',
      'Packaging',
      'Industry Trends',
      'Other',
    ];

    const options = optionTexts.map((value, i) => $template.generateMultiselectOption({
      ordinal: i + 1,
      value,
    }));

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        // canModifyLogic: true,
        canModifyValue: true,
        canDelete: true,
        template: { key: PricingStrategyQuestion.OtherInfluence },
      },
      options,
      section: baseSection,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Aside from the product itself, which of the following would MOST influence your evaluation of the price point?`),
      ]),
    });
  }

  const questions: SurveyQuestion[] = [
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    recentCustomerQuestion,
    evaluationInvolvementQuestion,
    PerceivedValueQuestion(),
    awarenessQuestion,
    PerceptionQuestion(),
    PoorQualityQuestion(),
    GoodDealQuestion(),
    ApproachingExpensiveQuestion(),
    BeyondConsiderationQuestion(),
    OtherInfluenceQuestion(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    logic: [
      ...recentCustomerLogic,
      ...evaluationInvolvementLogic,
    ],
    messages: [],
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [section],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.PricingStrategy,
    },
  };
}