import { useCallback, useContext, useState } from 'react';
import {
  ProjectHonorariaRefetchContext,
  ProjectHonorariaEditingContext,
  ProjectHonorariaContext,
  ProjectHonorariaMutationContext,
} from '@/containers/Project.Honoraria';
import { ProjectHonoraria } from '$admin/components/Project.Honoraria';
import * as PH from '$admin/components/Project.Honoraria/interfaces';
import * as $honoraria from '$admin/components/Project.Honoraria/utils';
import { SectionHeader } from './SectionHeader';
import styles from './style.css';

type Props = unknown;

export const Honoraria = (props: Props) => {
  const items = useContext(ProjectHonorariaContext);
  const refetch = useContext(ProjectHonorariaRefetchContext);

  const [values, setValues] = useState<PH.Form.State['honoraria']>(items);
  const [valid, setValid] = useState(false);
  const {
    editable,
    editing,
    toggle: toggleEditing,
  } = useContext(ProjectHonorariaEditingContext);

  const handleChangeValues: PH.Form.OnChangeValues = useCallback((values, valid) => {
    setValues(values.honoraria);
    setValid(valid);
  }, [
    setValid,
    setValues,
  ]);

  const handleReset = useCallback(() => {
    toggleEditing();
    setValid(false);
    setValues(items);
  }, [
    items,
    setValid,
    setValues,
    toggleEditing,
  ]);

  const mutation = useContext(ProjectHonorariaMutationContext);

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync({
      items: $honoraria.transformSubmitData({
        honoraria: values,
      }),
    }, {
      onSuccess: () => {
        refetch().then(handleReset);
      },
    });
  }, [
    handleReset,
    mutation,
    refetch,
    values,
  ]);

  return (
    <>
      <ProjectHonoraria
        className={styles.wrap}
        items={items}
        onChangeValues={handleChangeValues}
        readonly={!editing}>
        <SectionHeader
          canEdit={editable}
          canSave={valid}
          editing={editing}
          onEdit={toggleEditing}
          onReset={handleReset}
          onSave={handleSubmit}
          title="Honoraria" />
      </ProjectHonoraria>
    </>
  );
};

Honoraria.displayName = 'Project.Details.Section.Honoraria';