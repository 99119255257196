import cuid from 'cuid';
import {
  SurveyPipedValueType,
  SurveyQuestionConditionType,
  SurveyOptionType,
  SurveyActionType,
  SurveyConditionType,
} from '@enums/Survey';
import {
  PersonaResearchQuestion,
  PersonaResearchTemplateSection,
  SurveyTemplateType,
} from '@enums/survey.template';
import { SurveyTemplate } from '@/types/survey.templates';
import {
  MultipleChoiceQuestion,
  SurveyBuilderVersion,
  SurveyQuestion,
} from '@/types/survey';
import { parseSurveyRichText } from '@/containers/Survey/utils';
import * as $question from '../utils/question';
import * as $shared from './template.shared-questions';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.PersonaResearchTemplate = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  evaluationCriteria: $template.generateInitialTextItems(5),
  partnershipCriteria: $template.generateInitialTextItems(5),
  roles: $template.generateInitialTextItems(5),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.PersonaResearchTemplate = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5, 'Competitor'),
//   evaluationCriteria: $template.generateInitialTextItems(5, 'Evaluation Criteria'),
//   partnershipCriteria: $template.generateInitialTextItems(5, 'Partnership Criteria'),
//   roles: $template.generateInitialTextItems(5, 'Role'),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.PersonaResearchTemplate): SurveyBuilderVersion {

  const screeningSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: PersonaResearchTemplateSection.Screening },
    },
    hidden: false,
    name: 'Screening',
    ordinal: 1,
  });

  const budgetSpendSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: PersonaResearchTemplateSection.BudgetSpend },
    },
    hidden: false,
    name: 'Product Budget/Spend Questions',
    ordinal: 2,
  });

  const roleEvalSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: PersonaResearchTemplateSection.RoleEval },
    },
    hidden: false,
    name: 'Role & Eval Criteria Questions',
    ordinal: 3,
  });

  const decisionMakingSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: PersonaResearchTemplateSection.DecisionMaking },
    },
    hidden: false,
    name: 'Decision-Making Questions',
    ordinal: 4,
  });

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: screeningSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: screeningSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleQuestion({ section: screeningSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: screeningSection });
  }

  const {
    logic: orgCurrentCustomerLogic,
    question: orgCurrentCustomerQuestion,
  } = (function OrgCurrentCustomerQuestion() {
    const questionIdentifier = cuid();
    const yesOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: 'Yes',
    });
    const noOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 2,
      value: 'No',
    });

    const dqIfNoLogic = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noOption.base.identifier },
    });

    const logic = [dqIfNoLogic];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.OrgCurrentCustomer,
        },
      },
      options: [yesOption, noOption],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Is your organization a current customer of '),
        categoryNode(),
        $template.generateTextNode('?'),
      ]),
    });

    return { logic, question };
  })();

  const {
    logic: respondentInvolvementLogic,
    question: respondentInvolvementQuestion,
  } = (function RespondentInvolvementQuestion() {
    const questionIdentifier = cuid();

    const noInfluenceOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 4,
      value: 'The decision impacts me, but I have no influence',
    });

    const noInvolvementOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 5,
      value: 'No involvement',
    });

    const options = [
      'Final decision maker',
      'A part of a team making the final decision',
      'A key influencer on the decision',
    ];

    const dqIfNoInvolvement = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noInvolvementOption.base.identifier },
    });

    const dqIfNoInfluence = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noInfluenceOption.base.identifier },
    });

    const logic = [dqIfNoInvolvement, dqIfNoInfluence];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.RespondentInvolvement,
        },
      },
      options: [
        ...options.map((value, i) => $template.generateMultipleChoiceOption({
          ordinal: i + 1,
          value,
        })),
        noInfluenceOption,
        noInvolvementOption,
      ],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('What best describes your involvement in the decision-making process for '),
        categoryNode(),
        $template.generateTextNode(' solutions at your organization?'),
      ]),
    });

    return { logic, question };
  })();

  function DepartmentBudgetQuestion() {

    const options = [
      '$100k',
      '$100k - $300k',
      '$300k - $500k',
      '$500k - $1M',
      '$1M - $3M',
      '$3M - $5M',
      '$5M - $10M',
      '$10M+',
    ];

    return $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.DepartmentBudget,
        },
      },
      options: options.map((value, i) => $template.generateMultipleChoiceOption({
        ordinal: i + 1,
        value,
      })),
      section: {
        identifier: budgetSpendSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Approximately how much is your annual department budget?'),
      ]),
    });
  }

  function OrgProductsQuestion() {

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.OrganizationProducts,
        },
      },
      options: [
        $template.generateTargetMultiselectOption({
          ordinal: 1,
          target: data.target.value,
        }),
        ...data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
          item,
          ordinal: i + 2,
        })),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: data.competitors.length + 2,
          type: SurveyOptionType.Default,
          value: 'Other',
        }),
      ],
      section: {
        identifier: budgetSpendSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Which of the following '),
        categoryNode(),
        $template.generateTextNode(` solutions is your organization currently using?`),
      ]),
    });
  }

  function BusinessModelQuestion() {
    const options = [
      'B2B',
      'B2C',
      'B2B & B2C',
    ];

    return $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.OrganizationBusinessModel,
        },
      },
      options: [
        ...options.map((value, i) => $template.generateMultipleChoiceOption({
          ordinal: i + 1,
          value,
        })),
        $template.generateMultipleChoiceOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: options.length + 1,
          type: SurveyOptionType.Default,
          value: 'Other',
        }),
      ],
      section: {
        identifier: roleEvalSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Which of the following best describes your company's business model?`),
      ]),
    });
  }

  function TopChallengesQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.TopChallenges,
        },
      },
      section: {
        identifier: roleEvalSection.identifier,

      },
      settings: $question.shortText.DefaultSettings,
      value: $template.generateRichTextValue([
        $template.generateTextNode('Please briefly describe some of the top challenges that you face in your role today.'),
      ]),
    });
  }

  function TopPrioritiesQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.TopPriorities,
        },
      },
      section: {
        identifier: roleEvalSection.identifier,
      },
      settings: $question.shortText.DefaultSettings,
      value: $template.generateRichTextValue([
        $template.generateTextNode('Please briefly describe some of your top priorities in your role today.'),
      ]),
    });
  }

  function EvaluationCriteriaQuestion() {

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.EvaluationCriteria,
        },
      },
      options: [
        ...data.evaluationCriteria.map((item, i) => $template.generateEvaluationCriteriaOption({
          item,
          ordinal: i + 1,
        })),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: data.evaluationCriteria.length + 1,
          type: SurveyOptionType.Default,
          value: 'Other',
        }),
      ],
      section: {
        identifier: roleEvalSection.identifier,
      },
      settings: {
        ...$question.multiselect.DefaultSettings,
        maximumSelections: 3,
        minimumSelections: 3,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('What are the most important criteria for you when evaluating a new '),
        categoryNode(),
        $template.generateTextNode(` solution? (Please select the Top 3)`),
      ]),
    });
  }

  function EvaluationCriteriaReasonQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.OtherEvaluationCriteria,
        },
      },
      section: {
        identifier: roleEvalSection.identifier,
      },
      settings: $question.shortText.DefaultSettings,
      value: $template.generateRichTextValue([
        $template.generateTextNode('When evaluating a new '),
        categoryNode(),
        $template.generateTextNode(' solution, what other key criteria you are looking to understand?'),
      ]),
    });
  }

  function PartnershipCriteriaQuestion() {
    return $template.generateRankingQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.PartnershipCriteria,
        },
      },
      options: [
        ...data.partnershipCriteria.map((item, i) => $template.generatePartnershipCriteriaRankingOption({
          item,
          ordinal: i + 1,
        })),
        $template.generateRankingOption({
          metadata: {
            canModifyValue: false,
            canDelete: true,
            template: {},
          },
          ordinal: data.partnershipCriteria.length + 1,
          value: 'Other',
        }),
      ],
      section: {
        identifier: roleEvalSection.identifier,
      },
      settings: $question.ranking.DefaultSettings,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Considering what makes a successful solutions partnership, please rank these factors in terms of importance. (Most important to least important)`),
      ]),
    });
  }

  const partnershipCriteriaQuestion = PartnershipCriteriaQuestion();

  function PartnershipCriteriaReasonQuestion() {
    return $template.generateShortTextQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.PartnershipCriteriaReason,
        },
      },
      section: {
        identifier: roleEvalSection.identifier,
      },
      settings: $question.shortText.DefaultSettings,
      value: $template.generateRichTextValue([
        $template.generateTextNode(`You ranked `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.FirstRankedOption,
          },
          questionIdentifier: partnershipCriteriaQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the most important factor and `),
        $template.generatePipedQuestionResponseNode({
          pipedValue: {
            type: SurveyPipedValueType.LastRankedOption,
          },
          questionIdentifier: partnershipCriteriaQuestion.base.identifier,
        }),
        $template.generateTextNode(` as the least important factor for successful solutions partnerships. Please elaborate what drove your response.`),
      ]),
    });
  }

  function TargetRoleQuestion() {

    return $template.generateMultiselectQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.TargetRole,
        },
      },
      options: [
        ...data.roles.map((m, i) => $template.generateTargetRoleMultiselectOption({
          ordinal: i + 1,
          item: m,
        })),
        $template.generateMultiselectOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            isAnchored: true,
            isOpenEnded: true,
          },
          ordinal: data.roles.length + 1,
          type: SurveyOptionType.Default,
          value: 'Other',
        }),
      ],
      section: {
        identifier: decisionMakingSection.identifier,
      },
      settings: {
        ...$question.multiselect.DefaultSettings,
        minimumSelections: 1,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Which of the following roles are involved in the decision-making process for `),
        categoryNode(),
        $template.generateTextNode(` solutions at your organization? (Please select all that apply)`),
      ]),
    });
  }

  const targetRoleQuestion = TargetRoleQuestion();

  function FinalDecisionMakerQuestion() {

    const roleOptions: MultipleChoiceQuestion.Option[] = data.roles.map((item, i) => {
      const reliantOption = targetRoleQuestion.options.find(f => f.metadata.template.linkedEntity?.id === item.id);

      return $template.generateTargetRoleMulipleChoiceOption({
        conditions: [{
          conditionType: SurveyConditionType.Question,
          identifier: cuid(),
          data: {
            question: { identifier: targetRoleQuestion.base.identifier },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: { identifier: reliantOption.base.identifier },
            },
          },
        }],
        item,
        ordinal: i + 1,
      });
    });

    const otherOption = (() => {
      const reliantOption = targetRoleQuestion.options.find(f => parseSurveyRichText(f.value) === 'Other');

      return $template.generateMultipleChoiceOption({
        conditions: [{
          identifier: cuid(),
          conditionType: SurveyConditionType.Question,
          data: {
            question: { identifier: targetRoleQuestion.base.identifier },
            type: SurveyQuestionConditionType.OptionSelected,
            value: {
              option: { identifier: reliantOption.base.identifier },
            },
          },
        }],
        metadata: {
          canDelete: false,
          canModifyValue: false,
          isAnchored: true,
          isOpenEnded: true,
        },
        ordinal: roleOptions.length + 1,
        type: SurveyOptionType.Default,
        value: parseSurveyRichText(reliantOption.value),
      });
    })();

    return $template.generateMultipleChoiceQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.FinalDecisionMaker,
        },
      },
      options: [
        ...roleOptions,
        otherOption,
      ],
      section: {
        identifier: decisionMakingSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Who is the final decision-maker for `),
        categoryNode(),
        $template.generateTextNode(` solutions at your organization?`),
      ]),
    });
  }

  function DepartmentSolutionBudgetQuestion() {

    return $template.generateSlidersQuestion({
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: PersonaResearchQuestion.DepartmentBudget,
        },
      },
      matrixRows: [$template.generateSlidersRow({
        metadata: {
          canDelete: false,
          canModifyValue: false,
        },
        ordinal: 1,
        value: 'Pct.',
      })],
      section: {
        identifier: budgetSpendSection.identifier,
      },
      settings: {
        ...$question.matrixSlider.DefaultSettings,
        slider: {
          displayPctOfTotal: false,
          hideSlider: false,
          increment: 1,
          label: $template.generateRichTextValue([
            $template.generateTextNode('% of Department Budget'),
          ]),
          minValue: 0,
          maxValue: 100,
          ensureAnswerTotalEqualsMax: false,
          total: $question.matrixSlider.DefaultSettings.slider.total,
        },
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`Approximately what percentage of your department's budget is typically allocated to `),
        categoryNode(),
        $template.generateTextNode(` solutions?`),
      ]),
    });
  }

  const questions: SurveyQuestion[] = [
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    orgCurrentCustomerQuestion,
    respondentInvolvementQuestion,
    DepartmentBudgetQuestion(),
    DepartmentSolutionBudgetQuestion(),
    OrgProductsQuestion(),
    BusinessModelQuestion(),
    TopChallengesQuestion(),
    TopPrioritiesQuestion(),
    EvaluationCriteriaQuestion(),
    EvaluationCriteriaReasonQuestion(),
    partnershipCriteriaQuestion,
    PartnershipCriteriaReasonQuestion(),
    targetRoleQuestion,
    FinalDecisionMakerQuestion(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    logic: [
      ...orgCurrentCustomerLogic,
      ...respondentInvolvementLogic,
    ],
    messages: [],
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [
      screeningSection,
      budgetSpendSection,
      roleEvalSection,
      decisionMakingSection,
    ],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.PersonaResearch,
    },
  };
}