import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { addMonths } from 'date-fns';
import { Timeline, type DataGroup } from 'vis-timeline/esnext';
import editorStyles from 'vis-timeline/dist/vis-timeline-graph2d.css';
import * as Table from '@/components/Table';
import { BrandProjectTrackerContext } from '@/containers/Workspace.Brand/Context';
import { BrandProjectTrackerContainer } from '@/containers/Workspace.Brand/Brand.ProjectTracker.Container';
import { getLocationFor, useUseableCSS } from '@/utils';
import overrideStyles from 'static/css/useable/vis-timeline.css';
import styles from './style/Tab.ProjectTracker.css';

type GroupItem = DataGroup & { order: number };

const ViewComponent = () => {
  const state = useContext(BrandProjectTrackerContext);
  const [container, timeline] = useVisTimeline();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const tl = timeline.current;

    const groups: GroupItem[] = state.projects.map((project, i) => ({
      id: project.id,
      content: `${project.name}`,
      order: Math.max(...(state.tracker[project.id]?.segments?.map(s => s.end?.getTime() || (new Date().getTime() + project.id)) || [])),
    }));

    const items = groups.flatMap((group, gIdx) => {
      const tracker = state.tracker[+group.id];
      if (!tracker) return [];
      return tracker.segments.map((s, sIdx) => ({
        id: `${gIdx}/${sIdx}`,
        content: '',
        start: s.start || new Date(),
        end: s.end || new Date(),
        group: group.id,
      }));
    });

    tl.setData({
      groups,
      items,
    });

    const getMin = () => {
      const val = Math.min(...items.map(i => i.start?.getTime()));
      if (val) return addMonths(new Date(val), -1);
      return addMonths(new Date(), -1);
    };
    const getMax = () => {
      const val = Math.max(...items.map(i => i.end?.getTime()));
      if (val) return addMonths(new Date(val), 4);
      return addMonths(new Date(), 4);
    };

    tl.setOptions({
      min: getMin(),
      max: getMax(),
    });

    tl.redraw();

    setTimeout(() => {
      tl.fit({ animation: false });
      tl.moveTo(Date.now(), { animation: false });
    }, 0);

    setTimeout(() => {
      setVisible(true);
    }, 500);
  }, [
    timeline,
    state.projects,
    state.tracker,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.table}>
            <Table.Layout.Box className={styles.box}>
              <Table.Layout.Header
                classes={{ root: styles.header }}
                title="Project Tracker" />
              <div style={{ opacity: visible ? '100%' : '0', transition: 'all 500ms' }} ref={container} />
            </Table.Layout.Box>
          </div>
        </div>
      </div>
    </div>
  );
};

type GroupViewProps = {
  group: GroupItem;
};

const GroupView = ({ group }: GroupViewProps) => {
  const to = getLocationFor.project.rootWithId({
    id: +group.id,
  });

  return (
    <div className={styles.group}>
      <a className={styles.link} href={to.pathname}>
        {group.content as string}
      </a>
    </div>
  );
};

const useVisTimeline = () => {
  const container = useRef<HTMLDivElement>();
  const timeline = useRef<Timeline>();

  useUseableCSS(editorStyles, overrideStyles);

  const initializeTimeline = useCallback(() => {
    timeline.current = new Timeline(container.current, [], [],
      {
        groupOrder: (a: GroupItem, b: GroupItem) => b.order - a.order,
        orientation: {
          axis: 'top',
          item: 'top',
        },
        selectable: false,
        zoomMin: 1000 * 60 * 60 * 24 * 31 * 2,
        zoomMax: 1000 * 60 * 60 * 24 * 31 * 36,
        height: 'calc(100vh - var(--navbar-height) - 305px)',
        type: 'range',
        zoomFriction: 15,
        groupTemplate: (group: GroupItem, element: HTMLElement) => {
          if (!group) return;
          createRoot(element).render(<GroupView group={group} />);
          return null;
        },
      });
  }, []);

  useEffect(() => {
    if (container.current) {
      initializeTimeline();
    }

    return () => {
      if (timeline.current) {
        timeline.current.destroy();
      }
    };
  }, [
    initializeTimeline,
  ]);

  return [container, timeline] as const;
};

export const BrandTabProjectTracker = () => (
  <BrandProjectTrackerContainer>
    <ViewComponent />
  </BrandProjectTrackerContainer>
);

BrandTabProjectTracker.displayName = 'Workspace.Brand.Tab.ProjectTracker';