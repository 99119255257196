import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useReactTable, createColumnHelper, flexRender, getCoreRowModel,
} from '@tanstack/react-table';
import { Edit2, Trash } from 'react-feather';
import { useMutation } from '@tanstack/react-query';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import * as $api from '@api';
import { BaseProjectSurvey } from '@/types';
import { utils } from '@/enums';
import { useAlert } from '@/components/Modal/Alert';
import { useMatchProjectIdFromUrl } from '@/containers/GroupProject/hooks';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { BackLink } from '@/presentation/Back';
import { ButtonActivityIndicator } from '@/presentation';
import Toast from '@/components/Toast';
import Button from '@/components/Button';
import { Modal, ModalProps } from '@/components/Modal/Modal';
import { Input } from '@/components/Input';
import { PopperMenu, PopperMenuItem } from '@/components/Popper';
import * as Table from '@/components/Table';
import { getLocationFor, useToggle } from '@utils';
import { useProjectSurveysContext, ProjectSurveysContainer } from '$admin/containers/Project.Survey';
import styles from './style/ProjectSurveys.css';

type LocationState = {
  from: string;
};

export const ProjectSurveys = () => {
  const { projectId } = useMatchProjectIdFromUrl();

  return (
    <ProjectSurveysContainer projectId={projectId}>
      <SurveysTable />
    </ProjectSurveysContainer>
  );
};

type TableItem = BaseProjectSurvey;
const columnHelper = createColumnHelper<TableItem>();
const columns = [
  columnHelper.accessor('name', {
    cell: info => <NameCell item={info.row.original} />,
    header: 'Survey Name',
    meta: {
      className: styles.nameCell,
    },
  }),
  columnHelper.accessor('typeId', {
    cell: info => utils.SurveyType.getName(info.row.original.typeId),
    header: 'Type',
    meta: {
      className: styles.typeCell,
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: info => <SurveyContextMenu surveyId={info.row.original.id} />,
    header: '',
    meta: {
      className: styles.actionsCell,
    },
  }),
];

const SurveysTable = () => {
  const { query } = useProjectSurveysContext();
  const location = useLocation<LocationState>();
  const { projectId } = useMatchProjectIdFromUrl();
  const surveys = query.data.surveys;

  const table = useReactTable({
    data: surveys,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const backTo = useMemo(() => {
    return location.state?.from ||
      getLocationFor.project.surveyResponses({ slug: projectId.toString() });
  }, [projectId, location.state]);

  const canCreateNew = true;

  return (
    <div className={styles.root}>
      <div>
        <BackLink
          className={styles.back}
          to={backTo} />
      </div>
      <Table.Root className={styles.table}>
        <Table.Header
          classes={{
            thead: styles.thead,
            tr: styles.tr,
            th: styles.th,
          }}
          getHeaderGroups={table.getHeaderGroups}
          getHeaderCellStyles={def => def.meta?.['className']} />
        <Table.Body
          classes={{ tbody: styles.tbody }}
          getBodyCellStyles={def => def.meta?.['className']}
          getRowModel={table.getRowModel} />
      </Table.Root>
      <div>
        {canCreateNew &&
          <Button
            size='small'
            variant='brick'
            to={getLocationFor.project.surveyNew({ slug: projectId.toString() })}>
            Create New Survey
          </Button>
        }
      </div>
    </div>
  );
};

type NameCellProps = { item: TableItem };
const NameCell = ({ item }: NameCellProps) => {
  const onEdit = useEditSurvey({ surveyId: item.id });
  return (
    <div className={styles.name} onClick={onEdit}>{item.name}</div>
  );
};

const SurveyContextMenu = ({ surveyId }: ISurveyId) => {
  const onEdit = useEditSurvey({ surveyId });
  const onDelete = useDeleteSurvey({ surveyId });
  const [toggleAlert, Alert] = useAlert();
  const [open, toggleClone] = useToggle();

  const handleClick = useCallback((fn: () => unknown, onClose: () => void) => () => {
    onClose();
    return fn();
  }, []);

  const cloneSurvey = useMemo(() => {
    return {
      children: `Clone`,
      onClick: toggleClone,
    };
  }, [toggleClone]);

  const editSurvey = useMemo(() => {
    return {
      children: `Edit`,
      onClick: onEdit,
    };
  }, [onEdit]);

  const deleteSurvey = useMemo(() => {
    return {
      children: `Delete`,
      onClick: toggleAlert,
    };
  }, [toggleAlert]);

  const items = [
    editSurvey,
    deleteSurvey,
    cloneSurvey,
  ];

  return (
    <>
      <PopupState
        variant="popper"
        popupId="project-survey-popper">
        {popupState => (
          <div>
            <div {...bindToggle(popupState)}>
              <MoreHorizontalAnchor open={popupState.isOpen} />
            </div>
            <Popper
              {...bindPopper(popupState)}
              placement="bottom-end"
              className={styles.popper}>
              <ClickAwayListener
                onClickAway={popupState.close}>
                <div>
                  <PopperMenu>
                    {items.map((item, i) => (
                      <PopperMenuItem
                        key={i}
                        onClick={handleClick(item.onClick, popupState.close)}>
                        {item.children}
                      </PopperMenuItem>))}
                  </PopperMenu>
                </div>
              </ClickAwayListener>
            </Popper>
          </div>)}
      </PopupState>
      <Alert
        message={`Deleting a survey is not reversible. Are you sure you want to delete it?`}
        onConfirm={onDelete} />
      {open &&
        <CloneSurveyModal
          open={open}
          onClose={toggleClone}
          surveyId={surveyId} />}
    </>
  );
};

type CloneSurveyModalProps =
  & Pick<ModalProps, 'open' | 'onClose'>
  & ISurveyId;

const CloneSurveyModal = (props: CloneSurveyModalProps) => {
  const { query } = useProjectSurveysContext();
  const { projectId } = useMatchProjectIdFromUrl();
  const [name, setName] = useState('');

  const mutation = useMutation({
    mutationKey: [
      `post:projects/surveys/clone`,
      projectId,
      props.surveyId,
    ],
    mutationFn: () => {
      return $api.projects.surveys.clone({
        projectId,
        surveyId: props.surveyId,
        name,
      });
    },
    onSuccess: () => {
      query.refetch();
      props.onClose();
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}>
      <div className={styles.clone}>
        <div className={styles.header}>
          Clone Survey
        </div>
        <div className={styles.body}>
          <Input
            onChange={e => setName(e.target.value)}
            placeholder="Survey Name"
            value={name} />
        </div>
        <div className={styles.footer}>
          <ButtonActivityIndicator
            className={styles.btn}
            disabled={name.length <= 0}
            implicitDisable={mutation.isLoading}
            loading={mutation.isLoading}
            onClick={() => mutation.mutate()}>
            Clone Survey
          </ButtonActivityIndicator>
        </div>
      </div>
    </Modal>
  );
};

const SurveyTableActions = ({ surveyId }: ISurveyId) => {
  const history = useHistory();
  const onEdit = useEditSurvey({ surveyId });
  const onDelete = useDeleteSurvey({ surveyId });
  const [toggleAlert, Alert] = useAlert();

  return (
    <>
      <div className={styles.actions}>
        <Edit2 className={styles.action} onClick={onEdit} />
        <Trash className={styles.action} onClick={toggleAlert} />
      </div>
      <Alert message={`Deleting a survey is not reversible. Are you sure you want to delete it?`} onConfirm={onDelete} />
    </>
  );
};

const useEditSurvey = ({ surveyId }: ISurveyId) => {
  const { projectId } = useMatchProjectIdFromUrl();
  const history = useHistory();
  return useCallback(() => {
    history.push(getLocationFor.project.surveyEditor({ slug: projectId.toString(), surveyId }));
  }, [history, surveyId, projectId]);
};

const useDeleteSurvey = ({ surveyId }: ISurveyId) => {
  const { projectId } = useMatchProjectIdFromUrl();
  const { query } = useProjectSurveysContext();
  const deleteSurvey = useMutation(['delete-survey', surveyId, projectId], () => {
    return $api.projects.surveys.deleteSurvey({ surveyId, projectId });
  }, {
    onSuccess: () => {
      query.refetch();
    },
    onError: () => {
      Toast.error({
        title: 'There was an error deleting the survey',
      });
    },
  });

  return deleteSurvey.mutate;
};