import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import * as api from '@api';
import { GetProjectFiles } from '@api/interfaces/projects.files';
import { querykey } from '@consts';

type Props = GetProjectFiles.Request;
type Data = GetProjectFiles.Response;
type QueryKey = readonly [string, Props];
type Options = UseQueryOptions<Data, AxiosError, Data, QueryKey>;

export const useFetchProjectFiles = (props: Props, options?: Options) => {

  const qk = querykey.Projects.Files.Get(props);

  return useQuery(qk, ({ queryKey }) => {
    const [, params] = queryKey;
    return api.projects.files.getProjectFiles(params);
  }, options);
};

export const useInvalidateProjectFilesQuery = () => {

  const queryClient = useQueryClient();

  return useCallback(() => {
    const baseQueryKey = querykey.Projects.Files.Base;
    return queryClient.invalidateQueries([baseQueryKey]);
  }, [queryClient]);
};