import { Children, forwardRef, useCallback, useImperativeHandle, useMemo, useEffect, useState } from 'react';
import Label from './Label';
import SimpleAccordion from './SimpleAccordion';
import type { AccordionProps, AccordionRefObject } from './interfaces';

type Props =
  AccordionProps;

const Accordion = forwardRef<AccordionRefObject, Props>(({
  label,
  ...props
}, ref) => {
  const [count, setCount] = useState(Children.count(props.children));
  const [open, setOpen] = useState(!props.collapsed);
  const setVisibility = useCallback((visible: boolean) => setOpen(visible), [setOpen]);
  const toggleVisibility = useCallback(() => {
    if (count > 0) setOpen(!open);
  }, [open, setOpen, count]);

  useImperativeHandle(ref, () => ({ toggleVisibility }), [toggleVisibility]);

  const nextCount = useMemo(() => {
    return Children.count(props.children);
  }, [props.children]);

  useEffect(() => {
    if (!props.collapsed && nextCount !== count && nextCount > 0) {
      setVisibility(true);
    }
  }, [
    props.collapsed,
    nextCount,
    count,
    setVisibility,
  ]);

  useEffect(() => {
    if (nextCount !== count) {
      setCount(nextCount);
    }
  }, [nextCount, count]);

  const renderLabel = useCallback(() => {
    if (typeof label !== 'function') {
      return (
        <Label
          className={props.classes.label}
          count={count}
          showCount={props.showCount}
          tooltipText={props.tooltipText}>
          {label}
        </Label>
      );
    } else {
      return label(count);
    }
  }, [
    count,
    label,
    props.showCount,
    props.tooltipText,
    props.classes.label,
  ]);

  return (
    <SimpleAccordion
      className={props.className}
      classes={props.classes}
      grows={props.grows}
      height={props.height}
      label={renderLabel()}
      open={open}
      toggleOpen={toggleVisibility}>
      {props.children}
    </SimpleAccordion>
  );
});

Accordion.defaultProps = {
  classes: {},
  collapsed: false,
  grows: true,
  height: 60,
  label: '',
  showCount: true,
  tooltipText: '',
};

export { Accordion };
export default Accordion;
