import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyPipedValueType,
} from '@enums/Survey';
import {
  NetPromoterScoreQuestion,
  NetPromoterScoreTemplateSection,
  SurveyTemplateEntityType,
  SurveyTemplateType,
} from '@enums/survey.template';
import { SurveyTemplate } from '@/types/survey.templates';
import {
  MatrixSliderQuestion,
  MultipleChoiceQuestion,
  SurveyQuestion,
  SurveySection,
  SurveyBuilderVersion,
} from '@/types/survey';
import * as $question from '../utils/question';
import * as $shared from './template.shared-questions';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.NetPromoterScore = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.NetPromoterScore = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5, 'Competitor'),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.NetPromoterScore): SurveyBuilderVersion {

  const screeningSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: NetPromoterScoreTemplateSection.Screening },
    },
    hidden: false,
    name: 'Screening',
    ordinal: 1,
  });

  const targetNPSection = $template.generateSection({
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: {
        key: NetPromoterScoreTemplateSection.NetPromoterTargt,
        linkedEntity: {
          id: data.target.id,
          type: SurveyTemplateEntityType.Target,
        },
      },
    },
    hidden: true,
    name: 'Net Promoter Score Target',
    ordinal: 2,
  });

  const competitorNPSections = data.competitors.map((m, i) => generateNetPromoterCompetitorSection(m, i + 3));

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: screeningSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: screeningSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleDQQuestion({ section: screeningSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: screeningSection });
  }

  const {
    logic: respondentRoleLogic,
    question: respondentRoleQuestion,
  } = (function RespondentRoleQuestion() {
    const questionIdentifier = cuid();

    const noInvolvementOption = $template.generateMultipleChoiceOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
      },
      ordinal: 1,
      value: `No involvement in the evaluation / usage`,
    });

    const otherOptions = [
      `User`,
      `Decision-maker / Relationship Manager`,
      `User and Decision-maker / Relationship Manager`,
    ];

    const dqIfNoInvolvement = $template.generateOptionSelectedLogic({
      action: { type: SurveyActionType.Disqualification },
      question: { identifier: questionIdentifier },
      option: { identifier: noInvolvementOption.base.identifier },
    });

    const logic = [dqIfNoInvolvement];

    const question = $template.generateMultipleChoiceQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: NetPromoterScoreQuestion.RespondentEvaluationRole,
        },
      },
      options: [
        noInvolvementOption,
        ...otherOptions.map((value, i) => $template.generateMultipleChoiceOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
          },
          ordinal: i + 2,
          value,
        })),
      ],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`What best describes your role in the evaluation / usage of `),
        $template.generateCategoryNode(data.category),
        $template.generateTextNode(`?`),
      ]),
    });

    return { logic, question };
  })();

  const {
    logic: orgProductsLogic,
    question: orgProductsQuestion,
  } = (function OrgProductsQuestion() {
    const questionIdentifier = cuid();

    const targetOption = $template.generateTargetMultiselectOption({
      ordinal: 1,
      target: data.target.value,
    });

    const competitorOptions = data.competitors.map((item, i) => $template.generateCompetitorMultiselectOption({
      item,
      ordinal: i + 2,
    }));

    const targetResponseLogic = $template.generateOptionSelectedLogic({
      action: {
        type: SurveyActionType.IncludeSection,
        section: {
          identifier: targetNPSection.identifier,
        },
      },
      question: { identifier: questionIdentifier },
      option: { identifier: targetOption.base.identifier },
    });

    const competitorResponseLogic = competitorOptions.map(option => {
      const section = competitorNPSections.find(f => f.metadata.template.linkedEntity.id === option.metadata.template.linkedEntity.id);
      return $template.generateOptionSelectedLogic({
        action: {
          type: SurveyActionType.IncludeSection,
          section: {
            identifier: section.identifier,
          },
        },
        question: { identifier: questionIdentifier },
        option: { identifier: option.base.identifier },
      });
    });

    const logic = [
      targetResponseLogic,
      ...competitorResponseLogic,
    ].filter(Boolean);

    const question = $template.generateMultiselectQuestion({
      identifier: questionIdentifier,
      metadata: {
        anchor: false,
        canModifyValue: false,
        canDelete: false,
        template: {
          key: NetPromoterScoreQuestion.OrgCurrentCustomer,
        },
      },
      options: [
        targetOption,
        ...competitorOptions,
      ],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Please select all of '),
        categoryNode(),
        $template.generateTextNode(` offerings that your organization is a customer of?`),
      ]),
    });

    return { logic, question };
  })();

  function generateNetPromoterTargetQuestions() {
    const params: GenerateNetPromoterQuestion = {
      isTarget: true,
      linkedEntity: data.target,
      ordinal: 0,
      section: targetNPSection,
    };

    const recommendationQuestion = RecommendationQuestion(params);

    return [
      recommendationQuestion,
      RecommendationReasonQuestion({
        ...params,
        recommendationQuestion,
      }),
      RecommendationHigherRating(params),
    ];
  }

  function generateNetScoreQuestions() {

    return data.competitors.reduce<SurveyQuestion[]>((acc, competitor) => {
      const section = competitorNPSections.find(f => f.metadata.template.linkedEntity.id === competitor.id);

      return acc.concat(generateNetScoreCompetitorQuestions({
        competitor,
        section,
        startingOrdinal: 0,
      }));

    }, []);
  }

  const questions: SurveyQuestion[] = [
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    respondentRoleQuestion,
    orgProductsQuestion,
    ...generateNetPromoterTargetQuestions(),
    ...generateNetScoreQuestions(),
  ];

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: $template.generateItemsForQuestions(questions),
    logic: [
      ...respondentRoleLogic,
      ...orgProductsLogic,
    ],
    messages: [],
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [
      screeningSection,
      targetNPSection,
      ...competitorNPSections,
    ],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.NetPromoterScore,
    },
  };
}

export function generateNetPromoterCompetitorSection(competitor: SurveyTemplate.LinkedEntity, ordinal: number) {
  return $template.generateSection({
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: {
        key: NetPromoterScoreTemplateSection.NetPromoterCompetitor,
        linkedEntity: {
          id: competitor.id,
          type: SurveyTemplateEntityType.Competitor,
        },
      },
    },
    hidden: true,
    name: 'Net Promoter Score Competitor',
    ordinal,
  });
}

type GenerateNetPromoterCompetitorQuestions = {
  competitor: SurveyTemplate.Competitor;
  section: SurveySection;
  startingOrdinal: number;
};

type GenerateNetPromoterQuestion = {
  linkedEntity: {
    id: string;
    value: string;
  };
  isTarget: boolean;
  ordinal: number;
  recommendationQuestion?: MatrixSliderQuestion.Question;
  section: Pick<SurveySection, 'identifier' | 'ordinal'>;
};

export function generateNetScoreCompetitorQuestions({ competitor, section, startingOrdinal }: GenerateNetPromoterCompetitorQuestions) {

  const params: GenerateNetPromoterQuestion = {
    isTarget: false,
    linkedEntity: competitor,
    ordinal: startingOrdinal,
    section,
  };

  const recommendationQuestion = RecommendationQuestion(params);

  return [
    recommendationQuestion,
    RecommendationReasonQuestion({
      ...params,
      ordinal: startingOrdinal + 1,
      recommendationQuestion,
    }),
    RecommendationHigherRating({
      ...params,
      ordinal: startingOrdinal + 1,
    }),
  ];
}

function RecommendationQuestion({ isTarget, linkedEntity, ordinal, section }: GenerateNetPromoterQuestion) {

  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateSlidersQuestion({
    metadata: {
      anchor: false,
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendation
          : NetPromoterScoreQuestion.TargetRecommendation,
      },
    },
    matrixRows: [$template.generateSlidersRow({
      metadata: {
        canDelete: false,
        canModifyValue: false,
      },
      ordinal: 1,
      value: 'Likelihood',
    })],
    ordinal,
    section: {
      identifier: section.identifier,
    },
    settings: {
      ...$question.matrixSlider.DefaultSettings,
      slider: {
        displayPctOfTotal: false,
        hideSlider: false,
        increment: 1,
        label: null,
        minValue: 0,
        maxValue: 10,
        ensureAnswerTotalEqualsMax: false,
        total: $question.matrixSlider.DefaultSettings.slider.total,
      },
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`How likely is it that you would recommend `),
      node,
      $template.generateTextNode(` to a friend or colleague?`),
    ]),
  });
}

function RecommendationReasonQuestion({ isTarget, linkedEntity, ordinal, recommendationQuestion, section }: GenerateNetPromoterQuestion) {

  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateShortTextQuestion({
    metadata: {
      anchor: false,
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendationReason
          : NetPromoterScoreQuestion.TargetRecommendationReason,
      },
    },
    ordinal,
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`Please tell us why you gave `),
      node,
      $template.generateTextNode(` a `),
      $template.generatePipedQuestionResponseNode({
        pipedValue: {
          type: SurveyPipedValueType.SliderRowValue,
          row: {
            identifier: recommendationQuestion.matrixRows[0].base.identifier,
          },
        },
        questionIdentifier: recommendationQuestion.base.identifier,
      }),
      $template.generateTextNode(` rating.`),
    ]),
  });
}

function RecommendationHigherRating({ isTarget, linkedEntity, ordinal, section }: GenerateNetPromoterQuestion) {
  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateShortTextQuestion({
    metadata: {
      anchor: false,
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendationReason
          : NetPromoterScoreQuestion.TargetRecommendationReason,
      },
    },
    ordinal,
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`What changes would `),
      node,
      $template.generateTextNode(` have to make for you to give it a higher rating?`),
    ]),
  });
}