import { useSurveyBuilderItemEditing } from '@containers/SurveyBuilder/Context';
import { FormButtons } from '@presentation/FormButtons';
import {
  useCancelMessageEditing,
  useSaveMessageContext,
} from '@/containers/SurveyBuilder.Message/context';
import { MessageBuilderContainer, MessageBuilderEditorContainer } from '@/containers/SurveyBuilder.Message';
import { MessageNumber } from '@presentation/Survey';
import { SurveyRichTextEditor } from '@/components/Survey.RichText';
import { SurveyMessageContextMenu } from './ContextMenu';
import { Toolbar } from './Toolbar';
import styles from './style/MessageBuilder.css';

export const MessageBuilder = () => {

  const { editing } = useSurveyBuilderItemEditing();

  return (
    <div className={styles.root}>
      {editing && <Editing />}
      {!editing && <NotEditing />}
    </div>
  );

};

const NotEditing = () => {

  return (
    <MessageBuilderEditorContainer>

      <div className={styles.text}>
        <MessageNumber className={styles.number} />
        <SurveyRichTextEditor className={styles.editor} />
      </div>

      <SurveyMessageContextMenu className={styles.contextMenu} />

    </MessageBuilderEditorContainer>
  );
};

const Editing = () => {
  return (
    <MessageBuilderEditorContainer>
      <Toolbar className={styles.toolbar} />

      <SurveyRichTextEditor className={styles.editor} />

      <MessageBuilderContainer>
        <MessageBuilderFormButtons />
      </MessageBuilderContainer>
    </MessageBuilderEditorContainer>
  );
};

const MessageBuilderFormButtons = () => {

  const handleCancel = useCancelMessageEditing();
  const { canSave, onSave } = useSaveMessageContext();

  return (
    <div className={styles.buttons}>
      <FormButtons
        archivable={false}
        disabled={!canSave}
        deletable={false}
        onSubmit={onSave}
        onCancel={handleCancel} />
    </div>
  );
};

export default MessageBuilder;