import cuid from 'cuid';
import {
  SurveyActionType,
} from '@enums/Survey';
import {
  MarketAnalysisQuestion,
  MarketAnalysisSection,
  SurveyTemplateType,
} from '@enums/survey.template';
import { SurveyTemplate } from '@/types/survey.templates';
import {
  SurveyQuestion,
  SurveyBuilderVersion,
} from '@/types/survey';
import * as $question from '../utils/question';
import * as $template from './utils';

export const defaultTemplateData: SurveyTemplate.MarketAnalysis = {
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.MarketAnalysis = {
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.MarketAnalysis): SurveyBuilderVersion {

  return {
    alternateImageExercises: [],
    classifications: [],
    createdOn: null,
    id: null,
    items: [],
    logic: [],
    messages: [],
    questions: [],
    quotas: [],
    sections: [
    ],
    surveyId: null,
    surveyDetails: null,
    tagging: [],
    template: {
      data,
      type: SurveyTemplateType.MarketingCampaignEffectiveness,
    },
  };
}