import {
  SurveySection,
} from '@/types/survey';
import { OrdinalMap, SurveySectionsBuilder } from '../interfaces';
import {
  generateNewSection,
} from './defaults';

type State = SurveySectionsBuilder.State;

export function sectionAdded(state: State, { value }: SurveySectionsBuilder.SectionAdded.State): State {

  const ordinal = value.ordinal;
  const ordinals = generateNewOrdinals();

  const aboveSection = state.filter(f => f.ordinal < ordinal);
  const belowSection = state.filter(f => f.ordinal >= ordinal);

  return [
    ...aboveSection,
    value,
    ...belowSection.map(refreshSection),
  ];

  function generateNewOrdinals() {
    return state.reduce<OrdinalMap>((acc, x) => {
      acc[x.identifier] = x.ordinal < ordinal
        ? x.ordinal
        : x.ordinal + 1;
      return acc;
    }, {});
  }

  function refreshSection(section: SurveySection) {
    const newOrdinal = ordinals[section.identifier];
    return {
      ...section,
      name: section.name === `Section ${section.ordinal}`
        ? `Section ${newOrdinal}`
        : section.name,
      ordinal: newOrdinal,
    };
  }
}

export function newSectionAdded(state: State, { ordinal }: SurveySectionsBuilder.NewSectionAdded.Action): State {

  const newSection = generateNewSection({ ordinal });

  return sectionAdded(state, { value: newSection });
}

export function removeSection(state: State, { identifier }: SurveySectionsBuilder.RemoveSection.State): State {
  const toRemove = state.find(f => f.identifier === identifier);

  const sectionOrdinals = generateNewSectionOrdinals();

  return state
    .filter(f => f.identifier !== identifier)
    .map(refreshSection);

  function refreshSection(section: SurveySection): SurveySection {
    return section.ordinal < toRemove.ordinal
      ? section
      : {
        ...section,
        name: section.name === `Section ${section.ordinal}`
          ? `Section ${sectionOrdinals[section.identifier]}`
          : section.name,
        ordinal: sectionOrdinals[section.identifier],
      };
  }

  function generateNewSectionOrdinals() {
    return state.reduce<OrdinalMap>((acc, x) => {
      acc[x.identifier] = x.ordinal < toRemove.ordinal
        ? x.ordinal
        : x.ordinal > toRemove.ordinal
          ? x.ordinal - 1
          : null;
      return acc;
    }, {});
  }
}

export function toggleSectionHidden(state: State, { identifier }: SurveySectionsBuilder.ToggleSectionHidden.Action): State {

  return state.reduce<State>((acc, x) => {
    if (x.identifier === identifier) {
      return acc.concat({
        ...x,
        hidden: !x.hidden,
      });
    }

    return acc.concat(x);
  }, []);

}

export function sectionNameUpdated(state: State, { payload }: SurveySectionsBuilder.SectionNameUpdated.Action): State {

  return state.reduce<State>((acc, x) => {

    return x.identifier === payload.identifier
      ? acc.concat({ ...x, name: payload.value })
      : acc.concat(x);

  }, []);
}

export function sectionScreenerToggled(state: State, { payload }: SurveySectionsBuilder.SectionScreenerToggled.Action): State {

  return state.reduce<State>((acc, x) => {
    if (x.identifier === payload.identifier) {
      return acc.concat({
        ...x,
        isScreener: !x.isScreener,
      });
    }

    return acc.concat(x);
  }, []);
}

export function sectionBreakAdded(state: State, action: SurveySectionsBuilder.SectionBreakAdded.Action): State {
  const section = state.find(f => f.identifier === action.payload.identifier);

  const newSection = generateNewSection({
    identifier: action.payload.newIdentifier,
    ordinal: section.ordinal + 1,
  });

  return sectionAdded(state, { value: newSection });
}

export function sectionMetadataUpdated(state: State, { payload }: SurveySectionsBuilder.SectionMetadataUpdated.Action): State {
  return state.reduce<State>((acc, x) => {
    if (x.identifier === payload.identifier) {
      return acc.concat({
        ...x,
        metadata: {
          ...x.metadata,
          ...payload.metadata,
        },
      });
    }

    return acc.concat(x);
  }, []);
}