import { useCallback, useContext } from 'react';
import { SurveyBuilderItemContext } from '@containers/SurveyBuilder/Context';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { useCanPreviewSurvey, useIsEditingSurvey } from '@/containers/SurveyBuilder/hooks';
import { BuilderQuestionText } from '@presentation/Survey';
import { getLocationFor } from '@utils';
import { QuestionPreviewItem } from '@/components/SurveyBuilder.Question.Preview';
import { QuestionContextMenu } from './QuestionContextMenu';
import styles from './style/EditableQuestion.css';

const EditableQuestion = () => {
  const item = useQuestionBuilderItemContext();
  const editing = useIsEditingSurvey();
  const state = useContext(SurveyBuilderItemContext);

  const { canPreview, surveyVersionId } = useCanPreviewSurvey();

  const renderQuestion = useCallback(() => {
    return (
      <div className={styles.question}>
        <BuilderQuestionText
          className={styles.questionText}
          item={{
            ordinal: item.ordinal,
            value: item.value,
            identifier: item.base.identifier,
            captureSheetColor: item.captureSheetColor,
            captureSheetLabel: item.captureSheetLabel,
          }} />
        {!editing &&
          <QuestionContextMenu
            className={styles.menu}
            canPreview={canPreview}
            onClickPreview={() => {
              window.open(`${getLocationFor.project.surveyPreview({ surveyVersionId }).pathname}?ordinal=${state.ordinal}`);
            }} />}
      </div>
    );
  }, [
    editing,
    item,
    canPreview,
    surveyVersionId,
    state.ordinal,
  ]);

  return (
    <div className={styles.root}>
      {renderQuestion()}
      <QuestionPreviewItem
        item={item} />
    </div>
  );
};

export default EditableQuestion;