import qs from 'qs';
import http, { transformers } from '@services/http';
import { nullableDate } from '@utils/date';
import type { Surveys } from './interfaces';

export * as captureSheet from './surveys.capture-sheet';

const toggleSurveyResponseAnswerVisibility = (data: Surveys.ToggleAnswerVisibility.Request): Promise<Surveys.ToggleAnswerVisibility.Response> => {
  const { responseId, questionId, ...rest } = data;
  return http.post(`/surveys/responses/${data.responseId}/questions/${data.questionId}/visibility`, rest);
};

const goBack = (data: Surveys.Response.GoBack.Request): Promise<Surveys.Response.GoBack.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'back',
    body: data,
  });
};

const goToItem = (data: Surveys.Response.GoToItem.Request): Promise<Surveys.Response.GoToItem.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'go-to-item',
    body: data,
  });
};

const submitMessageScreen = (data: Surveys.Response.SubmitMessageScreen.Request): Promise<Surveys.Response.SubmitMessageScreen.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-message-screen',
    body: data,
  });
};

const submitAIEScreen = (data: Surveys.Response.SubmitAIEScreen.Request): Promise<Surveys.Response.SubmitAIEScreen.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-aie-screen',
    body: data,
  });
};

const submitAnswer = (data: Surveys.Response.SubmitAnswer.Request): Promise<Surveys.Response.SubmitAnswer.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-answer',
    body: data,
  });
};

export const uploadSurveyImageAttachment = (data: Surveys.UploadImageAttachment.Request): Promise<Surveys.UploadImageAttachment.Response> => {
  const form = new FormData();

  form.append('blob', data.blob);
  form.append('identifier', data.identifier);

  return http.post(`/surveys/attachments`, form, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export const uploadStimSequenceAttachment = (data: Surveys.UploadStimSequenceAttachment.Request): Promise<Surveys.UploadStimSequenceAttachment.Response> => {
  const form = new FormData();

  form.append('blob', data.blob);

  return http.post(`/surveys/stim-sequence-attachments`, form, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

const getSurveyDrafts = (data: Surveys.GetSurveyDrafts.Request) => {
  return http.get<Surveys.GetSurveyDrafts.Response>(`/surveys/drafts?${qs.stringify(data, { skipNulls: true })}`, { transformResponse: transformers.transformDates });
};

const getSurveyDraft = (data: Surveys.GetSurveyDraft.Request) => {
  return http.get<Surveys.GetSurveyDraft.Response>(`/surveys/draft/${data.surveyVersionId}`, { transformResponse: transformers.transformDates });
};

const saveSurveyDraft = (data: Surveys.SaveSurveyDraft.Request): Promise<Surveys.SaveSurveyDraft.Response> => {
  return http.post(`/surveys/draft`, data);
};

const discardSurveyDrafts = (data: Surveys.DiscardSurveyDrafts.Request) => {
  return http.delete(`/surveys/drafts?${qs.stringify(data, { skipNulls: true })}`);
};

const discardSurveyDraft = (data: Surveys.DiscardSurveyDraft.Request) => {
  return http.delete(`/surveys/draft/${data.surveyVersionId}`);
};

const getSurveyVersionForm = (data: Surveys.GetSurveyForm.Request): Promise<Surveys.GetSurveyForm.Response> => {
  return http.get(`/survey-versions/${data.surveyVersionId}/survey-form`, {
    params: {
      preview: data.preview,
      ordinal: data.ordinal,
    },
  });
};

const searchSurveyModuleCategories = (query: Surveys.SearchSurveyModuleCategories.Request): Promise<Surveys.SearchSurveyModuleCategories.Response> => {
  return http.get(`/surveys/modules/search-categories?${qs.stringify(query)}`);
};

const searchSurveyModulesByCategories = ({ categoryId, ...query }: Surveys.SearchSurveyModulesByCategory.Request): Promise<Surveys.SearchSurveyModulesByCategory.Response> => {
  return http.get(`/surveys/modules/search-categories/${categoryId}/modules?${qs.stringify(query)}`);
};

const fetchSurveyModule = (data: Surveys.FetchSurveyModule.Request): Promise<Surveys.FetchSurveyModule.Response> => {
  const query = { isModule: data.isModule, skipSanitization: data.skipSanitization };
  return http.get(`/surveys/modules/${data.surveyId}?${qs.stringify(query)}`)
    .then((data: Surveys.FetchSurveyModule.Response<string>) => ({
      ...data,
      module: {
        ...data.module,
        createdOn: nullableDate(data.module.createdOn),
      },
      survey: {
        ...data.survey,
        createdOn: nullableDate(data.survey.createdOn),
      },
    }));
};

export const fetchSurvey = (data: Surveys.GetSurvey.Request): Promise<Surveys.GetSurvey.Response<Date>> => {
  return http.get<Surveys.GetSurvey.Response<Date>>(`/surveys/${data.surveyId}`, { transformResponse: transformers.transformDates });
};

export const upsertSurvey = (data: Surveys.UpsertSurvey.Request): Promise<Surveys.UpsertSurvey.Response> => {
  return http.patch(`/surveys/upsert`, {
    survey: data.survey,
    draftVersionId: data.draftVersionId,
  }, { transformResponse: transformers.transformDates });
};

export const downloadSurveyTexts = ({ surveyId, surveyVersionId }: Surveys.DownloadSurveyTexts.Request) => {
  return http.download(`/surveys/${surveyId}/texts${surveyVersionId ? qs.stringify({ surveyVersionId }) : ''}`);
};

export const response = {
  goBack,
  goToItem,
  submitAIEScreen,
  submitAnswer,
  submitMessageScreen,
};

export const responses = {
  toggleAnswerVisibility: toggleSurveyResponseAnswerVisibility,
};

export const draft = {
  discardSurveyDraft,
  discardSurveyDrafts,
  getSurveyDraft,
  getSurveyDrafts,
  saveSurveyDraft,
};

export const version = {
  getSurveyVersionForm,
};

export const module = {
  fetchSurveyModule,
  searchCategories: searchSurveyModuleCategories,
  searchModulesByCategories: searchSurveyModulesByCategories,
};