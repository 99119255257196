import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question';
import { SurveySettings } from '@/types/survey';
import { Input } from '@/components/Input';
import { CheckboxLabel } from '@/components/Checkbox';
import styles from './style/Settings.DontKnow.css';

type Props = {
  className?: string;
  disabled?: boolean;
  label?: string;
};

export const ShowDontKnowCheckbox = ({
  className,
  disabled,
  label = `Show "I Don't know"`,
}: Props) => {

  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItem();

  const value = (item.settings as SurveySettings.IDontKnow).dontKnow;

  const handleToggle = useCallback(() => {
    const enabled = !value.enabled;
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        dontKnow: {
          ...value,
          enabled,
        },
      },
    });
  }, [
    dispatch,
    item.settings,
    item.base.identifier,
    value,
  ]);

  const handleLabelChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update-question-settings',
      questionIdentifier: item.base.identifier,
      settings: {
        ...item.settings,
        dontKnow: {
          enabled: true,
          label: e.target.value,
        },
      },
    });
  }, [
    dispatch,
    item.settings,
    item.base.identifier,
  ]);

  return (
    <div className={className}>
      <CheckboxLabel
        disabled={disabled}
        checked={value.enabled}
        label={label}
        onChange={handleToggle} />
      {value.enabled &&
        <div className={styles.row}>
          <div className={styles.label}>Label:</div>
          <Input
            placeholder="Enter a label for the 'I do not know' option"
            onChange={handleLabelChange}
            value={value.label} />
        </div>
      }
    </div>
  );
};