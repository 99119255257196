import { useState } from 'react';
import * as consts from '@consts';
import { Button } from '@/components/Button';
import { PrivacyPolicyTerms } from '@/components/Modal/PrivacyPolicyTerms';
import styles from './style/SignUpForm.css';

type Props = unknown;

export const SignupFooter = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.caption}>
      <div>By signing up, you agree to our</div>
      <div
        className={styles.grow}
        onClick={() => setOpen(true)}>
        <span>Terms and Privacy Policy</span>
        <div className={styles.underline} />
      </div>
      <PrivacyPolicyTerms
        onClose={() => setOpen(false)}
        visible={open} />
    </div>
  );
};

export const NavigateToLogin = () => {
  return (
    <div className={styles.subtitle}>
      <span>Already have an account? </span>
      <Button
        to={consts.path.Website.Login}
        variant="link">
        <span className={styles.link}>Log In</span>
      </Button>
    </div>
  );
};

export const BrandInsightsTermsOfUse = (props: Props) => {
  return (
    <div className={styles.terms}>
      <div>By signing up, you agree to our</div>
      <div className={styles.grow}>
        <span className={styles.tou}>
          <a
            href={consts.path.Website.Terms.BrandInsights}
            target="_blank"
            rel="noreferrer">
            Terms of Use
          </a>
        </span>
        <div className={styles.underline} />
      </div>
    </div>
  );
};

export default SignupFooter;