import { SurveyBuilder, SurveyDraftBuilder } from '../interfaces';

export function draftReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyDraftBuilder.State {
  const draft = state.draft;
  switch (action.type) {
    case 'draft-discarded':
      return {
        ...draft,
        surveyVersionId: null,
      };

    case 'draft-loaded':
      return {
        ...draft,
        surveyVersionId: action.draft.id,
      };

    case 'draft-survey-data-updated':
      return {
        ...draft,
        surveyVersionId: action.value.id,
      };

    case 'draft-status-updated':
      return {
        ...draft,
        status: action.status,
      };

    case 'reset-survey':
      return {
        surveyVersionId: null,
        status: 'idle',
      };
  }
  return state.draft;
}