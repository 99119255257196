import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column as RTColumn, UsePaginationInstanceProps, useFilters, useTable, useFlexLayout, usePagination, TableOptions } from 'react-table';
import { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Projects } from '@api/interfaces/projects';
import { useIsInternalUser } from '@/containers/Store';
import { cx } from '@utils';
import { Pagination } from '$admin/Project.Aggregate/Pagination';
import { Cells as Column } from '@/components/GroupProject/ProjectsTableCells';
import * as Table from '@/components/Table';
import styles from './style/Group.Projects.css';
import { EmptyState } from './EmptyState';

type Props = {
  classes?: {
    body?: string;
    td?: string;
  };
  data:       Projects.FetchProjects.ProjectItem[];
  empty:      boolean;
  loading:    boolean;
  pagination: Projects.FetchProjects.Response['pagination'];
  query:      DecodedValueMap<QPConfig>;
  setQuery:   SetQuery<QPConfig>;
};

export const ProjectsTable = ({ query, setQuery, classes = {}, ...props }: Props) => {
  const tablescroll = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(query.index);
  const [scrolled, setScrolled] = useState(true);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const isInternalUser = useIsInternalUser();

  const setSort = useCallback((key: string) => {
    const sorting = query.sortBy === key;
    const desc = query.sortDir === 'desc';

    if (sorting) {
      setQuery({
        sortDir: desc ? 'asc' : 'desc',
      }, 'pushIn');
    } else {
      setQuery({
        sortBy: key,
        sortDir: 'desc',
      }, 'replace');
    }

  }, [
    query,
    setQuery,
  ]);

  const columns = useMemo<RTColumn<Projects.FetchProjects.ProjectItem>[]>(() => [
    {
      //accessor: 'name',
      id: 'name',
      Cell: Column.Name,
      Header: () => {
        return (
          <SortableHeader
            sorted={query.sortBy === 'name' ? query.sortDir : false}
            onClick={() => setSort('name')}>
            Name
          </SortableHeader>
        );
      },
      minWidth: 200,
    },
    {
      //accessor: 'metrics.progress.percentage',
      id: 'progress',
      Header: () => {
        return (
          <SortableHeader
            sorted={query.sortBy === 'progress' ? query.sortDir : false}
            onClick={() => setSort('progress')}>
            Progress
          </SortableHeader>
        );
      },
      Cell: Column.Progress,
      minWidth: 105,
      maxWidth: 105,
    },
    isInternalUser && isDesktop ? {
      //accessor: 'owner',
      id: 'owner',
      Cell: Column.Owner,
      Header: () => {
        return (
          <SortableHeader
            sorted={query.sortBy === 'owner' ? query.sortDir : false}
            onClick={() => setSort('owner')}>
            Owner
          </SortableHeader>
        );
      },
      minWidth: 80,
      maxWidth: 80,
    } : undefined,
    isDesktop ? {
      //accessor: 'createdOn',
      id: 'createdOn',
      Header: () => {
        return (
          <SortableHeader
            sorted={query.sortBy === 'created' ? query.sortDir : false}
            onClick={() => setSort('created')}>
            Created On
          </SortableHeader>
        );
      },
      Cell: Column.CreatedOn,
      minWidth: 80,
      maxWidth: 80,
    }: undefined,
    {
      id: 'actions',
      Cell: Column.Actions,
      minWidth: 50,
      width: 50,
    },
  ].filter(Boolean), [
    isDesktop,
    isInternalUser,
    query,
    setSort,
  ]);

  const params: TableOptions<Projects.FetchProjects.ProjectItem> = {
    columns,
    data: props.data,
    initialState: {
      pageIndex: 0,
      pageSize: query.size,
    },
    manualFilters: true,
    manualPagination: true,
    pageCount: props.pagination.pageCount,
  };

  const {
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    rows,
  } = useTable<Projects.FetchProjects.ProjectItem>(
    params,
    useFilters,
    useFlexLayout,
    usePagination,
  );

  const nextPage = useCallback(() => {
    setQuery({ index: query.index + 1 }, 'pushIn');
    setIndex(query.index + 1);
    setScrolled(false);
  }, [
    query.index,
    setQuery,
  ]);

  const previousPage = useCallback(() => {
    const prevIndex = query.index - 1;
    const index = prevIndex > 0
      ? prevIndex
      : undefined;
    setQuery({ index }, 'pushIn');
    setIndex(index ?? 0);
    setScrolled(false);
  }, [
    query.index,
    setQuery,
  ]);

  const canPreviousPage = query.index > 0;
  const canNextPage = query.index + 1 < props.pagination.pageCount;

  const paginationProps: PaginationProps = {
    canNextPage,
    canPreviousPage,
    nextPage,
    pageCount: props.pagination.pageCount,
    pageIndex: query.index,
    pageSize: query.size,
    pageSizeOptions: [25, 50, 100],
    previousPage,
    pageOptions,
    setPageSize: size => setQuery({ index: 0, size }, 'pushIn'),
  };

  const checkScroll = useCallback(() => {
    if (!scrolled && query.index === index) {
      tablescroll.current?.scrollTo?.(0, 0);
      setScrolled(true);
    }
  }, [
    scrolled,
    setScrolled,
    index,
    query.index,
  ]);

  useEffect(() => {

    checkScroll();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.data]);

  return (
    <Table.Root
      EmptyComponent={EmptyState}
      empty={props.empty}
      loading={props.loading}>
      <Table.Header headerGroups={headerGroups} />
      <Table.Body
        {...getTableBodyProps()}
        classes={{
          tbody: classes.body ?? styles.body,
          td: classes.td ?? styles.td,
        }}
        prepareRow={prepareRow}
        ref={tablescroll}
        rows={rows} />
      <Table.Layout.Footer>
        <Pagination {...paginationProps} />
      </Table.Layout.Footer>
    </Table.Root>
  );
};

ProjectsTable.displayName = 'Groups.Projects.Table';

type QPConfig = {
  index:   QueryParamConfig<number, number>;
  size:    QueryParamConfig<number, number>;
  status:  QueryParamConfig<number, number>;
  sortBy:  QueryParamConfig<'created' | 'name' | 'owner' | 'progress', string>;
  sortDir: QueryParamConfig<'asc' | 'desc', string>;
};

type PaginationProps = {
  pageIndex:        number;
  pageSize:         number;
  pageSizeOptions?: number[];
} & Pick<UsePaginationInstanceProps<Projects.FetchProjects.ProjectItem>,
    | 'canNextPage'
    | 'canPreviousPage'
    | 'nextPage'
    | 'previousPage'
    | 'pageCount'
    | 'pageOptions'>
  & Partial<Pick<UsePaginationInstanceProps<Projects.FetchProjects.ProjectItem>,
    | 'setPageSize'>>;

type SortableHeaderProps = {
  children: React.ReactNode;
  className?: string;
  onClick:  (e: unknown) => void;
  sorted:   'asc' | 'desc' | string | false;
};

const SortableHeader = (props: SortableHeaderProps) => {
  const desc = props.sorted === 'desc';

  return (
    <div
      className={cx(styles.sortable, props.className)}
      onClick={props.onClick}>
      {props.children}
      {(props.sorted && !desc) && <ArrowDropUpIcon />}
      {(props.sorted && desc) && <ArrowDropDownIcon />}
    </div>
  );
};