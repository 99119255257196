import { useCallback } from 'react';
import { Trash2 as Trash } from 'react-feather';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SectionTypeDisplayName } from '@containers/SurveyBuilder/utils/question.exclusive-options';
import { ExclusiveOptionsQuestion } from '@/types/survey.question.exclusive-options';
import { AddItemButton } from '@presentation/AddItemButton';
import { convertToSurveyRichText, parseSurveyRichText } from '@/containers/Survey/utils';
import { CheckboxLabel } from '@/components/Checkbox';
import { DropDown } from '@/components/DropDown';
import { Input } from '@/components/Input';
import { TextEntryList } from '@/components/TextEntryList';
import { ExclusiveOptionsOptionContextMenu } from './ExclusiveOptions.Option.ContextMenu';
import styles from './style/ExclusiveOptions.css';
import { RationaleCheckbox } from './Settings.Rationale';

type Props = {
  item: ExclusiveOptionsQuestion.Question;
};

type DropDownItem = {
  id: ExclusiveOptionsQuestion.SectionType;
  text: string;
};

const minItemsPerSection = 1;

const dropdownItems: DropDownItem[] = Object.entries(SectionTypeDisplayName).map(([k, v]) => ({ id: k as ExclusiveOptionsQuestion.SectionType, text: v }));

export const ExclusiveOptionsQuestionBuilder = ({ item }: Props) => {
  const [state, dispatch] = useSurveyBuilderState();

  const addSection = useCallback(() => {
    dispatch({
      type: 'exclusive-options-add-section',
      questionIdentifier: item.base.identifier,
    });
  }, [dispatch, item]);

  const deleteSection = useCallback((sectionId: string) => {
    dispatch({
      type: 'exclusive-options-delete-section',
      questionIdentifier: item.base.identifier,
      sectionId,
    });
  }, [dispatch, item]);

  const updateSection = useCallback((sectionId: string, prop: keyof ExclusiveOptionsQuestion.OptionSection) => {
    return (val: string) => {
      const sections = item.settings.optionSections.map(os => {
        if (os.identifier == sectionId) {
          return {
            ...os,
            [prop]: val,
          };
        } else {
          return os;
        }
      });

      dispatch({
        type: 'update-question-settings',
        settings: {
          ...item.settings,
          optionSections: sections,
        },
        questionIdentifier: item.base.identifier,
      });
    };
  }, [dispatch, item]);

  const getSectionTextEntries = useCallback((sectionId: string) => {
    return item.options.filter(o => o.metadata.sectionId === sectionId).map(o => ({
      value: parseSurveyRichText(o.value, false),
      key: o.base.identifier,
    }));
  }, [item.options]);

  const removeItem = useCallback((key: string) => {
    return () => {
      dispatch({
        type: 'remove-question-option',
        option: { identifier: key },
        questionIdentifier: item.base.identifier,
      });
    };
  }, [item, dispatch]);

  const updateItemValue = useCallback((key: string) => {
    return (val: string) => {
      dispatch({
        type: 'update-question-option-value',
        option: { identifier: key },
        questionIdentifier: item.base.identifier,
        value: convertToSurveyRichText(val),
      });
    };
  }, [dispatch, item]);

  const addOption = useCallback((sectionId: string) => {
    dispatch({
      type: 'exclusive-options-add-option',
      questionIdentifier: item.base.identifier,
      sectionId,
    });
  }, [dispatch, item]);

  const toggleDisplayGroupTitle = useCallback(() => {
    dispatch({
      type: 'update-question-settings',
      settings: {
        ...item.settings,
        displayGroupTitles: !item.settings.displayGroupTitles,
      },
      questionIdentifier: item.base.identifier,
    });
  }, [dispatch, item.settings, item.base.identifier]);

  return (
    <>
      {item.settings.optionSections?.map(s => (
        <div key={s.identifier}>
          <div className={styles.header}>
            <Input
              className={styles.sectionTitleInput}
              value={s.value}
              placeholder='Enter an option group title'
              onChange={e => updateSection(s.identifier, 'value')(e.target.value)} />
            <div className={styles.dropdown}>
              <DropDown
                items={dropdownItems}
                getItemValue={v => v.text}
                text={dropdownItems.find(i => i.id == s.type)?.text}
                onSelect={v => updateSection(s.identifier, 'type')(v.id)} />
            </div>
            <Trash
              size={20}
              className={styles.trashIcon}
              onClick={() => deleteSection(s.identifier)} />
          </div>
          <div className={styles.optionList}>
            <TextEntryList
              updateItemValue={updateItemValue}
              removeItemEnabled={getSectionTextEntries(s.identifier).length > minItemsPerSection}
              removeItem={removeItem}
              placeholder='Enter an answer choice'
              items={getSectionTextEntries(s.identifier)} />

            <AddItemButton
              className={styles.add}
              label="Add"
              onClick={() => addOption(s.identifier)} />
          </div>
        </div>
      ))}
      <AddItemButton
        className={styles.add}
        label="Add Option Group"
        onClick={addSection} />
      <div>
        <RationaleCheckbox className={styles.checkbox} />
      </div>
      <CheckboxLabel
        className={styles.checkbox}
        checked={item.settings.displayGroupTitles}
        label="Display group titles"
        onChange={toggleDisplayGroupTitle} />
    </>
  );
};