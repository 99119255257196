import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as $api from '@api/projects.conference';
import { GetProjectConferenceTagsOverview } from '$admin/api/interfaces/projects';
import * as $admin from '$admin/api/projects.conference-tags';

type Data = GetProjectConferenceTagsOverview.Response;
type Params = IProjectId;
type QueryKey = readonly [string, Params];

export function useProjectConferenceTagsOverview(
  data: Params,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  const isAdminPage = window.location.pathname.startsWith('/admin');

  const queryKey = isAdminPage
    ? AdminProjectConferenceTagsOverviewKey
    : ProjectConferenceTagsOverviewKey;

  return useQuery([queryKey, data], ({ queryKey }) => {
    const { projectId } = queryKey[1];

    return isAdminPage
      ? $admin.getProjectConferenceTagsOverview({ projectId })
      : $api.getTagsOverview({ projectId });

  }, options);
}

const AdminProjectConferenceTagsOverviewKey = `get:admin/projects/conference-tags/overview`;
const ProjectConferenceTagsOverviewKey = `get:projects/conference-tags/overview`;

export const isAdminPage = () => {
  const { pathname } = window.location;

  return pathname.startsWith('/admin');
};