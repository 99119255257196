import { useCallback } from 'react';
import { useSelectUser } from '@containers/Store';
import { Back } from '@presentation/Back';
import { OnboardingPayee } from '@/components/Tipalti';
import { SetupProps } from './interfaces';
import styles from './style/Setup.css';

export function Setup({ navigation, nextLabel }: SetupProps) {
  const user = useSelectUser();

  const handleComplete = useCallback(() => {
    navigation.next();
  }, [navigation]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{`Connect a payout account to be compensated.`}</h1>
        <div className={styles.copy}>{`Please complete the onboarding steps to connect a payout account.`}</div>
        <OnboardingPayee
          userId={user.id}
          onComplete={handleComplete} />
        <div className={styles.navigation}>
          {navigation.back
            ? <Back onClick={navigation.back} />
            : <div />}
        </div>
      </div>
    </div>
  );
}

Setup.defaultProps = {
  nextLabel: 'Next',
};