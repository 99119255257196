import { useMemo } from 'react';
import { ProjectType, SurveyActionType, SurveyItemType, SurveyType, utils as enumUtils } from '@enums';
import { SurveyBuilderVersion } from '@/types';
import {
  SurveyBuilderState,
  InitialValue as SurveyBuilderStateInitialValue,
} from './SurveyBuilderState';
import { SurveyBuilderOptionsContext, SurveyBuilderOptionsContextValue } from './Context';
import { SubmitSurveyDraftContainer } from './SubmitSurveyDraftContainer';
import { RichTextEditorRegistryContainer } from './RichTextEditorRegistryContainer';
import { SurveyBuilder } from './interfaces';

type Props = {
  initialValue?: SurveyBuilderVersion;
  excludeSurveyTypes?: SurveyType[];
  projectType: ProjectType;
} & ChildrenProps;

const actionTypes = [
  SurveyActionType.None,
  SurveyActionType.Disqualification,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToQuestion,
  SurveyActionType.SkipToSection,
  SurveyActionType.IncludeSection,
];

const itemTypes = [SurveyItemType.Question, SurveyItemType.Message, SurveyItemType.AlternateImageExercise];

const VALID_SURVEY_TYPES = [SurveyType.Project, SurveyType.ConferenceQuant];

export const ProjectSurveyOptionsContainer = (props: Props) => {

  const defaultSurveyDetails = useMemo(() => {
    if (props.projectType === ProjectType.Survey) {
      return {
        typeId: SurveyType.Project,
        name: 'Survey',
      } as SurveyBuilder.State['survey']['surveyDetails'];
    } else {
      //TODO: set this based on the available survey types
      return {};
    }
  }, [props.projectType]);

  const options = useMemo(() => ({
    actionTypes,
    itemTypes,
    maxQuestions: 500,
    questionTypes: enumUtils.SurveyQuestionType.values(),
    showDetails: props.projectType !== ProjectType.Survey,
    defaultSurveyDetails,
    surveyTypes: props.excludeSurveyTypes ? VALID_SURVEY_TYPES.filter(t => !props.excludeSurveyTypes.includes(t)) : VALID_SURVEY_TYPES,
  } as SurveyBuilderOptionsContextValue), [props.projectType, props.excludeSurveyTypes, defaultSurveyDetails]);

  return (
    <SurveyBuilderOptionsContext.Provider value={options}>
      {props.children}
    </SurveyBuilderOptionsContext.Provider>
  );
};
