import { useCallback } from 'react';
import type { IConference } from '@containers/Conference';
import { useConferenceInstance, useCoordinator, useTwilioVideo } from '@containers/Conference';
import { ButtonActivityIndicator } from '@presentation';
import type { SelectOptionProps } from '@/components/Select/interfaces';
import Toast from '@/components/Toast';
import { NetworkTest } from '@/components/Conference.Entry/NetworkTest';
import { Tabs } from './Tabs';
import * as utils from './utils';
import { useCheckDevice } from './hooks';
import styles from './style/Video.css';

export function Video() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();
  const coordinator = useCoordinator();
  const { getAVPermission, changeAudioSource, changeVideoSource, audioSource, videoSource } = useTwilioVideo();

  const handleCamera = useCallback((enabled: boolean) => {
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        camera: enabled,
      },
    });
  }, [instance.conferenceIdentifier, coordinator]);

  const handleMicrophone = useCallback((enabled: boolean) => {
    if (enabled) {
      getAVPermission(true, false)
        .then(result => {
          if (!result) {
            coordinator.updatePreRoom({
              conferenceIdentifier: instance.conferenceIdentifier,
              settings: {
                microphone: false,
              },
            });
            Toast.error({
              title: `Failed to get browser permission to use your microphone!`,
            });
          }
        });
    }
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        microphone: enabled,
      },
    });
  }, [getAVPermission, instance.conferenceIdentifier, coordinator]);

  const handleVisibility = useCallback((value: SelectOptionProps) => {
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        visibility: utils.visibilityToBool(value.id),
      },
    });
  }, [instance.conferenceIdentifier, coordinator]);

  const handleJoin = useCallback(() => {
    coordinator.join({
      conferenceIdentifier: instance.conferenceIdentifier,
      visibility: instance.settings.visibility,
    });
  }, [instance.conferenceIdentifier, instance.settings.visibility, coordinator]);

  const [joinConference, DeviceAlert] = useCheckDevice({
    onConfirm: handleJoin,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>
            {instance.conference.title} Call
          </div>
          <div className={styles.date}>
            {utils.formatCallTime(instance.call.start, instance.call.end)}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.cameraToggle || instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `Join the conference with the settings below.`
              : `The settings below have been set for you by the Host.`}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.cameraToggle || instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `You'll also be able to change these after joining.`
              : null}
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
            cameraVisible={instance.features.cameraToggle}
            cameraValue={instance.settings.camera}
            cameraSource={videoSource?.deviceId}
            disabledCamera={!instance.features.cameraToggle}
            disabledMicrophone={!instance.features.microphoneToggle}
            microphoneValue={instance.settings.microphone}
            microphoneSource={audioSource?.deviceId}
            disabledVisibility={!instance.features.visibilityToggle}
            visibilityValue={utils.boolToVisibility(instance.settings.visibility)}
            phoneInformation={instance.dial}
            visibilityVisible={instance.features.showVisibility}
            showCameraPreview={instance.settings.camera}
            disabledNametag={!instance.features.editNametag}
            onToggleCamera={handleCamera}
            onChangeCameraSource={changeVideoSource}
            toggleMicrophone={handleMicrophone}
            changeMicrophoneSource={changeAudioSource}
            onSelectVisibility={handleVisibility} />
        </div>
        <div className={styles.actions}>
          <ButtonActivityIndicator
            className={styles.join}
            loading={instance.joiningMeeting}
            variant="brick"
            onClick={joinConference}>
            Join Conference
          </ButtonActivityIndicator>
        </div>
        <NetworkTest />
      </div>
      <DeviceAlert />
    </div>
  );
}