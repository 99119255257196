export const Access = '/access';

export const Account = '/account';

export const Admin = '/admin';

export const BrandInsights = '/brand-insights';

export const Calls = '/calls';

export const Companies = '/companies';

export const Compliance = '/compliance';

export const Contacts = '/contacts';

export const Discovery = '/discovery';

export const Home = '/home';

export const Inbound = '/_';

export const Invite = '/invite';

export const Onboarding = '/onboarding';

export const Signup = '/signup';

export const Terms = '/terms';
export const Terms2 = '/terms-of-service';

export const Welcome = '/welcome';

export const CALENDAR = '/calendar';

export const CONFERENCE = '/conference';

export const EMBEDDED = '/embedded';

export const MESSAGES = '/messages';

export const NOTIFICATIONS = '/notifications';

export const FORGOT_PASSWORD = '/forgot-password';
export const VERIFY_EMAIL = '/verify-email';

export const PROJECT_INVITE = '/invite';
export const PROJECT_NOTES = '/project-notes';

export const PROJECTS = '/projects';

export const RESEARCH = '/research';

export const USER_PROFILE = '/profile';
export const Settings = '/settings';

export const LANDING = '/get-started';
export const BATCHELOR = '/batchelor';
export const JOIN_NOW = '/join-now';

export const SCHEDULING = '/scheduling';

export const SEARCH = '/search';

export const SELFSERVESURVEY = '/self-serve-survey';

export const SUMMARY = '/summary';

export const SURVEYS = '/surveys';

export const HOME = Home;

export const REFER = '/refer';

export const TRANSCRIPT = '/transcript';

export const DebugThrowError = '/debug-throw-error';
export const DebugThrowErrorBackend = '/debug-throw-error-backend';

export const Topics = '/topics';

export const UniversalSearch = '/universal-search';

export const Unsubscribe = '/unsubscribe';

export const WORKSPACES = '/workspaces';

export const POSTS = '/posts';

export const EXTERNALPROJECT = '/external';
export const ExternalSourcing = '/ext-src';

export const PrivacyPolicy = '/privacy-policy';
export const PrivacyPolicy2 = '/privacy';