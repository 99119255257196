import { SurveyBuilder, SurveyTaggingBuilder } from '../interfaces';
import * as $actions from './state.tagging';

export function taggingReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyTaggingBuilder.State {

  const existingTagging = state.survey.tagging;

  switch (action.type) {

    case 'tagging-item-added':
      return $actions.taggingItemAdded(existingTagging, action);

    case 'tagging-item-removed':
      return $actions.taggingItemRemoved(existingTagging, action);

    case 'tagging-item-saved':
      return $actions.taggingItemSaved(existingTagging, action);

    case 'tagging-editing-toggled-off':
      return $actions.taggingEditingToggledOff(state, action);

    case 'tagging-editing-toggled-on':
      return $actions.taggingEditingToggledOn(state, action);

    case 'revert-uncommitted-changes':
      return state.committed.tagging;

    case 'remove-question-row':
      return $actions.rowRemoved(existingTagging, action);

    case 'remove-question-option':
      return $actions.optionRemoved(existingTagging, action);

    case 'question-removed':
      return $actions.questionRemoved(existingTagging, action);

    case 'remove-section':
      return $actions.sectionRemoved(state, action);

    case 'update-question-identifier':
      return $actions.questionIdentifierUpdated(existingTagging, action);

    case 'update-question-type':
      return $actions.questionTypeUpdated(existingTagging, action);

    default:
      return existingTagging;
  }

}