import http from '@services/http';
import * as Interfaces from './interfaces/conference-cues';

export const getConferenceCues = async () => {
  return http.get<Interfaces.FetchConferenceCues.Response>(`/admin/conference-cues`);
};

export const previewConferenceCue = async (data: Interfaces.PreviewConferenceCue.Request): Promise<Interfaces.PreviewConferenceCue.Response> => {
  return http.post(`/admin/conference-cues/preview`, data);
};

export const updateConferenceCue = async (data: Interfaces.UpdateConferenceCue.Request) => {
  return http.post(`/admin/conference-cues`, data);
};