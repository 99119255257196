import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type { Projects } from '@api/interfaces/projects';
import * as enums from '@enums';
import ActiveProjectsPanel from '@screens/GroupMain/ActiveProjectsPanel';
import { WorkspacesNavMenu } from '@/components/Workspace/WorkspaceNav';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { useIsInternalUser } from '@/containers/Store';
import AgendaCard from './AgendaCard';
import { ScreenLayout } from './Layout';
import styles from './style/Main.css';
import { ActiveBrandsPanel } from './ActiveBrandsPanel';
import { useRegisterGlobalChatContext, useBrandsQuery } from './hooks';

const mapState = (state: Store.State) => state.group.features;

const ComplianceMain = () => {
  const groupFeatures = useSelector(mapState);

  useRegisterGlobalChatContext();

  const isInternalUser = useIsInternalUser();

  const projectStatuses = useMemo(() => {
    const statuses = [enums.ProjectStatus.Active];

    if (!isInternalUser) statuses.push(enums.ProjectStatus.Archived);

    return statuses;
  }, [isInternalUser]);

  const query = useQuery({
    queryKey: [
      `get:projects`, {
        index: 0,
        size: 5,
        status: projectStatuses,
      },
    ],
    queryFn: () => {
      return api.projects.fetchProjects({
        index: 0,
        size: 5,
        status: projectStatuses,
      });
    },
    refetchOnWindowFocus: false,
  });

  const brandsQuery = useBrandsQuery();

  const loading = query.isInitialLoading && !query.data;

  const data = loading
    ? getLazyTableData(4)
    : query.data?.items ?? [];

  const empty = query.isFetchedAfterMount && !loading && !data.length;

  const hideReviewPanel = useMemo(() => {
    return groupFeatures.callReviewStatus === enums.ReviewOption.Disabled
      && groupFeatures.surveyReviewStatus === enums.ReviewOption.Disabled
      && groupFeatures.messagesReviewStatus === enums.ReviewOption.Disabled;
  }, [
    groupFeatures,
  ]);

  return (
    <ScreenLayout>

      <div className={styles.col}>
        {groupFeatures.workspaces && <WorkspacesNavMenu className={styles.workspaces} />}

        <ActiveBrandsPanel query={brandsQuery} />

        {!hideReviewPanel &&
          <ActiveProjectsPanel
            data={data}
            empty={empty}
            loading={loading} />}
      </div>

      <div className={styles.col}>
        <div className={styles.sidebar}>
          <AgendaCard />
        </div>
      </div>

    </ScreenLayout>
  );
};

const ComplianceMainContainer = () => {
  return (
    <>
      <Helmet title="Home" />
      <ComplianceMain />
    </>
  );
};

export { ComplianceMainContainer };
export default ComplianceMainContainer;

const getLazyTableData = <T extends Projects.FetchProjects.ProjectItem>(pageSize = 25) => {
  return Array.from({ length: pageSize }, _ => ({} as T));
};