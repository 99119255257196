import { useCallback, useContext } from 'react';
import { MedicalBackgroundContext, MedicalBackgroundIdentificationContext } from '@containers/MedicalOnboarding/Context';
import * as Field from '@/components/MedicalProfile/Field';

type Props = {
  className?: string;
};

export const Country = (props: Props) => {
  const ctx = useContext(MedicalBackgroundContext);
  const identification = useContext(MedicalBackgroundIdentificationContext);

  const handleChange = useCallback((value: string) => {
    ctx.mutation.mutate({
      identification: {
        ...identification.form,
        country: value,
      },
    }, {
      onSuccess: identification.rehydrate,
    });

    identification.setValue({
      country: value,
    });
  }, [
    ctx.mutation,
    identification,
  ]);

  return (
    <Field.Country
      onChange={item => handleChange(item.value)}
      value={identification.form.country} />
  );
};

Country.displayName = 'Member.Experience.Input.Country';