import { useCallback, useState } from 'react';
import { useDevice } from '@utils';
import { ConferenceDialInfo } from '@/types';
import { Tab } from '@/components/Conference.Entry';
import { SelectOptionProps } from '@/components/Select/interfaces';
import { TabView, TabViewRenderParams } from '@/components/TabView';
import { TabKey, TabRoute } from './interfaces';

type Props = {
  cameraVisible?: boolean;
  cameraValue?: boolean;
  cameraSource?: string;
  disabledCamera?: boolean;
  microphoneValue?: boolean;
  microphoneSource?: string;
  disabledMicrophone?: boolean;
  disabledVisibility?: boolean;
  visibilityValue?: number;
  visibilityVisible?: boolean;
  showCameraPreview?: boolean;
  disabledNametag: boolean;
  phoneInformation: ConferenceDialInfo;
  onToggleCamera?: (enabled: boolean) => void;
  onChangeCameraSource?: (device: MediaDeviceInfo) => void;
  toggleMicrophone: (muted: boolean) => void;
  changeMicrophoneSource: (device: MediaDeviceInfo) => void;
  onSelectVisibility: (value: SelectOptionProps) => void;
};

export const Tabs = (props: Props) => {
  const device = useDevice();
  const [index, setIndex] = useState<number>(0);
  const routes = getTabViewRoutes(device.phone, props.phoneInformation);

  const handleIndexChange = useCallback((i: number) => {
    setIndex(i);
  }, []);

  const View = (params: TabViewRenderParams) => {
    switch (params.route.key) {
      case TabKeys.dial:
        return <Tab.Dial
                  visibilityValue={props.visibilityValue}
                  disabledVisibility={props.disabledVisibility}
                  phoneInformation={props.phoneInformation}
                  visibilityVisible={props.visibilityVisible}
                  onSelectVisibility={props.onSelectVisibility} />;
      case TabKeys.desktop:
      default:
        return <Tab.Desktop
                  cameraVisible={props.cameraVisible}
                  cameraValue={props.cameraValue}
                  cameraSource={props.cameraSource}
                  disabledCamera={props.disabledCamera}
                  microphoneValue={props.microphoneValue}
                  microphoneSource={props.microphoneSource}
                  disabledMicrophone={props.disabledMicrophone}
                  visibilityValue={props.visibilityValue}
                  disabledVisibility={props.disabledVisibility}
                  visibilityVisible={props.visibilityVisible}
                  showCameraPreview={props.showCameraPreview}
                  disabledNametag={props.disabledNametag}
                  onToggleCamera={props.onToggleCamera}
                  onChangeCameraSource={props.onChangeCameraSource}
                  toggleMicrophone={props.toggleMicrophone}
                  onChangeMicrophoneSource={props.changeMicrophoneSource}
                  onSelectVisibility={props.onSelectVisibility} />;
    }
  };

  return (
    <TabView
      index={index}
      onIndexChange={handleIndexChange}
      routes={routes}
      renderTabView={View} />
  );
};

const TabKeys: TabKey = {
  desktop: 'desktop',
  dial: 'dial',
};

const getTabViewRoutes = (isMobile: boolean, phoneInformation: ConferenceDialInfo): TabRoute[] => {

  const showDesktopTitle = true;
  const showDialTitle = phoneInformation.phoneNumbers.length > 0;

  return [
    showDesktopTitle
      ? {
        title: <Tab.DesktopTitle />,
        key: TabKeys.desktop,
      }
      : null,
    showDialTitle
      ? {
        title: <Tab.DialTitle />,
        key: TabKeys.dial,
      }
      : null,
  ].filter(Boolean);
};

export default Tabs;