import { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import type * as API from '@api/interfaces';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { Spinner } from '@/components/ActivityIndicator';
import { useUserVerificationContextMenu } from '@/components/ObjectAccess/hooks';
import { PopperMenu, PopperMenuItem, useZIndexModifier } from '@/components/Popper';
import { Portal } from '@/components/Portal';
import type { Entry } from './interfaces';
import styles from './style/ProjectAccess.css';

type Props = {
  busy?: boolean;
  item: Entry;
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

export const ProjectAccessContextMenu = (props: Props) => {
  const userVerificationContextMenu = useUserVerificationContextMenu({
    projectId: props.projectId,
    onBeforeCreateClientUser: props.onBeforeCreateClientUser,
    onSuccessCreateClientUser: props.onSuccessCreateClientUser,
  });
  const menu = userVerificationContextMenu(props.item);

  if (!menu.visible) return null;

  if (props.busy) {
    return (
      <Spinner
        color="#979797"
        size={18} />
    );
  }

  return (
    <ContextMenu
      onClickCreateClientUser={menu.onClickCreateClientUser}
      onClickSendInviteEmail={menu.onClickSendInviteEmail} />
  );
};

type HigherOrderParams = {
  activeClientUserCreationEmail?: string;
  onBeforeCreateClientUser?: (params: { email: string }) => void;
  onSuccessCreateClientUser?: (res: API.Users.RegisterClientUsers.Response) => void;
} & IProjectId;

export const useProjectAccessContextMenu = (params: HigherOrderParams) => {
  const ProjectAccessContextMenuWrapper = useMemo(() => {
    return (props: Pick<Props, 'item'>) => {
      return (
        <ProjectAccessContextMenu
          busy={params.activeClientUserCreationEmail === props.item.email}
          onBeforeCreateClientUser={params.onBeforeCreateClientUser}
          onSuccessCreateClientUser={params.onSuccessCreateClientUser}
          projectId={params.projectId}
          {...props} />
      );
    };
  }, [params]);

  return ProjectAccessContextMenuWrapper;
};

type ContextMenuProps = {
  onClickCreateClientUser?: () => void;
  onClickSendInviteEmail?: () => void;
};

const ContextMenu = ({ onClickCreateClientUser, onClickSendInviteEmail }: ContextMenuProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const wrapClick = useCallback((handler: (() => void)) => {
    if (typeof handler === 'function') {
      return () => {
        handler();
        setOpen(false);
      };
    }
  }, []);

  const items = useMemo(() => {
    return [
      {
        children: 'Send Verification Email',
        onClick: wrapClick(onClickSendInviteEmail),
      },
      {
        children: 'Create New User Account',
        onClick: wrapClick(onClickCreateClientUser),
      },
    ].filter(x => !!x.onClick);
  }, [
    onClickCreateClientUser,
    onClickSendInviteEmail,
    wrapClick,
  ]);

  return (
    <>
      <div
        ref={setReferenceElement}
        onClick={() => setOpen(true)}>
        <MoreHorizontalAnchor
          className={styles.anchor}
          open={open} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            onClickAway={() => setOpen(false)}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...popperAttributes.popper}>
              <PopperMenu>
                {items.map((m, i) => (
                  <PopperMenuItem
                    key={i}
                    onClick={m.onClick}>
                    {m.children}
                  </PopperMenuItem>
                ))}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>}
    </>
  );
};