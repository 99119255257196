import { Fragment, useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { AddSurveyItemButton, AddSurveyItemDivider } from '@presentation/SurveyBuilder';
import { SurveySection as Section } from '@presentation/Survey';
import { SurveyQuestionContainer } from '@containers/SurveyBuilder.Question/QuestionContainer';
import type { SurveyItem } from '@/types/survey';
import { SurveyItemType } from '@/enums/Survey';
import { SurveyBuilderItemContainer, useAddSurveyAIE, useAddSurveyMessage, useAddSurveyQuestion, useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { SurveyBuilderMessageContainer } from '@/containers/SurveyBuilder.Message';
import { SurveyBuilderAlternateImagesContainer } from '@/containers/SurveyBuilder.AIE';
import { useSurveySectionBuilderContext } from '@/containers/SurveyBuilder.Section/context';
import type { RenderSurveyItemPopperProps } from '@/components/SurveyBuilder/AddItemPopper';
import { AddSurveyItemPopper } from '@/components/SurveyBuilder/AddItemPopper';
import { QuestionBuilderItem } from '@/components/SurveyBuilder.Question';
import { MessageBuilder } from '@/components/SurveyBuilder.Message';
import { AIEBuilder } from '@/components/SurveyBuilder.AIE';
import SurveySectionHeader from './Header';
import styles from './style/SectionBuilder.css';

export const SurveySectionBuilder = () => {

  const ctx = useSurveySectionBuilderContext();
  const [_, dispatch] = useSurveyBuilderState();

  const renderItem = useCallback((item: SurveyItem) => {

    if (item.type === SurveyItemType.Question) {
      return (
        <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
          <SurveyQuestionContainer>
            <QuestionBuilderItem />
          </SurveyQuestionContainer>
        </SurveyBuilderItemContainer>
      );
    } else if (item.type === SurveyItemType.Message) {
      return (
        <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
          <SurveyBuilderMessageContainer>
            <MessageBuilder />
          </SurveyBuilderMessageContainer>
        </SurveyBuilderItemContainer>
      );
    } else if (item.type === SurveyItemType.AlternateImageExercise) {
      return (
        <SurveyBuilderItemContainer itemIdentifier={item.identifier}>
          <SurveyBuilderAlternateImagesContainer>
            <AIEBuilder />
          </SurveyBuilderAlternateImagesContainer>
        </SurveyBuilderItemContainer>
      );
    }
  }, []);

  const addQuestion = useAddSurveyQuestion(ctx.section.identifier);
  const addMessage = useAddSurveyMessage(ctx.section.identifier);
  const addAIE = useAddSurveyAIE(ctx.section.identifier);

  const sortedItems = useMemo(() => {
    return [...ctx.items].sort((a, b) => a.ordinal - b.ordinal);
  }, [ctx.items]);

  const handleSectionBreak = useCallback((cutoffOrdinal: number) => () => {
    const newSectionItems = sortedItems.filter(f => f.ordinal > cutoffOrdinal);
    dispatch({
      type: 'section-break-added',
      payload: {
        items: newSectionItems,
        identifier: ctx.section.identifier,
        newIdentifier: cuid(),
      },
    });
  }, [dispatch, ctx.section.identifier, sortedItems]);

  const addItemProps = useCallback((ordinal: number) => {
    return {
      canAddAIE: ctx.canAddAIE,
      canAddMessage: ctx.canAddMessage,
      canAddQuestion: ctx.canAddQuestion,
      onAddAIE: addAIE(ordinal),
      onAddQuestion: addQuestion(ordinal),
      onAddMessage: addMessage(ordinal),
    };
  }, [
    addAIE,
    addMessage,
    addQuestion,
    ctx.canAddAIE,
    ctx.canAddMessage,
    ctx.canAddQuestion,
  ]);

  const renderItems = useCallback(() => {

    return (
      <>
        {sortedItems
          .map((item, i) =>
            <Fragment key={item.identifier}>
              {renderItem(item)}
              {i !== ctx.items.length - 1 &&
                <AddSurveyItemPopper {...addItemProps(item.ordinal + 1)} onBreakSection={handleSectionBreak(item.ordinal)}>
                  {(popperProps: RenderSurveyItemPopperProps, ref: React.Ref<HTMLDivElement>) => (
                    <AddSurveyItemDivider
                      ref={ref}
                      isOpen={popperProps.popperOpen}
                      onClick={popperProps.onClick}
                      disabled={popperProps.disabled} />
                  )}
                </AddSurveyItemPopper>
              }
            </Fragment>
          )
        }
      </>
    );
  }, [
    addItemProps,
    ctx.items.length,
    handleSectionBreak,
    renderItem,
    sortedItems,
  ]);

  return (
    <>
      <Section>
        <SurveySectionHeader
          className={styles.header}
          item={ctx.section} />
        <AddSurveyItemPopper {...addItemProps(ctx.items[0]?.ordinal)}>
          {(popperProps: RenderSurveyItemPopperProps, ref: React.Ref<HTMLDivElement>) => (
            <AddSurveyItemDivider
              ref={ref}
              isOpen={popperProps.popperOpen}
              onClick={popperProps.onClick}
              lineClassName={styles.firstDivider}
              disabled={popperProps.disabled || !ctx.items.length} />
          )}
        </AddSurveyItemPopper>
        {renderItems()}
        {(ctx.canAddQuestion || ctx.canAddMessage) &&
          <div className={styles.addQuestion}>
            <AddSurveyItemPopper
              sameWidth={true}
              {...addItemProps(ctx.lastOrdinal + 1)}>
              {(props: RenderSurveyItemPopperProps, ref: React.Ref<HTMLDivElement>) => (
                <AddSurveyItemButton
                  ref={ref}
                  onClick={props.onClick} />
              )}
            </AddSurveyItemPopper>
          </div>
        }
      </Section>
    </>
  );
};

export default SurveySectionBuilder;