import { ProjectType, SurveyType } from '@enums';
import { SurveyBuilderVersion } from '@/types';
import {
  SurveyBuilderState,
} from './SurveyBuilderState';
import { SubmitSurveyDraftContainer } from './SubmitSurveyDraftContainer';
import { ProjectSurveyOptionsContainer } from './ProjectSurveyOptionsContainer';
import { RichTextEditorRegistryContainer } from './RichTextEditorRegistryContainer';

type Props = {
  initialValue?: SurveyBuilderVersion;
  excludeSurveyTypes?: SurveyType[];
  projectType: ProjectType;
} & ChildrenProps;

export const ProjectSurveyBuilderState = (props: Props) => {
  return (
    <ProjectSurveyOptionsContainer {...props}>
      <RichTextEditorRegistryContainer>
        <SurveyBuilderState initialValue={props.initialValue}>
          <SubmitSurveyDraftContainer>
            {props.children}
          </SubmitSurveyDraftContainer>
        </SurveyBuilderState>
      </RichTextEditorRegistryContainer>
    </ProjectSurveyOptionsContainer>
  );
};

export default ProjectSurveyBuilderState;
