import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import type { AxiosError } from 'axios';
import styled from '@emotion/styled';
import * as actions from '@actions';
import * as consts from '@consts';
import * as $oauth2 from '@services/auth/oauth2';
import { qs } from '@utils';
import { Logo } from '@/components/Branding';
import { Spinner } from '@/components/ActivityIndicator';
import styles from './style/Inbound.css';

type Search = {
  code?: string;
  state: string;
  error?: string;
  error_description?: string;
};

type Params = {
  identifier: string;
};

type PageError = {
  error: string;
  description: string;
};

export function OAuth2() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { identifier } = useParams<Params>();
  const [pageError, setPageError] = useState<PageError>();

  useEffect(() => {
    const search = qs.parse<Search>(location.search);

    if (search.code && search.state) {
      $oauth2.callback({
        identifier,
        code: search.code,
        state: search.state,
      })
      .then(data => {
        if (data.type === 'redirect') {
          dispatch(actions.userAuthenticationChange());
          history.replace(data.returnUrl ? data.returnUrl : consts.pathname.Home);
        }
      })
      .catch(err => {
        const error = err as AxiosError;
        const data = error?.response?.data as Record<string, string>;
        setPageError({
          error: error?.code,
          description: data?.message || error?.message,
        });
      });
    }

  }, [dispatch, history, identifier, location.search]);

  useEffect(() => {
    const search = qs.parse<Search>(location.search);
    if (search.error) {
      setPageError({
        error: search.error,
        description: search.error_description,
      });
    }
  }, [location.search]);

  return (
    <div className={styles.root}>
      <Root>
        <Logo.Landscape />
        <Body>
          {pageError
            ? (
              <ErrorWrapper>
                <ErrorHeader>{`We're sorry, something went wrong.`}</ErrorHeader>
                <ErrorLabel>Code:</ErrorLabel>
                <ErrorLine>{pageError.error || 'N/A'}</ErrorLine>
                <ErrorLabel>Description:</ErrorLabel>
                <ErrorLine>{pageError.description || 'N/A'}</ErrorLine>
              </ErrorWrapper>
            )
            : <Spinner />}
        </Body>
      </Root>
    </div>
  );
}

const Root = styled.div({
  borderRadius: '10px',
  backgroundColor: 'var(--gray-xxl)',
  padding: '32px',
  minWidth: '400px',
  maxWidth: '800px',
});

const Body = styled.div({
  marginTop: '20px',
});

const ErrorWrapper = styled.div({
  color: 'var(--black)',
  backgroundColor: 'var(--reddish-white)',
  borderRadius: '15px',
  padding: '32px',
  fontSize: '16px',
});

const ErrorHeader = styled.h1({
  fontSize: '20px',
  fontFamily: 'var(--font-bold)',
  lineHeight: '28px',
  margin: '0 0 30px 0',
});

const ErrorLabel = styled.div({
  fontSize: '16px',
  fontFamily: 'var(--font-semibold)',
  margin: '0 0 4px 0',
});

const ErrorLine = styled.div({
  fontSize: '16px',
  lineHeight: '18px',

  ':not(:last-child)': {
    margin: '0 0 20px 0',
  },
});