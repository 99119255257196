import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { Column, CellProps } from 'react-table';
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table';
import type { UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';
import type { GetBrands } from '@api/interfaces/workspace.brand';
import { Brand } from '@/components/icons';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { useIsInternalUser } from '@/containers/Store';
import * as Table from '@/components/Table';
import { getLocationFor } from '@/utils';
import styles from './style/ActiveProjectsPanel.css';

const MaxProjects = 5;

function EmptyState() {
  return (
    <div className={styles.empty}>
      <div className={styles.title}>No active brands</div>
      {/*<div className={styles.text}>Create a project to get started</div>*/}
    </div>
  );
}

type Props = {
  query: UseQueryResult<GetBrands.Response>;
};

export const ActiveBrandsPanel = (props: Props) => {
  const isInternalUser = useIsInternalUser();

  const columns = useMemo<Column<GetBrands.BrandItem>[]>(() => [
    {
      id: 'displayName',
      Cell: (x: CellProps<GetBrands.BrandItem>) => (
        <div className={styles.nameCell}>
          <Brand className={styles.brandIcon} />
          <Link to={getLocationFor.brand.root({ brandId: x.cell.row.original.id })}>
            {x.cell.row.original.displayName}
          </Link>
        </div>
      ),
      Header: () => 'Name',
      minWidth: 200,
    }, {
      id: 'progress',
      Cell: () => <></>,
      width: 105,
    }, isInternalUser ? {
      id: 'owner',
      Cell: (x: CellProps<GetBrands.BrandItem>) => x.cell.row.original.createdBy?.fullName,
      Header: () => 'Owner',
      width: 80,
    } : undefined, {
      id: 'createdOn',
      Cell: (x: CellProps<GetBrands.BrandItem>) => format(x.cell.row.original.createdOn, 'MM/dd/yyyy'),
      Header: () => 'Created On',
      minWidth: 80,
      width: 80,
    },
    {
      id: 'actions',
      Cell: () => <></>,
      minWidth: 50,
      width: 50,
      disableSortBy: true,
    },
  ].filter(Boolean), [isInternalUser]);

  const params = {
    columns,
    data: props.query.data?.brands,
    disableSortBy: true,
    initialState: {
      pageIndex: 0,
      pageSize: MaxProjects,
    },
  };

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<GetBrands.BrandItem>(
    params,
    useFlexLayout,
    usePagination,
  );

  //const showMore = useMemo(() => props.data.length > MaxProjects, [props.data.length]);

  return (
    <Card>
      <GroupCardHeader className={styles.header}>
        <div>Brands</div>
      </GroupCardHeader>
      <CardContent fluid>
        <Table.Root
          EmptyComponent={EmptyState}
          empty={props.query.data?.brands?.length === 0}
          loading={props.query.isLoading}>
          <Table.Header headerGroups={headerGroups} />
          <Table.Body
            {...getTableBodyProps()}
            classes={{
              tbody: styles.body,
              td: styles.td,
              tr: styles.tr,
            }}
            prepareRow={prepareRow}
            rows={rows} />
        </Table.Root>
      </CardContent>
    </Card>
  );
};

export default ActiveBrandsPanel;