import type * as enums from '@enums';
import type { Groups, Surveys } from '@services/api/interfaces';
import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import * as xform from '@transformers';
import { nullableDate, qs } from '@utils';
import type { Projects } from './interfaces';

export const fetchSurvey = async (data: Projects.FetchSurvey.Request): Promise<Projects.FetchSurvey.Response> => {
  return http.get(`/admin/projects/${data.projectId}/survey/${data.surveyId}`)
    .then((data: Projects.FetchSurvey.Response<string>) => ({
      ...data,
      survey: data.survey
        ? {
          ...data.survey,
          createdOn: nullableDate(data.survey.createdOn),
        }
        : null,
    }));
};

export const deleteSurvey = async (data: Projects.DeleteSurvey.Request): Promise<Projects.DeleteSurvey.Response> => {
  return http.delete(`/projects/${data.projectId}/survey/${data.surveyId}`);
};

export const fetchResponse = (data: Omit<Groups.Compliance.FetchSurveyResponseReview.Request, 'groupId'>) => {
  return http.get(`admin/projects/survey-responses/${data.responseId}`, { transformResponse: transformDates })
    .then((response: Groups.Compliance.FetchSurveyResponseReview.Response) => {
      return response.type === 'internal-user'
        ? {
          type: response.type,
          data: {
            ...response.data,
            user: xform.setUserSlug(response.data.user),
          },
        }
        : response;
    });
};

export const fetchPartialResponse = (data: Projects.FetchProjectSurveyPartialResponse.Request) => {
  return http.get(`admin/surveys/responses-partial/${data.surveyVersionId}/${data.userId}`, { transformResponse: transformDates })
    .then((response: Projects.FetchProjectSurveyPartialResponse.Response) => response);
};

export const fetchResponses = (data: Projects.FetchProjectSurveyResponses.Request): Promise<Projects.FetchProjectSurveyResponses.Response> => {
  const { projectId, surveyId, ...rest } = data;
  return http.get(`/admin/projects/${projectId}/${surveyId}/survey-responses?${qs.stringify(rest as Record<string, unknown>, { arrayFormat: 'bracket' })}`, { transformResponse: transformDates });
};

export const getReadyToPublishCounts = (data: Projects.GetReadyToPublishCounts.Request): Promise<Projects.GetReadyToPublishCounts.Response> => {
  return http.get(`/admin/projects/survey-responses/ready?${qs.stringify(data)}`);
};

export const publishResponses = (data: { projectId: number }) => {
  return http.post(`/admin/projects/${data.projectId}/survey-responses/publish`, {});
};

export const toggleAnswerVisibility = (data: Surveys.ToggleAnswerVisibility.Request): Promise<Surveys.ToggleAnswerVisibility.Response> => {
  const { responseId, questionId, ...rest } = data;
  return http.post(`/admin/surveys/responses/${data.responseId}/questions/${data.questionId}/visibility`, rest);
};

export const updateScreeningStatus = (data: { projectSurveyResponseId: number; screeningStatusId: enums.ApprovalStatus }) => {
  const { projectSurveyResponseId, ...rest } = data;
  return http.patch(`/admin/projects/survey-responses/${projectSurveyResponseId}`, rest);
};

export const updateSurvey = (data: Projects.UpdateSurvey.Request): Promise<Projects.UpdateSurvey.Response> => {
  return http.put(`/admin/projects/${data.projectId}/survey`, data)
    .then((data: Projects.UpdateSurvey.Response<string>) => ({
      ...data,
      survey: data.survey
        ? {
          ...data.survey,
          createdOn: nullableDate(data.survey.createdOn),
        }
        : null,
    }));
};

export const upsertSurvey = async (data: Projects.UpsertSurvey.Request): Promise<Projects.UpsertSurvey.Response> => {
  return http.put(`/admin/projects/${data.projectId}/survey/upsert`, data)
    .then((data: Projects.UpsertSurvey.Response<string>) => ({
      ...data,
      survey: data.survey
        ? {
          ...data.survey,
          createdOn: nullableDate(data.survey.createdOn),
        }
        : null,
    }));
};

// export const surveys = {
//   fetchUserResponse: fetchProjectSurveyResponse,
//   fetchResponses: fetchProjectSurveyResponses,
//   getReadyToPublishCounts,
//   publishResponses: publishSurveyResponses,
//   toggleAnswerVisibility: toggleSurveyResponseAnswerVisibility,
//   updateScreeningStatus: updateProjectSurveyResponseScreeningStatus,
// };