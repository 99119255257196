import Cancel from '@mui/icons-material/Cancel';
import InsertPhoto from '@mui/icons-material/InsertPhoto';
import { useRemoveLogoMutation, useUpdateLogoMutation } from '@containers/Branding/hooks';
import { MediaOrientation } from '@enums';
import { cx } from '@utils';
import { ImageCrop } from '@/components/ImageCrop';
import styles from './style/Settings.Branding.Logo.css';

type Props = {
  imageSrc?: string;
};

export const Image = (props: Props) => {
  const mutation = useRemoveLogoMutation();

  if (!props.imageSrc) {
    return (
      <div className={styles.landscape}>
        <div className={styles.clamp}>
          <InsertPhoto className={styles.noimage} />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.landscape, styles.content)}>
      <img
        className={styles.image}
        src={props.imageSrc} />
      <div
        className={styles.remove}
        onClick={() => mutation.mutate({
          orientation: MediaOrientation.Landscape,
        })}>
        <Cancel />
      </div>
    </div>
  );
};

Image.displayName = 'Settings.Branding.Logo.Landscape.Image';

export const Cropper = (props: Props) => {
  const mutation = useUpdateLogoMutation();

  return (
    <ImageCrop
      classes={{
        root: styles.lcropper,
        picker: styles.lpicker,
        original: styles.loriginal,
        preview: {
          image: styles.lpreview,
        },
      }}
      aspect={2.5}
      crop={{
        width: 350,
        height: 140,
      }}
      imageUrl={props.imageSrc}
      isSubmitting={mutation.isLoading}
      onSave={data => mutation.mutate({
        blob: data.blob,
        orientation: MediaOrientation.Landscape,
      })}
      placeholders
      ruleOfThirds />
  );
};

Cropper.displayName = 'Settings.Branding.Logo.Landscape.Cropper';