import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Select, { createFilter, ActionMeta, OnChangeValue } from 'react-select';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { createSelectStyles, createSelectTheme } from './select-styles';

type Props = {
  className?: string;
  isInvalid?: boolean;
  onChange:   (newValue: OnChangeValue<Item, false>, actionMeta: ActionMeta<Item>) => unknown;
  value:      string;
};

export const Country = (props: Props) => {
  const query = useQuery([QK.Search.Countries.Get], () => {
    return api.search.fetchCountries()
    .then(res => {
      return res.items.map(x => ({
        code: x.code,
        value: x.name,
      }));
    });
  }, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

  const filterOption = useCallback((option: FilterOptionOption<Item>, rawInput: string) => {
    const defaultFilter = createFilter<Item>({
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: 'start',
    });

    const countryCodeFilter = (option: FilterOptionOption<Item>, input: string) => {
      if (input) {
        return option.data.code.toLowerCase() === input.toLowerCase();
      }
      return true;
    };

    return defaultFilter(option, rawInput) || countryCodeFilter(option, rawInput);
  }, []);

  const selectStyles = useMemo(() => createSelectStyles<Item>({ isInvalid: props.isInvalid }), [props.isInvalid]);
  const selectTheme = useMemo(() => createSelectTheme(), []);

  return (
    <Select
      className={props.className}
      defaultValue={query.data?.find(i => i.value === props.value)}
      value={query.data?.find(i => i.value === props.value)}
      options={query.data || []}
      getOptionLabel={o => `${o.value} (${o.code})`}
      getOptionValue={o => o.value}
      noOptionsMessage={v => `Country not found`}
      placeholder="Select country"
      onChange={props.onChange}
      filterOption={filterOption}
      styles={selectStyles}
      theme={selectTheme} />
  );
};

Country.displayName = 'MedicalProfile.Field.Country';

type Item = {
  code: string;
  value: string;
};

type FilterOptionOption<D> = {
  label: string;
  value: string;
  data: D;
};