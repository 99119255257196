import { useContext } from 'react';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import { ProjectSurveysContext } from './Context';

type Response = Awaited<ReturnType<typeof $api.projects.surveys.getSurveys>>;

type Options = UseQueryOptions<Response>;
type Props = {
  disabled?: boolean;
} & IProjectId & ChildrenProps;

export const ProjectSurveysContainer = ({ projectId, children, disabled }: Props) => {
  const query = useQuery(['get-project-surveys', projectId], () => {
    return $api.projects.surveys.getSurveys({ projectId });
  }, {
    placeholderData: { surveys: [] },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!projectId && !disabled,
  });

  return (
    <ProjectSurveysContext.Provider value={{ query }}>
      {children}
    </ProjectSurveysContext.Provider>
  );
};

export const useProjectSurveysContext = () => useContext(ProjectSurveysContext);