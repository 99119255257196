import {
  SurveyBuilderVersion,
} from '@/types';
import { SaveSurveyDraftContext } from './Context';
import { useSubmitSurveyDraftMutation } from './hooks/useSubmitSurveyDraftMutation';

type Props = {
  initialValue?: SurveyBuilderVersion;
} & ChildrenProps;

export const SubmitSurveyDraftContainer = ({
  children,
}: Props) => {
  const submitSurveyDraft = useSubmitSurveyDraftMutation();

  return (
    <SaveSurveyDraftContext.Provider value={submitSurveyDraft}>
      {children}
    </SaveSurveyDraftContext.Provider>
  );
};

export default SubmitSurveyDraftContainer;
