import { useCallback } from 'react';
import type { SurveyQuestionType } from '@enums/Survey';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useRichTextEditorRegister } from '@/containers/SurveyBuilder/hooks/useRichTextEditorRegister';
import { useCanCreateFindAndReplace } from '@/containers/SurveyBuilder/hooks/useCanCreateFindAndReplace';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question/Context';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import type { MatrixRangeQuestion, SurveyRichText } from '@/types/survey';
import { QuestionRowsBuilderContainer } from '@/containers/SurveyBuilder.Question';
import type { SurveyRichTextEditorOnChange } from '@/components/Survey.RichText';
import { SurveyRichTextEditorContainer, useSurveyRichTextEditor, SurveyRichTextEditor } from '@/components/Survey.RichText';
import { FindAndReplaceButton } from '@/components/Survey.RichText/Editor.FindAndReplace.Button';
import { MatrixRangeRowsBuilder } from './MatrixRange.Rows';
import { MatrixRangeColumnsBuilder } from './MatrixRange.Columns';
import {
  RandomizeOptionsCheckbox,
  RandomizeRowsCheckbox,
  OrderRowsBasedOnSource,
  RationaleCheckbox,
} from './Settings';
import styles from './style/MatrixRange.Question.css';
import { useHasLinkedRows } from './hooks';
import { ReuseQuestionRows } from './ReuseQuestionValues';
import { FindAndReplaceRows } from './FindAndReplace';

type Props = {
  item: MatrixRangeQuestion.Question;
};

export const MatrixRangeQuestionBuilder = (props: Props) => {
  const item = useQuestionBuilderItem<SurveyQuestionType.MatrixRange>();
  const [_, dispatch] = useSurveyBuilderState();

  const handleSettingsChange = useCallback((field: keyof Pick<MatrixRangeQuestion.Settings, 'leftColumnLabel' | 'rightColumnLabel'>) => (value: SurveyRichText.RichTextValue) => {
    dispatch({
      type: 'update-question-settings',
      settings: {
        ...item.settings,
        [field]: value,
      },
      questionIdentifier: item.base.identifier,
    });
  }, [dispatch, item.base.identifier, item.settings]);

  const hasLinkedRows = useHasLinkedRows(item);
  const canFar = useCanCreateFindAndReplace();

  return (
    <>
      <div className={styles.rowMetadata}>
        <div className={styles.rowLabel} style={{ width: '160px' }}>Left Column Label:</div>
        <EditorContainer
          onChange={handleSettingsChange('leftColumnLabel')}
          value={item.settings.leftColumnLabel}
          identifier={`${item.base.identifier}:settings:leftColumnLabel`} />
      </div>
      <div className={styles.rowMetadata}>
        <div className={styles.rowLabel} style={{ width: '160px' }}>Right Column Label:</div>
        <EditorContainer
          onChange={handleSettingsChange('rightColumnLabel')}
          value={item.settings.rightColumnLabel}
          identifier={`${item.base.identifier}:settings:rightColumnLabel`} />
      </div>

      <QuestionSettingTitle title="Columns" />

      <QuestionOptionsBuilderContainer
        maxOptions={surveyBuilder.matrixRange.MaxColumns}
        minOptions={surveyBuilder.matrixRange.MinColumns}>
        <MatrixRangeColumnsBuilder item={props.item} />
      </QuestionOptionsBuilderContainer>

      <RandomizeOptionsCheckbox
        label="Randomize Columns"
        className={styles.checkbox} />

      <QuestionSettingTitle title="Rows">
        <ReuseQuestionRows />
        {canFar ? <FindAndReplaceRows question={item} /> : null}
      </QuestionSettingTitle>

      <QuestionRowsBuilderContainer
        maxRows={surveyBuilder.matrixRange.MaxRows}
        minRows={surveyBuilder.matrixRange.MinRows}>
        <MatrixRangeRowsBuilder item={props.item} />
      </QuestionRowsBuilderContainer>

      <RandomizeRowsCheckbox
        disabled={item.settings.orderRowsBasedOnSource}
        className={styles.checkbox} />

      {hasLinkedRows &&
        <OrderRowsBasedOnSource
          disabled={item.settings.randomizeRows}
          className={styles.checkbox} />
      }

      <RationaleCheckbox className={styles.checkbox} />
    </>
  );
};

type EditorProps = {
  onChange: (value: SurveyRichText.RichTextValue) => void;
  value: SurveyRichText.RichTextValue;
  identifier: string;
};

const EditorContainer = ({ onChange, value, identifier }: EditorProps) => {
  const canFar = useCanCreateFindAndReplace();

  const { manager, state, setState } = useSurveyRichTextEditor({
    initialState: value,
  });

  const handleChange: SurveyRichTextEditorOnChange = useCallback(params => {
    if (!params.tr?.docChanged) return;

    setState(params.state);

    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    onChange(value);
  }, [
    onChange,
    setState,
  ]);

  const editorRef = useRichTextEditorRegister({
    identifier,
  });

  return (
    <SurveyRichTextEditorContainer
      manager={manager}
      onChange={handleChange}
      state={state}
      editorRef={editorRef}>
      <SurveyRichTextEditor className={styles.editor} />
      {canFar ? <FindAndReplaceButton className={styles.boldBtn} /> : null}
    </SurveyRichTextEditorContainer>
  );
};