import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { useSurveyBuilderState, useHasUncommittedQuestionChanges, useSubmitSurveyDraft } from '@containers/SurveyBuilder/hooks';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useToggle } from '@utils';
import { NewVersionModal } from '@/components/SurveyBuilder.Question/NewVersionModal';
import { QuestionBuilderSaveContext, useQuestionBuilderItemContext } from './Context';
import { useUploadQuestionImages } from './useUploadQuestionImages';

type Props =
  ChildrenProps;

export const QuestionBuilderSaveContainer = (props: Props) => {

  const [showVersionModal, toggleVersionModal] = useToggle(false);
  const [_, dispatch] = useSurveyBuilderState();
  const uploadImages = useUploadQuestionImages();
  const submitDraft = useSubmitSurveyDraft();

  const item = useQuestionBuilderItemContext();

  // const hasUncommittedChanges = useHasUncommittedQuestionChanges();

  const questionValidation = useMemo(() => {
    const valid = surveyBuilder.validateQuestion(item);

    return {
      canSave: valid.success,
      validationErrors: valid.success === true ? null : [valid.result.message].filter(Boolean),
    };
  }, [item]);

  const handleSubmit = useCallback(async () => {
    await uploadImages();

    submitDraft();

    dispatch({
      type: 'toggle-editing',
      itemIdentifier: null,
    });
  }, [
    dispatch,
    submitDraft,
    uploadImages,
  ]);

  const onSaveQuestionFromModal = useCallback((isNew: boolean) => async () => {
    if (isNew) {
      dispatch({
        type: 'update-question-identifier',
        oldIdentifier: item.base.identifier,
        newIdentifier: cuid(),
      });
    }

    await handleSubmit();

    toggleVersionModal();

  }, [
    dispatch,
    item,
    toggleVersionModal,
    handleSubmit,
  ]);

  const onSave = useCallback(async () => {
    if (item.base.id) {
      toggleVersionModal();
    } else {
      await handleSubmit();
    }
  }, [
    item.base.id,
    toggleVersionModal,
    handleSubmit,
  ]);

  const value = {
    ...questionValidation,
    onSave,
  };

  return (
    <>
      <QuestionBuilderSaveContext.Provider value={value}>
        {props.children}
      </QuestionBuilderSaveContext.Provider>
      {showVersionModal &&
        <NewVersionModal
          onClose={toggleVersionModal}
          saveAsNew={onSaveQuestionFromModal(true)}
          saveAsExisting={onSaveQuestionFromModal(false)} />
      }
    </>
  );
};